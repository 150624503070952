import { useState, useEffect, useContext } from "react";
import SunIcon from "./SunIcon";
import ClockIcon from "./ClockIcon";
import ProfileVector from "./ProfileVector";
import { Btn, H4 } from "../../../../AbstractElements";
import { Card, CardBody, Col } from "reactstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { WelcomeBack } from "../../../../Constant";
import studentContext from "../../../../Helper/Student";
import { capitalizeFirstLetter } from "../../../../utils/text";

const Greetingcard = ({ onClickHandler }) => {
  const { studentDetails, currentCourse } = useContext(studentContext);
  const today = new Date();
  const curHr = today.getHours();
  const curMi = today.getMinutes();
  const [meridiem, setMeridiem] = useState("AM");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isDashboard = pathname !== '/profile'

  useEffect(() => {
    if (curHr >= 12) {
      setMeridiem("PM");
    } else {
      setMeridiem("AM");
    }
  }, []);

  return (
    <Col sm='12' md={isDashboard ? '5' : '4'} className="d-flex ">
      <Card className="profile-greeting w-100 overflow-hidden">
        <CardBody>
          {isDashboard && <div className="d-sm-flex d-block justify-content-between">
            <div>
              <SunIcon />
            </div>
            <ClockIcon curHr={curHr} curMi={curMi} meridiem={meridiem} />
          </div>}
          <div className="greeting-user first-step">
            <ProfileVector profileImage={studentDetails?.userProfileImage} />
            <div>
              <H4>
                {isDashboard && <span className="m-r-5 show-color">{WelcomeBack}</span>}
                <Link to={`/profile`}>
                  {isDashboard ? studentDetails.fullName.split(" ")[0] : studentDetails.fullName}
                  <span className="right-circle">
                    <i className="fa fa-check-circle font-primary f-14 middle"></i>
                  </span>
                </Link>
              </H4>
            </div>
            <div>
              <span className="badge badge-primary f-12">{"Current Course"}</span>
              <span className="font-primary f-16 middle f-w-500 ms-2">
                <Link to={`/courses/${currentCourse.id}`}>
                  {currentCourse?.name} {capitalizeFirstLetter(currentCourse?.type)}
                </Link>
              </span>
            </div>
            <Btn
              attrBtn={{
                onClick: () => isDashboard ? navigate("/profile") : onClickHandler(),
                color: "primary",
                className: "m-t-20 goto-profile-btn",
              }}
            >
              {isDashboard ? 'View Full Profile' : 'Edit Profile'}
            </Btn>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Greetingcard;
