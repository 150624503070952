import React, { Fragment, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { Footer, P } from '../../AbstractElements'
import BugModal from '../../Components/BugModal'
import CommonModal from '../../Components/UiKits/Modals/common/modal'
import { addReport } from '../../Service/Firebase'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import { bugValidation } from '../../Validation'
import Loader from '../../Common/Loader'
import { useContext } from 'react'
import studentContext from '../../Helper/Student'

const FooterComponent = () => {
  const [openModal, setOpenModal] = useState(false)
  const { studentDetails } = useContext(studentContext)

  const bugReportHandler = async () => {
    try {
      const { email, description, screenshot } = formik.values
      await addReport(screenshot, email, description)
      setOpenModal(false)
      toast.success("Report submitted successfully");
    } catch (error) {
      console.log(error)
      formik.setSubmitting(false)
    }
  }

  const formik = useFormik({
    validationSchema: bugValidation,
    initialValues: {
      email: studentDetails?.email ? studentDetails?.email : "",
      description: "",
      screenshot: ''
    },
    enableReinitialize: true,
    onSubmit: bugReportHandler
  })

  return (
    <Fragment>
      <div style={{ height: '40px' }} />
      <Footer attrFooter={{ className: "footer" }}>
        <Container fluid={true}>
          <Row>
            <Col md="6" className='p-0 footer-left'>
              <P attrPara={{ className: "mb-0" }} ></P>
            </Col>
            <Col md="6" className='p-0 footer-right'>
              <P
                attrPara={{
                  className: "mb-0 ms-3 text-primary footer-bug-modal",
                  onClick: () => setOpenModal(true)
                }}>Report A Bug</P>
            </Col>
          </Row>
        </Container>
      </Footer>
      <CommonModal
        isOpen={openModal}
        toggler={() => setOpenModal(false)}
        bodyClass="p-t-0 p-b-0"
        confirmHandler={() => formik.handleSubmit()}
        confirmBtnType='submit'
        title='Report A Bug'
        confirmButtonText={formik.isSubmitting ? <Loader /> : 'Submit'}>
        <form onSubmit={formik.handleSubmit}>
          <BugModal
            formik={formik}
          />
        </form>
      </CommonModal>
    </Fragment>
  )
}

export default FooterComponent;