import React, { useEffect, useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import StudentContext from "../Helper/Student";
import { authHeader, handleResponse } from "../Services/fack.backend";
import Loader from "../Layout/Loader";
import AuthLayout from "../Common/AuthLayout";

const PrivateRoute = () => {
  const { studentDetails, fetchStudentDetails, isLoading, isAuthenticated, loginAdmin } =
    useContext(StudentContext);
  const isUserView = localStorage.getItem("loginAsUser")
  const isUserOnboard =
    window !== undefined && localStorage.getItem("onBoardingEmail");
  const isUser = loginAdmin?.isAdmin ? true : studentDetails?.userProfileImage
  const showLoader = loginAdmin?.isAdmin ? isUserView ? studentDetails ? false : true : false : isLoading

  useEffect(() => {
    fetchStudentDetails();
  }, []);

  if (showLoader) return <Loader />;

  return isUser ? (
    <AuthLayout>
      <Outlet />
    </AuthLayout>
  ) : (
    <Navigate exact to={`/login`} />
  );
};

export default PrivateRoute;
