import React from "react";
import { Input, Label } from "reactstrap";

const RadioButton = (props) => {
  const { value, label, disabled, name, className, ...inputProps } = props
  return (
    <div className={`radio radio-primary ${disabled ? 'radio-disabled' : ''}`}>
      <Input disabled={disabled} id={value} type="radio" {...inputProps} name={name} value={value} className="m-l-10" />
      <Label className={`show-color ${className}`} for={value}>{label}</Label>
    </div>
  );
};

export default RadioButton;
