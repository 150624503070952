import { useEffect, useState } from "react";
import { H2, P } from "../../../../AbstractElements";
import { BeautifulSunnyDayWalk, SunnyDay } from "../../../../Constant";
import axios from "axios";
import { weatherIconData } from "../../../../Data/Icons";

const SunIcon = () => {
  const [weatherData, setWeatherData] = useState({});
  const weather = weatherData?.weather && weatherData?.weather[0].main

  const weatherCondition = () => {
    switch (weather) {
      case "Clear":
        return "Sunny Day";
      case "Clouds":
        return "Cloudy Day";
      case "Rain":
        return "Rainy Day";
      case "Snow":
        return "Snowy Day";
      default:
        return "Haze";
    }
  };

  const weatherConditions = {
    Clear: weatherIconData[0].icon,
    Clouds: weatherIconData[9].icon,
    Rain: weatherIconData[14].icon,
  };

  useEffect(() => {
    const fetchWeatherData = async () => {
      const city = "karachi";
      const apiUrl = `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=5fc6b681d5374a95207b70e1687cfeba`;

      try {
        const { data } = await axios.get(apiUrl);
        setWeatherData(data);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };

    fetchWeatherData();
  }, []);
  return (
    <div className="flex-grow-1">
      <div className="weather d-flex">
       {weatherData.main?.temp && <H2 attrH2={{ className: "f-w-400 show-color" }}>
          <span>
            {(weatherData.main?.temp - 273.15).toFixed(0)}
            <sup>
              <i className="fa fa-circle-o f-10"></i>
            </sup>
            C
          </span>
        </H2>}
        <div className="span sun-bg">
          {weatherConditions[weather]}
        </div>
      </div>
      <span className="font-primary f-w-700">{weatherCondition()}</span>
     
    </div>
  );
};

export default SunIcon;
