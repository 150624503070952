import React, { useContext, useEffect, useState } from "react";
import firebase from "firebase";
import Context from "./index";
import courseContext from "../Course";
import {
  getStudent,
  getStudentAttendance,
  getUserNotification,
  isUserExist,
} from "../../Service/Firebase";
import { updateAttendance } from "../../utils/attendance";
import { useLocation, useParams } from "react-router";
import { checkPermissionAccess } from "../../utils/errorValidationNavigate";
import { toast } from "react-toastify";

const StudentProvider = (props) => {
  const { courses } = useContext(courseContext);
  const [studentDetails, setStudentDetails] = useState(null);
  const [notifications, setNotifications] = useState([])
  const [attendanceData, setAttendanceData] = useState({});
  const [loginAdmin, setLoginAdmin] = useState(null);
  const [calenderData, setCalenderData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [attendance, setAttendance] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentCourse, setCurrentCourse] = useState({});

  const fetchStudentDetails = async () => {
    try {
      firebase.auth().onAuthStateChanged(async function (user) {
        if (user) {
          if (!courses?.length) return;
          const loginUser = await getStudent(user.email.toLowerCase());
          if (loginUser?.isAdmin) {
            setLoginAdmin(loginUser)
            viewStudentProfileAsAdmin(loginUser)
          } else {
            const currentCourseId =
              loginUser.courses &&
              loginUser.courses[loginUser?.courses?.length - 1]?.courseId;
            const currentCour = courses.find(
              (cour) => cour.id === currentCourseId && cour.isCurrent === true
            );
            const notifications = await getUserNotification(user.email);
            setNotifications(notifications)
            setCurrentCourse({
              ...currentCour,
              ...loginUser.courses[loginUser.courses.length - 1],
            });
            setStudentDetails(loginUser);
            await getAttendance(loginUser.email, currentCour);
          }
          setIsAuthenticated(true);
          setIsLoading(false);

        } else {
          setIsLoading(false);
        }
      });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const viewStudentProfileAsAdmin = async (adminUser) => {
    const paramsEmail = window.location.search.split("email=")[1];
    

    const isLocalStorageEmail = localStorage.getItem("loginAsUser");
    const userToViewEmail = paramsEmail
      ? paramsEmail
      : isLocalStorageEmail;
    const isUserHaveAccess =
      adminUser?.isAdmin && checkPermissionAccess(adminUser);
    const userExist = await isUserExist(userToViewEmail);

    if (isUserHaveAccess) {
      if (userToViewEmail) {
        if (!userExist) {
          toast.error("User not found");
          localStorage.removeItem("loginAsUser");
        } else {
          localStorage.setItem("loginAsUser", userToViewEmail);
          const studentProfile = await getStudent(userToViewEmail)
          const currentCourseId =
            studentProfile.courses &&
            studentProfile.courses[studentProfile?.courses?.length - 1]?.courseId;
          const currentCour = courses.find(
            (cour) => cour.id === currentCourseId && cour.isCurrent === true
          );
          const notifications = await getUserNotification(studentProfile.email);
          setNotifications(notifications)
          setCurrentCourse({
            ...currentCour,
            ...studentProfile.courses[studentProfile.courses.length - 1],
          });
          setStudentDetails(studentProfile);
          await getAttendance(studentProfile.email, currentCour);
        }
      }
    } else {
      toast.error(
        "You don’t have access, please ask Super Admin to provide you access"
      );
    }
  }

  // const fetchStudentDetails = async () => {
  //   const paramsEmail = window.location.search.split("email=")[1];

  //   try {
  //     const user = await waitForAuthStateChanged();
  //     console.log(';user', user)
  //     if (!user.email) {
  //       setIsLoading(false);
  //       return;
  //     }

  //     const loginUser = await getStudent(user.email.toLowerCase());
  //     const userToViewEmail = getUserToViewEmail(paramsEmail);
  //     const isUserHaveAccess = loginUser.isAdmin && checkPermissionAccess(loginUser);
  //     const userExist = await isUserExist(userToViewEmail);

  //     if (loginUser.isAdmin) {
  //       handleAdminUser(loginUser, isUserHaveAccess, userToViewEmail, userExist);
  //     } else {
  //       handleNonAdminUser(loginUser, isUserHaveAccess, userToViewEmail, userExist);
  //     }

  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log(error);
  //   }
  // };

  // const waitForAuthStateChanged = () => {
  //   return new Promise((resolve) => {
  //     firebase.auth().onAuthStateChanged(resolve);
  //   });
  // };

  // const getUserToViewEmail = (paramsEmail) => {
  //   const isLocalStorageEmail = localStorage.getItem("loginAsUser");
  //   return paramsEmail ? paramsEmail : isLocalStorageEmail;
  // };

  // const handleAdminUser = async (loginUser, isUserHaveAccess, userToViewEmail, userExist) => {
  //   setLoginAdmin(loginUser);
  //   if (isUserHaveAccess) {
  //     if (userToViewEmail) {
  //       if (!userExist) {
  //         toast.error("User not found");
  //         localStorage.removeItem("loginAsUser");
  //       } else {
  //         localStorage.setItem("loginAsUser", userToViewEmail);
  //       }
  //     }
  //   } else {
  //     toast.error("You don’t have access, please ask Super Admin to provide you access");
  //   }
  // };

  // const handleNonAdminUser = async (loginUser, isUserHaveAccess, userToViewEmail, userExist) => {
  //   const isUserView = isUserHaveAccess && userExist && userToViewEmail;
  //   const userData = isUserView ? await getStudent(userToViewEmail) : loginUser;

  //   if ((!loginUser.isAdmin && !userData.isAdmin) || (loginUser.isAdmin && !userData.isAdmin)) {
  //     const currentCourseId = userData.courses?.[userData?.courses?.length - 1]?.courseId;
  //     const currentCour = courses.find((cour) => cour.id === currentCourseId && cour.isCurrent === true);

  //     if (currentCour) {
  //       await getAttendance(userData.email, currentCour);
  //       setStudentDetails(userData);
  //       setCurrentCourse({ ...currentCour, ...userData.courses[userData.courses.length - 1] });

  //     }
  //   }
  // };  

  const getAttendance = async (userEmail, currentCour) => {

    const userAttendance = await getStudentAttendance(userEmail);
    const attendanceData = updateAttendance(userAttendance, currentCour);
    setCalenderData(attendanceData?.updatedAttendance);
    setAttendanceData(attendanceData?.data);
    setAttendance(userAttendance);
  };

  useEffect(() => {
    fetchStudentDetails();
  }, [courses]);

  return (
    <Context.Provider
      value={{
        ...props,
        fetchStudentDetails,
        attendance,
        setNotifications,
        notifications,
        getAttendance,
        isAuthenticated,
        setCurrentCourse,
        currentCourse,
        loginAdmin,
        setLoading: setIsLoading,
        attendanceData,
        calenderData,
        setLoginAdmin,
        setStudentDetails,
        studentDetails,
        isLoading,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default StudentProvider;
