import React, { Fragment, useContext, useEffect, useState } from "react";
import { Col } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { H4 } from "../../../AbstractElements";
import { initialstate } from "./DragData";
import { Dragging_Event } from "../../../Constant";
import studentContext from "../../../Helper/Student";

const DragCalendar = () => {
  const state = initialstate;
  const { calenderData, attendanceData } = useContext(studentContext);
  const [eventsData, setEventsData] = useState([])
// console.log('calenderData', calenderData)
  useEffect(() => {
    const events = [
      {
        title: "Present:",
        id: "1",
        bgClass: "present-color",
        value: attendanceData.present,
      },
      {
        title: "Absent:",
        id: "2",
        bgClass: "absent-color",
        value: attendanceData.absent,
      },
      {
        title: "Leave:",
        id: "3",
        bgClass: "leave-color",
        value: attendanceData.leave,
      },
      {
        title: "Total Classes Till Date:",
        id: "4",
        bgClass: "btn-success",
        value: attendanceData.totalClassesTillDate,
      },
      {
        title: "Total Course Classes:",
        id: "5",
        bgClass: "btn-warning",
        value: attendanceData.totalCourseClasses,
      },
    ];

  setEventsData(events)
}, [])

return (
    <Fragment>
      <Col xxl={3} lg={12}>
        <div id="external-events">
          <H4 attrH4={{ className: "mb-3 f-22 show-color" }}>
            {Dragging_Event}
          </H4>
          {eventsData.map((event) => (
            <div
              className={`fc-event w-100 b-r-6 text-start mb-2 btn text-white ${event.bgClass}`}
              key={event.id}
            >
              {event.title} {event.value}
            </div>
          ))}
        </div>
      </Col>
      <Col xxl={9} lg={12}>
        <div className="demo-app-calendar" id="mycalendartest">
          <FullCalendar
            defaultView="dayGridMonth"
            header={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            }}
            rerenderDelay={10}
            eventDurationEditable={false}
            editable={true}
            droppable={true}
            plugins={[dayGridPlugin, interactionPlugin]}
            weekends={state.calendarWeekends}
            events={calenderData}
          />
        </div>
      </Col>
    </Fragment>
  );
};
export default DragCalendar;
