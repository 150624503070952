import { object, string, number, array, boolean, ref } from "yup";

export const emailSchema = object().shape({
  email: string().email("Invalid email").required("Email is required"),
});

export const passwordSchema = object().shape({
  password: string().required("Password is required"),
});

export const onboardingValidation = [
  object().shape({
    fullName: string()
      .min(3, "Full name must be at least 3 characters").max(50, 'Full name should not exceed limit of 50 characters')
      .required("Full name is a required field"),
    gender: string().required("Gender is a required field"),
    age: number().max(100, 'Age should not be greater than 100').required("Age is a required field"),
    qualification: string().required("Qualification is a required field"),
    cnicNo: number()
      .required("CNIC No. is a required field")
      .test(
        "len",
        "Must be exactly 13 characters",
        (val) => val && val.toString().length === 13
      ),
    contactNo: string().trim()
      .required("Contact No. is a required field")
      .matches(/^[0-9]+$/, 'Only numbers from 0 to 9 are allowed')
      .min(11, "Please enter a valid phone number")
      .max(11, "Please enter a valid phone number"),
    // .test(
    //   "len",
    //   "Must be exactly 11 characters",
    //   (val) => val && val.toString().length === 10
    // ),
    ownLaptop: string().required("Own laptop is a required field"),
    howYouKnow: string().required("How you know is a required field"),
    userImage: string().required('A front pose clear picture is required'),
    policyAgreed: boolean().isTrue('You must agree to our policies'),
  }),
  object().shape({
    courseId: string().required('Please select course to continue'),
  }),
  object().shape({
    // password: string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    // cPassword: string().min(8, 'Confirm password must be at least 8 characters').required('Confirm password is  required'),
    password: string()
      .min(6, 'Password must be at least 8 characters')
      .required('Password is required'),
    cPassword: string()
      .oneOf([ref('password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  }),
];

export const NewCourseValidation = object().shape({
  courseId: string().required('Please select course'),
  images: array().nullable().min(1, 'Please upload fee screenshot')
})
export const FeeSubmissionValidation = object().shape({
  images: array().nullable().min(1, 'Please upload fee screenshot')
})

export const EditFormValidation = object().shape({
  email: string()
    .email()
    .required("Email is a required field"),
  fullName: string()
    .min(3, "Full name must be at least 3 characters")
    .required("Full name is a required field"),
  gender: string().required("Gender is a required field"),
  age: number().max(100, 'Age should not be greater than 100').required("Age is a required field"),
  qualification: string().required("Qualification is a required field"),
  cnicNo: number()
    .required("CNIC No. is a required field")
    .test(
      "len",
      "Must be exactly 13 characters",
      (val) => val && val.toString().length === 13
    ),
  contactNo: string().trim()
    .matches(/^[0-9]+$/, 'Only numbers from 0 to 9 are allowed')
    .required("Contact No. is a required field")
    .min(11, "Please enter a valid phone number")
    .max(11, "Please enter a valid phone number"),
  ownLaptop: string().required("Own laptop is a required field"),
  howYouKnow: string().required("How you know is a required field"),
  policyAgreed: boolean().isTrue('You must agree to our policies'),
})

export const passwordResetSchema = object().shape({
  password: string().min(8).required('Password is required'),
  cPassword: string().min(8).required('Confirm password is required')
})

export const bugValidation = object().shape({
  email: string().email('Invalid email').required('Email is required'),
  description: string().required('Description is required'),
  screenshot: string().required('Please upload bug image')
})