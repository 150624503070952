import React, { Fragment } from 'react';
import { Col } from 'reactstrap';
import BlogDetails from './BlogDetails';

const LearningDeatil = ({ courseDetails }) => {
  return (
    <Fragment>
      <Col xl="7" className='xl-60 box-col-60'>
        <div className="blog-single">
          <BlogDetails courseDetails={courseDetails} />
        </div>
      </Col>
    </Fragment>
  );
};

export default LearningDeatil;