import { useContext } from "react";
import { Card, CardBody } from "reactstrap";
import { Btn, H4, Image, LI, P, UL } from "../../../AbstractElements";
import LearningContext from "../../../Helper/Leatning";
import moment from "moment";

const BlogDetails = ({ courseDetails }) => {
  const { isOpen, setIsOpen } = useContext(LearningContext);


  const onClickeHandel = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="blog-box blog-details">
      <Card>
        <Image
          attrImage={{
            className: "img-fluid w-100 course-main-image",
            src: courseDetails.icon,
            alt: "blog-main",
          }}
        />
        <CardBody>
          <div className="blog-details">
            <UL attrUL={{ className: "course-detail-icon" }}>
              <LI>
                <i className="icofont icofont-building-alt"></i>
                {courseDetails.totalCourseClasses} classes
              </LI>
              <LI>
                <i className="fa fa-users"></i>
                {courseDetails.totalStudents} Total Students
              </LI>
              <LI>
                <i className="fa fa-calendar"></i>
                {courseDetails.totalDays}{" "}
                {courseDetails.totalDays > 1 ? "Classes" : "Class"} Per Week
              </LI>
              {courseDetails.isEnrolled && (
                <LI>
                  <i className="icofont icofont-brand-whatsapp"></i>
                  <a
                    href={courseDetails.whatsappLink}
                    target="_blank"
                    className="whatsapp-text"
                  >
                    Group
                  </a>
                </LI>
              )}
              <LI>
                <i className="fa fa-clock-o"></i>
                <span style={{ textTransform: "uppercase" }}>{courseDetails.days}</span>  | {moment(new Date(courseDetails.startDate)).format("LT")} -{" "}
                {moment(new Date(courseDetails.endDate)).format("LT")}
              </LI>
            </UL>
            <div className="d-flex align-items-center justify-content-between my-4 course-name-outline">
              <H4 attrH4={{ className: "show-color text-capitalize m-0" }}>
                {courseDetails.name} {courseDetails.type}
              </H4>
              <div className="d-flex align-items-center course-detail-action">
                <a href={courseDetails.courseOutline} target="_blank">
                  <Btn attrBtn={{ color: "primary" }}>
                    <i className="fa fa-file-archive-o"></i> View Course Outline
                  </Btn>
                </a>
                <a
                  className="btn btn-primary md-sidebar-toggle mobile-menu-btn"
                  href="#javascript"
                  onClick={onClickeHandel}
                >
                  {isOpen ? 'Close' : ""}   {courseDetails.isEnrolled ? `Fee's Module` : 'Payment Detail'}
                </a>
              </div>
            </div>
            <div className="single-blog-content-top">
              <P>{courseDetails.description}</P>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default BlogDetails;
