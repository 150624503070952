import moment from "moment";
import { P } from "../../../../AbstractElements";

const OrderListCardTableBody = ({ data }) => {
  return (
    <tbody>
      {data?.length ? data.map((item, index) => (
        <tr key={item.name}>
          <td>
            <div className="d-flex">
              <div className="number-dot">
                <span className="f-w-700">{index + 1}</span>
              </div>
              <div className="flex-grow-1">
                <span className="f-14 f-w-600 show-color">{item.category}</span>
              </div>
            </div>
          </td>
          <td>
            <div className="d-flex">
              {/* <div className="number-dot"> */}
              <span className="f-w-700">{item.name}</span>
              {/* </div> */}
            </div>
          </td>
          <td>
            <span>{item.date ? item.date?.seconds ? moment(item.date?.seconds * 1000).format('MM/DD/YYYY') : moment(item.date).format('MM/DD/YYYY'): 'N/A'}</span>
          </td>
          <td>{item.marks.toFixed(0)}</td>
          <td>{item.marks > 60 ? "Pass" : "Fail"}</td>
        </tr>
      )) : <P attrPara={{className: 'show-color' }}>No result found</P>}
    </tbody>
  );
};

export default OrderListCardTableBody;
