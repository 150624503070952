import { CardHeader } from "reactstrap";
import { H4, P } from "../../../../AbstractElements";
import { Absent, Leave, Presnt } from "../../../../Constant";

const UserCardHeader = () => {
  return (
    <CardHeader className="pb-0">
      <div className="d-flex justify-content-between">
        <div className="flex-grow-1">
          <P attrPara={{ className: "square-after f-w-600 header-text-primary" }}>AttendanceReport
            <i className="fa fa-circle"></i>
          </P>
          {/* <div className="main-notation">
            <div className="notation-container">
              <div className="present-notation" />
              <P attrPara={{ className: 'show-color' }}>{Presnt}</P>
            </div>
            <div className="notation-container">
              <div className="absent-notation" />
              <P attrPara={{ className: 'show-color' }}>{Absent}</P>
            </div>
            <div className="notation-container">
              <div className="leave-notation" />
              <P attrPara={{ className: 'show-color' }}>{Leave}</P>
            </div>
          </div> */}
        </div>
      </div>
    </CardHeader>
  );
};

export default UserCardHeader;