import React, { useEffect, useState } from "react";

const Loader = ({ pageLoader }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // setShow(true);
    // const timeout = setTimeout(() => {
    // }, time);
    // return () => {
    //   clearTimeout(timeout);
    // };
  }, [show]);

  return (
    <div className={`loader-wrapper ${!show ? "" : "d-none"} ${pageLoader ? 'page-loader' : ''}`}>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"> </div>
      <div className="dot"></div>
    </div>
  );
};

export default Loader;
