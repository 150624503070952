import React, { Fragment, useContext } from "react";
import { Col, Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { H4 } from "../../../AbstractElements";
import LearningContext from "../../../Helper/Leatning";
import CourseCard from "./CourseCard";
import { getCoursesDetails } from "../../../utils/courses";
import studentContext from "../../../Helper/Student";
import courseContext from "../../../Helper/Course";
import CardsClass from "../../JobSearch/JobCardView/Cards";

const BoxList = ({ isMyCourses, col }) => {
  const { learningData } = useContext(LearningContext);
  const { studentDetails } = useContext(studentContext);
  const { courses } = useContext(courseContext);
  const history = useNavigate();
  const clickApply = () => {
    history(`/courses/12`);
  };
  const courseInfo = getCoursesDetails(studentDetails, courses);

  const EnrolledCourses = ({ cardCol }) => {
    return (
      // <Col xl={col}>
      <div className="m-b-30 my-courses">
        <H4 attrH4={{ className: "m-b-20" }}>Enrolled Courses</H4>
        {/* <Col> */}
        <div className="my-courses">
          <CardsClass data={courseInfo.studentCourses} cardCol={cardCol} />
        </div>
        {/* </Col> */}
      </div>
      // </Col>
    );
  };

  return (
    <Fragment>
      {isMyCourses ? (
        <EnrolledCourses cardCol={4} />
      ) : (
        <>
          <EnrolledCourses cardCol={4} />
          {courseInfo.featuredCourses.length ? <div>
            <H4 attrH4={{ className: "m-b-20" }}>Featured Courses</H4>
            <CardsClass data={courseInfo.featuredCourses} cardCol={4} />
          </div> : ""}
        </>
      )}
    </Fragment>
  );
};
export default BoxList;
