import React from "react";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";

const useSweetAlert = () => {
  const navigate = useNavigate();

  const showSweetAlert = (
    title,
    text,
    confirmButtonText,
    icon,
    navigateTo,
    showConfirmButton,
    onSuccess
  ) => {
    SweetAlert.fire({
      title,
      text,
      icon,
      showConfirmButton,
      confirmButtonText,
    }).then((result) => {
      if (!navigateTo) return;
      if (result.isConfirmed) {
        onSuccess()
        navigate(navigateTo);
      } else if (result.dismiss === SweetAlert.DismissReason.cancel) {
        navigate(navigateTo);
      }
    });
  };
  return { showSweetAlert };
};

export default useSweetAlert;
