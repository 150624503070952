import { toast } from "react-toastify"
import { FEE_REFUND, FEE_VERIFIED_FAILED } from "../Constant"

export const getRefundScreenshot = (currentCourse) => {
	if (!currentCourse?.feeScreenshots?.length) return
	const refundScreenshotIndex = currentCourse.feeScreenshots.findIndex((screenshot) => screenshot.status === FEE_REFUND)
	return refundScreenshotIndex !== -1 ? refundScreenshotIndex : false
}

export const getNotVerifiedFees = (currentCourse) => {
	if (!currentCourse?.feeScreenshots?.length) return
	const failedScreenShot = currentCourse.feeScreenshots.findIndex((screenshot) =>
		screenshot.status === FEE_VERIFIED_FAILED)
	return failedScreenShot !== -1 ? failedScreenShot : false
}

export const downloadFeeScreenshot = async (image) => {
	if (!image) return
	try {
		const imageUrl = image;
		const response = await fetch(imageUrl);
		const blob = await response.blob();

		const url = window.URL.createObjectURL(blob);

		const a = document.createElement("a");
		a.style.display = "none";
		a.href = url;
		a.download = "image.jpg"; // You can change the filename here
		document.body.appendChild(a);

		a.click();

		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);

	} catch (error) {
		toast.error('Error downloading image');
		console.error("Error downloading image:", error);
	}
}