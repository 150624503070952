import React, { useContext, useEffect, useState } from 'react'
import studentContext from '../Helper/Student';
import { addAdminActivity } from '../Service/Firebase';

const useAdminTrackActivity = () => {
    const { studentDetails, loginAdmin } = useContext(studentContext);
    const [coordination, setCoordination] = useState({});
    const [userDenied, setUserDenied] = useState(false);



    const trackAdminLoginActivity = () => {
        const activityOptions = {
            adminId: loginAdmin.email,
            type: "admin_login",
            activityOnUser: "N/A",
            createdAt: new Date(),
        };
    
        const handleSuccess = (position) => {
            const coordination = {
                lat: position.coords.latitude,
                long: position.coords.longitude,
            };
            setCoordination(coordination);
            activityOptions.activity = `Admin with email ${loginAdmin.email} has logged into the student portal.`;
            activityOptions.coordination = coordination;
            handleActivity();
        };
    
        const handleError = (error) => {
            activityOptions.activity = `ALERT: Admin with email ${loginAdmin.email} has logged into the student portal.`;
            setUserDenied(true);
            if (!localStorage.getItem("admin_login") && !localStorage.getItem("refresh_tracked")) {
                handleActivity();
                localStorage.setItem("refresh_tracked", true);
            }
        };
    
        const handleActivity = () => {
            if (!localStorage.getItem("admin_login")) {
                setUserDenied(false);
                addAdminActivity(loginAdmin, activityOptions);
                localStorage.setItem("admin_login", true);
            }
        };
    
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
        } else {
            handleError();
        }
    };
    
    const handleBeforeUnload = (event) => {
        const isLoginActivityTracked = JSON.parse(
            localStorage.getItem("admin_login")
        );
        const isRefreshTracked = JSON.parse(
            localStorage.getItem("refresh_tracked")
        );
        if (loginAdmin?.isAdmin && !isLoginActivityTracked && !isRefreshTracked) {
            event.preventDefault();
            event.returnValue = "";
            const activityOptions = {
                adminId: studentDetails.email,
                coordination,
                type: "admin_login",
                activityOnUser: "N/A",
                createdAt: new Date(),
            };
            addAdminActivity(studentDetails, activityOptions);
            localStorage.setItem("refresh_tracked", true);
        }
    };


    useEffect(() => {
        if (loginAdmin?.isAdmin) {
            trackAdminLoginActivity();
        }
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [loginAdmin, studentDetails]);

    return {userDenied, coordination}
}

export default useAdminTrackActivity