import React from "react";
import { CardHeader, Collapse } from "reactstrap";
import QuizByCategory from "./QuizByCategory";
import { H5 } from "../../AbstractElements";

const QuizCard = ({ beginner, open, setOpen }) => {
  const categoryName = beginner.data.name;
  const isAlreadyOpen = open[categoryName]
  return (
    <CardHeader
      onClick={() => setOpen({ [categoryName]: !isAlreadyOpen  })}
      className="quiz-border quiz-card-header"
    >
      <div className="d-flex align-items-center cursor-pointer justify-content-between m-b-10">
        <H5 attrH5={{ className: "mb-0 show-color" }}>{categoryName}</H5>
        {open[categoryName] ? (
          <i className="fa fa-angle-down show-color"></i>
        ) : (
          <i className="fa fa-angle-up show-color"></i>
        )}
      </div>
      <Collapse isOpen={open[categoryName]}>
        {beginner.quizzes.map((quiz) => (
          <QuizByCategory
            quiz={quiz}
            categoryName={categoryName}
            key={quiz.id}
          />
        ))}
      </Collapse>
    </CardHeader>
  );
};

export default QuizCard;
