import React, { useEffect, useState, useContext } from "react";
import { Bell, Truck } from "react-feather";
import { LI, P } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import studentContext from "../../../Helper/Student";
import { capitalizeFirstLetter, timeShorter } from "../../../utils/text";
import { changeNotificationStatus } from "../../../Service/Firebase";
import { getCourseId } from "../../../utils/courses";
import courseContext from "../../../Helper/Course";

const Notification = ({ active, setActive }) => {
  const { notifications, setNotifications } = useContext(studentContext);
  const { courses } = useContext(courseContext);
  const navigation = useNavigate()

  const [isFocused, setIsFocused] = useState(false);
  const isUnReadNotification = notifications?.filter(
    (noti) => noti.read == false
  );

  const navigate = (courseName, courseType) => {
    const course = getCourseId(courses, courseName, courseType)
    if (course) {
      navigation(`/courses/${course?.id}`)
    }
  }

  const icons = {
    PENDING: "fa-clock-o notification-icon",
    VERIFIED: "fa-check notification-icon",
    NOTVERIFIED: "fa-times notification-icon",
  };

  useEffect(async () => {
    const markReadNotifiation = async () => {
      if (isFocused || active) {
        const updatedNotifications = [];
        const unreadNotifications = notifications.filter(
          (noti) => noti.read == false
        );
        const readNotifications = notifications.filter(
          (noti) => noti.read == true
        );
        for (const notification of unreadNotifications) {
          await changeNotificationStatus(notification.id);
          updatedNotifications.push({
            ...notification,
            read: true,
          });
        }
        const allNotifications = [
          ...updatedNotifications,
          ...readNotifications,
        ];
        setNotifications(allNotifications);
      }
    };
    markReadNotifiation();
  }, [active, isFocused]);

  return (
    <LI attrLI={{ className: "onhover-dropdown notification-conatainer" }}>
      <div
        className="notification-box"
        onMouseOver={() => setIsFocused(true)}
        onMouseLeave={() => setIsFocused(false)}
      >
        <Bell onClick={() => setActive("notificationbox")} />
        {isUnReadNotification?.length ? <div className="un-read" /> : ""}
      </div>
      <ul
        className={`notification-dropdown  onhover-show-div ${active === "notificationbox" ? "active" : ""
          }`}
      >
        <LI>
          <Bell />
          <h6 className="f-18 mb-0">Notification</h6>
        </LI>
        <div
          className="removeScrollbar notification-container"
        >
          {notifications.map((noti) => (
            <div className="d-flex align-items-center  single-notification-item" onClick={() => navigate(noti?.body?.courseName, noti?.body?.courseType)}>
              <div className="flex-shrink-0 notification-icon-container">
                <i className={`fa ${icons[noti.type.toUpperCase()]}`}></i>
              </div>
              <div className="flex-grow-1 m-l-15 d-flex justify-content-between">
                <P attrPara={{ className: 'm-0' }}>
                  <div>
                    <b className="d-block">{noti.title}</b>
                    {`${noti.body.courseName} ${capitalizeFirstLetter(noti.body.courseType)} Module ${noti.body.module}`}
                  </div>
                </P>
                <span className="pull-right notification-time">
                  {timeShorter(
                    noti.createdAt?.seconds ? noti.createdAt.seconds * 1000 : noti.createdAt
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
      </ul>
    </LI>
  );
};

export default Notification;
