import {
  Airplay,
  BarChart,
  Box,
  MessageCircle,
  BookOpen,
  Calendar,
  CreditCard,
  User,
  CheckSquare,
  Clock,
  Cloud,
  Command,
  Edit,
  FileText,
  Film,
  FolderPlus,
  GitPullRequest,
  Heart,
  HelpCircle,
  Home,
  Trello,
  Image,
  Layers,
  List,
  Mail,
  Map,
  Monitor,
  Package,
  Radio,
  Server,
  Sunrise,
  Users,
  Zap,
  ShoppingBag,
} from "react-feather";

export const MENU = [
  // {
  //   className: "menu-box",
  //   menu: [
  //     {
  //       title: "Dashboards",
  //       icon: <Home />,
  //       class: "lan-3",
  //       menu: [
  //         {
  //           title: "Default",
  //           url: `/dashboard`,
  //           class: "lan-4",
  //           type: "link",
  //         },
  //         {
  //           title: "Ecommerce",
  //           url: `/dashboard/ecommerce`,
  //           type: "link",
  //           class: "lan-5",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Widgets",
  //       icon: <Airplay />,
  //       class: "lan-6",
  //       menu: [
  //         {
  //           url: `/widgets/general`,
  //           type: "link",
  //           title: "General",
  //         },
  //         {
  //           url: `/widgets/chart`,
  //           type: "link",
  //           title: "Chart",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Ecommerce",
  //       icon: <ShoppingBag />,
  //       type: "sub",
  //       menu: [
  //         {
  //           url: `/ecommerce/product`,
  //           title: "Product",
  //           type: "link",
  //         },
  //         {
  //           url: `/ecommerce/product-page/1`,
  //           title: "Product Page",
  //           type: "link",
  //         },
  //         {
  //           url: `/ecommerce/product-list`,
  //           title: "Product List",
  //           type: "link",
  //         },
  //         {
  //           url: `/ecommerce/Add-product`,
  //           title: "Add Product",
  //           type: "link",
  //         },
  //         {
  //           url: `/ecommerce/payment-details`,
  //           title: "Payment Detail",
  //           type: "link",
  //         },
  //         {
  //           url: `/ecommerce/orderhistory`,
  //           title: "Order History",
  //           type: "link",
  //         },
  //         {
  //           url: `/ecommerce/pricing`,
  //           title: "Pricing",
  //           type: "link",
  //         },
  //         {
  //           url: `/ecommerce/invoice`,
  //           title: "Invoice",
  //           type: "link",
  //         },
  //         {
  //           url: `/ecommerce/cart`,
  //           title: "Cart",
  //           type: "link",
  //         },
  //         {
  //           url: `/ecommerce/checkout`,
  //           title: "Checkout",
  //           type: "link",
  //         },
  //         {
  //           url: `/ecommerce/whishlist`,
  //           title: "Wishlist",
  //           type: "link",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    className: "menu-box",
    menu: [
      // {
      //   title: "Chat",
      //   icon: <MessageCircle />,
      //   menu: [
      //     {
      //       url: `/chat-app`,
      //       type: "link",
      //       title: "Chat App",
      //     },
      //     {
      //       url: `/video-chat-app`,
      //       type: "link",
      //       title: "Video App",
      //     },
      //   ],
      // },
      // {
      //   title: "Email",
      //   icon: <Mail />,
      //   menu: [
      //     {
      //       url: `/email-app`,
      //       type: "link",
      //       title: "Mail Inbox",
      //     },
      //     {
      //       url: `/email/readmail`,
      //       type: "link",
      //       title: "Read Mail",
      //     },
      //     {
      //       url: `/email-app/compose`,
      //       type: "link",
      //       title: "Compose",
      //     },
      //   ],
      // },
      {
        title: "Dashboard",
        url: `/dashboard`,
        type: "link",
        bookmark: true,
        icon: <Home />,
      },
      {
        url: `/courses`,
        icon: <BookOpen />,
        title: "My Courses",
        type: "link",
        bookmark: true,
      },
      {
        url: `/quizzes`,
        icon: <Monitor />,
        type: "link",
        title: "Quizzes",
      },
      {
        url: `/quizzes-result`,
        icon: <Trello />,
        type: "link",
        title: "Quizzes Result",
      },
      {
        url: `/student-card`,
        icon: <CreditCard />,
        bookmark: true,
        type: "link",
        title: "Student Card",
      },
      {
        url: `/attendance`,
        icon: <Calendar />,
        type: "link",
        title: "Attendance",
      },
      {
        url: `/profile`,
        icon: <User />,
        type: "link",
        title: "Profile",
      },
      // {
      //   url: `/task`,
      //   icon: <CheckSquare />,
      //   type: "link",
      //   title: "Task",
      // },
      // {
      //   url: `/social-app`,
      //   icon: <Zap />,
      //   title: "Social App",
      //   type: "link",
      //   bookmark: true,
      // },

      // {
      //   url: `/todo-app/todo`,
      //   icon: <Clock />,
      //   type: "link",
      //   title: "To-Do",
      // },
    ],
  },
  // {
  //   className: "menu-box",
  //   menu: [
  //     {
  //       title: "Forms",
  //       icon: <FileText />,
  //       menu: [
  //         {
  //           title: "Form Controls",
  //           menu: [
  //             {
  //               title: "Form Validation",
  //               type: "link",
  //               url: `/forms/form-validation`,
  //             },
  //             {
  //               title: "Basic Input",
  //               type: "link",
  //               url: `/forms/baseInput`,
  //             },
  //             {
  //               title: "Checkbox & Radio",
  //               type: "link",
  //               url: `/forms/radio-checkbox`,
  //             },
  //             {
  //               title: "Input Groups",
  //               type: "link",
  //               url: `/forms/inputGroup`,
  //             },
  //             {
  //               title: "Mega Option",
  //               type: "link",
  //               url: `/forms/megaOptions`,
  //             },
  //           ],
  //         },
  //         {
  //           title: "Form Widgets",
  //           menu: [
  //             {
  //               title: "Datepicker",
  //               type: "link",

  //               url: `/form-widget/datepicker`,
  //             },
  //             {
  //               title: "Typeahead",
  //               type: "link",

  //               url: `/form-widget/typeahead`,
  //             },
  //             {
  //               title: "Rangepicker",
  //               type: "link",

  //               url: `/form-widget/rangepicker`,
  //             },
  //             {
  //               title: "Touchspin",
  //               type: "link",

  //               url: `/form-widget/touchspin`,
  //             },
  //             {
  //               title: "Select2",
  //               type: "link",

  //               url: `/form-widget/select`,
  //             },
  //             {
  //               title: "Switch",
  //               type: "link",

  //               url: `/form-widget/switch`,
  //             },
  //             {
  //               title: "Clipboard",
  //               type: "link",

  //               url: `/form-widget/clipboard`,
  //             },
  //           ],
  //         },
  //         {
  //           title: "Form Layout",
  //           menu: [
  //             {
  //               url: `/form-layout/formDefault`,
  //               type: "link",
  //               title: "Form Default",
  //             },
  //             {
  //               url: `/form-layout/formWizard`,
  //               type: "link",
  //               title: "Form Wizard",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: "Tables",
  //       icon: <Server />,
  //       menu: [
  //         {
  //           title: "React Strap Table",
  //           url: `/table/reactstraptable`,
  //           type: "link",
  //         },
  //         {
  //           title: "Data Tables",
  //           type: "link",
  //           url: `/table/datatable`,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   className: "menu-box",
  //   menu: [
  //     {
  //       title: "Ui-Kits",
  //       icon: <Box />,
  //       menu: [
  //         {
  //           url: `/ui-kits/typography`,
  //           title: "Typography",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/avatar`,
  //           title: "Avatars",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/helperclass`,
  //           title: "Helper-Classes",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/grid`,
  //           title: "Grid",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/tagsandpills`,
  //           title: "Tag & Pills",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/progress-bar`,
  //           title: "Progress",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/modal`,
  //           title: "Modal",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/alert`,
  //           title: "Alert",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/popover`,
  //           title: "Popover",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/tooltips`,
  //           title: "Tooltip",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/spinner`,
  //           title: "Spinners",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/dropdown`,
  //           title: "Dropdown",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/accordion`,
  //           title: "Accordion",
  //           type: "link",
  //         },
  //         {
  //           title: "Tabs",
  //           type: "sub",
  //           menu: [
  //             {
  //               title: "Bootstrap Tabs",
  //               type: "link",
  //               url: `/ui-kits/tab-bootstrap`,
  //             },
  //             {
  //               title: "Line Tabs",
  //               type: "link",
  //               url: `/ui-kits/tab-line`,
  //             },
  //           ],
  //         },
  //         {
  //           url: `/ui-kits/shadow`,
  //           title: "Shadow",
  //           type: "link",
  //         },
  //         {
  //           url: `/ui-kits/list`,
  //           title: "List",
  //           type: "link",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Bonus Ui",
  //       type: "sub",
  //       icon: <FolderPlus />,
  //       menu: [
  //         {
  //           url: `/bonus-ui/scrollable`,
  //           type: "link",
  //           title: "Scrollable",
  //         },
  //         {
  //           url: `/bonus-ui/treeview`,
  //           type: "link",
  //           title: "Tree",
  //         },
  //         {
  //           url: `/bonus-ui/rating`,
  //           type: "link",
  //           title: "Rating",
  //         },
  //         {
  //           url: `/bonus-ui/dropzone`,
  //           type: "link",
  //           title: "Dropzone",
  //         },
  //         {
  //           url: `/bonus-ui/tourComponent`,
  //           type: "link",
  //           title: "Tour",
  //         },
  //         {
  //           url: `/bonus-ui/sweetAlert`,
  //           type: "link",
  //           title: "SweetAlert",
  //         },
  //         {
  //           url: `/bonus-ui/ribbons`,
  //           type: "link",
  //           title: "Ribbons",
  //         },
  //         {
  //           url: `/bonus-ui/pagination`,
  //           type: "link",
  //           title: "Pagination",
  //         },
  //         {
  //           url: `/bonus-ui/breadcrumb`,
  //           type: "link",
  //           title: "Breadcrumb",
  //         },
  //         {
  //           url: `/bonus-ui/rangeSlider`,
  //           type: "link",
  //           title: "Range Slider",
  //         },
  //         {
  //           url: `/bonus-ui/imageCropper`,
  //           type: "link",
  //           title: "Image Cropper",
  //         },
  //         {
  //           url: `/bonus-ui/stickyNotes`,
  //           type: "link",
  //           title: "Sticky",
  //         },
  //         {
  //           url: `/bonus-ui/dragNDropComp`,
  //           type: "link",
  //           title: "Drag and Drop",
  //         },
  //         {
  //           url: `/bonus-ui/image-upload`,
  //           type: "link",
  //           title: "Upload",
  //         },
  //         {
  //           url: `/bonus-ui/card/basicCards`,
  //           type: "link",
  //           title: "Basic Card",
  //         },
  //         {
  //           url: `/bonus-ui/card/draggingCards`,
  //           type: "link",
  //           title: "Draggable Card",
  //         },
  //         {
  //           url: `/bonus-ui/timelines/timeline1`,
  //           type: "link",
  //           title: "Timeline",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Icons",
  //       icon: <Command />,
  //       type: "sub",
  //       menu: [
  //         {
  //           url: `/icons/flagIcons`,
  //           type: "link",
  //           title: "Flag Icon",
  //         },
  //         {
  //           url: `/icons/fontAwsomeIcon`,
  //           type: "link",
  //           title: "Font awesome Icon",
  //         },
  //         {
  //           url: `/icons/icoIcons`,
  //           type: "link",
  //           title: "Ico Icon",
  //         },
  //         {
  //           url: `/icons/themifyIcons`,
  //           type: "link",
  //           title: "Themify Icon",
  //         },
  //         {
  //           url: `/icons/featherIcons`,
  //           type: "link",
  //           title: "Feather Icon",
  //         },
  //         {
  //           url: `/icons/weatherIcons`,
  //           type: "link",
  //           title: "Whether Icon",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Buttons",
  //       icon: <Cloud />,
  //       type: "sub",
  //       menu: [
  //         {
  //           url: `/buttons/default-btn`,
  //           type: "link",
  //           title: "Default Style",
  //         },
  //         {
  //           url: `/buttons/groupBtn`,
  //           type: "link",
  //           title: "Button Group",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Charts",
  //       icon: <BarChart />,
  //       type: "sub",
  //       menu: [
  //         {
  //           url: `/charts/apexCharts`,
  //           type: "link",
  //           title: "Apex Chart",
  //         },
  //         {
  //           url: `/charts/googleChart`,
  //           type: "link",
  //           title: "Google Chart",
  //         },
  //         {
  //           url: `/charts/chartJs`,
  //           type: "link",
  //           title: "Chartjs",
  //         },
  //         {
  //           url: `/charts/chartistComponent`,
  //           type: "link",
  //           title: "Chartist",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   className: "menu-box",
  //   menu: [
  //     {
  //       url: `/samplepage`,
  //       icon: <Layers />,
  //       title: "Sample Page",
  //       type: "sub",
  //     },
  //     {
  //       title: "Others",
  //       icon: <Layers />,
  //       type: "sub",
  //       menu: [
  //         {
  //           title: "Error Page",
  //           type: "sub",
  //           menu: [
  //             {
  //               title: "Error Page 1",
  //               type: "link",
  //               url: `/error/error-page1`,
  //             },
  //             {
  //               title: "Error Page 2",
  //               type: "link",
  //               url: `/error/error-page2`,
  //             },
  //             {
  //               title: "Error Page 3",
  //               type: "link",
  //               url: `/error/error-page3`,
  //             },
  //             {
  //               title: "Error Page 4",
  //               type: "link",
  //               url: `/error/error-page4`,
  //             },
  //             {
  //               title: "Error Page 5",
  //               type: "link",
  //               url: `/error/error-page5`,
  //             },
  //           ],
  //         },
  //         {
  //           title: "Coming Soon",
  //           type: "sub",

  //           menu: [
  //             {
  //               title: "Coming Sample",
  //               type: "link",
  //               url: `/coming/comingsimple`,
  //             },
  //             {
  //               title: "Coming with Bg-video",
  //               type: "link",
  //               url: `/coming/comingbgvideo`,
  //             },
  //             {
  //               title: "Coming with Bg-Image",
  //               type: "link",
  //               url: `/coming/comingbgimage`,
  //             },
  //           ],
  //         },
  //         {
  //           title: "Authentication",
  //           type: "sub",

  //           menu: [
  //             {
  //               title: "Login Simple",
  //               type: "link",

  //               url: `/authentication/login-simple`,
  //             },
  //             {
  //               title: "Login With bg image",
  //               type: "link",

  //               url: `/authentication/login-img`,
  //             },
  //             {
  //               title: "Login with image two",
  //               type: "link",

  //               url: `/authentication/login-bg-img`,
  //             },
  //             {
  //               title: "Login with validation",
  //               type: "link",

  //               url: `/authentication/login-validation`,
  //             },
  //             {
  //               title: "Login with tooltip",
  //               type: "link",

  //               url: `/authentication/login-tooltip`,
  //             },
  //             {
  //               title: "Login with sweetaleart",
  //               type: "link",

  //               url: `/authentication/login-sweetalert`,
  //             },
  //             {
  //               type: "link",
  //               title: "Register Simple",

  //               url: `/authentication/register-simpleimg`,
  //             },
  //             {
  //               title: "Register with Bg image",
  //               type: "link",

  //               url: `/authentication/register-bg-img`,
  //             },
  //             {
  //               title: "Register with Bg Video",
  //               type: "link",

  //               url: `/authentication/register-video`,
  //             },
  //             {
  //               title: "Unlock User",
  //               type: "link",

  //               url: `/authentication/unlock-user`,
  //             },
  //             {
  //               title: "Forgot Password",
  //               type: "link",

  //               url: `/authentication/forget-pwd`,
  //             },
  //             {
  //               title: "Create Passward",
  //               type: "link",

  //               url: `/authentication/create-pwd`,
  //             },
  //             {
  //               title: "Maintenance",
  //               type: "link",

  //               url: `/authentication/maintenance`,
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },

  // {
  //   className: "menu-box",
  //   menu: [
  //     {
  //       icon: <Image />,
  //       title: "Gallery",
  //       type: "sub",
  //       menu: [
  //         {
  //           url: `/gallery/imageGallery`,
  //           title: "Gallery Grid",
  //           type: "link",
  //         },
  //         {
  //           url: `/gallery/imageWithDesc`,
  //           type: "link",
  //           title: "Gallery Grid  Desc",
  //         },
  //         {
  //           url: `/gallery/mesonryGallery`,
  //           type: "link",
  //           title: "Masonry Gallery",
  //         },
  //         {
  //           url: `/gallery/mesonryDesc`,
  //           type: "link",
  //           title: "Masonry With Desc",
  //         },
  //         {
  //           url: `/gallery/imageHover`,
  //           type: "link",
  //           title: "Hover Effect",
  //         },
  //       ],
  //     },
  //     {
  //       icon: <Film />,
  //       title: "Blog",
  //       type: "sub",
  //       menu: [
  //         {
  //           url: `/blog/blogDetail`,
  //           type: "link",
  //           title: "Blog Details",
  //         },
  //         {
  //           url: `/blog/blogSingle`,
  //           type: "link",
  //           title: "Blog Single",
  //         },
  //         {
  //           url: `/blog/blogPost`,
  //           type: "link",
  //           title: "Add Post",
  //         },
  //       ],
  //     },
  //     {
  //       icon: <Package />,
  //       title: "Job Search",
  //       type: "sub",
  //       menu: [
  //         {
  //           url: `/jobSearch/cardView`,
  //           type: "link",
  //           title: "Cards View",
  //         },
  //         {
  //           url: `/jobSearch/job-list`,
  //           type: "link",
  //           title: "List View",
  //         },
  //         {
  //           url: `/jobSearch/job-detail`,
  //           type: "link",
  //           title: "Job Details",
  //         },
  //         {
  //           url: `/jobSearch/job-apply`,
  //           type: "link",
  //           title: "Apply",
  //         },
  //       ],
  //     },
  //     {
  //       icon: <Radio />,
  //       title: "Learning",
  //       type: "sub",
  //       menu: [
  //         {
  //           url: `/learning/learning-list`,
  //           type: "link",
  //           title: "Learning List",
  //         },
  //         {
  //           url: `/learning/learning-detail`,
  //           type: "link",
  //           title: "Detail Course",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Maps",
  //       icon: <Map />,
  //       type: "sub",
  //       menu: [
  //         {
  //           url: `/map/googleMap`,
  //           type: "link",
  //           title: "Google Maps",
  //         },
  //         {
  //           url: `/map/pigeonMap`,
  //           type: "link",
  //           title: "Pigeon Maps",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Editor",
  //       icon: <Edit />,
  //       type: "sub",
  //       menu: [
  //         {
  //           url: `/editor/ckEditor`,
  //           type: "link",
  //           title: "CK  Editor",
  //         },
  //         {
  //           url: `/editor/mdeEditor`,
  //           type: "link",
  //           title: "MDE Editor",
  //         },
  //         {
  //           url: `/editor/acecodeeditor`,
  //           type: "link",
  //           title: "ACE code editor",
  //         },
  //       ],
  //     },
  //     {
  //       url: `/faq`,
  //       icon: <HelpCircle />,
  //       title: "FAQ",
  //       type: "link",
  //     },
  //     {
  //       title: "knowledgebase",
  //       icon: <Sunrise />,
  //       type: "sub",
  //       menu: [
  //         {
  //           url: `/knowledgebase/knowledgebase`,
  //           type: "link",
  //           title: "knowledgebase",
  //         },
  //         {
  //           url: `/knowledgebase/KnowledgeCategory`,
  //           type: "link",
  //           title: "KnowledgeCategory",
  //         },
  //         {
  //           url: `/knowledgebase/KnowledgeDetailContain`,
  //           type: "link",
  //           title: "KnowledgeDetail",
  //         },
  //       ],
  //     },
  //     {
  //       url: `/support-ticket`,
  //       icon: <Users />,
  //       type: "link",
  //       title: "Support Ticket",
  //     },
  //   ],
  // },
];
export const SEARCHMENU = [
  {
    className: "menu-box",
    menu: [
      {
        title: "Dashboards",
        icon: <Home />,
        class: "lan-3",
        menu: [
          {
            title: "Default",
            url: `/dashboard`,
            class: "lan-4",
            type: "link",
          },
          {
            title: "Ecommerce",
            url: `/dashboard/ecommerce`,
            type: "link",
            class: "lan-5",
          },
        ],
      },
      {
        title: "Widgets",
        icon: <Airplay />,
        class: "lan-6",
        menu: [
          {
            url: `/widgets/general`,
            type: "link",
            title: "General",
            bookmark:true,
          },
          {
            url: `/widgets/chart`,
            type: "link",
            title: "Chart",
          },
        ],
      },
      {
        title: "Ecommerce",
        icon: <ShoppingBag />,
        type: "sub",
        menu: [
          {
            url: `/ecommerce/product`,
            title: "Product",
            type: "link",
            bookmark:true,
          },
          {
            url: `/ecommerce/product-page/1`,
            title: "Product Page",
            type: "link",
          },
          {
            url: `/ecommerce/product-list`,
            title: "Product List",
            type: "link",
          },
          {
            url: `/ecommerce/Add-product`,
            title: "Add Product",
            type: "link",
          },
          {
            url: `/ecommerce/payment-details`,
            title: "Payment Detail",
            type: "link",
          },
          {
            url: `/ecommerce/orderhistory`,
            title: "Order History",
            type: "link",
          },
          {
            url: `/ecommerce/pricing`,
            title: "Pricing",
            type: "link",
          },
          {
            url: `/ecommerce/invoice`,
            title: "Invoice",
            type: "link",
          },
          {
            url: `/ecommerce/cart`,
            title: "Cart",
            type: "link",
          },
          {
            url: `/ecommerce/checkout`,
            title: "Checkout",
            type: "link",
          },
          {
            url: `/ecommerce/whishlist`,
            title: "Wishlist",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    className: "menu-box",
    menu: [
      {
        title: "Chat",
        icon: <MessageCircle />,
        menu: [
          {
            url: `/chat-app`,
            type: "link",
            title: "Chat App",
          },
          {
            url: `/video-chat-app`,
            type: "link",
            title: "Video App",
          },
        ],
      },
      {
        title: "Email",
        icon: <Mail />,
        menu: [
          {
            url: `/email-app`,
            type: "link",
            title: "Mail Inbox",
          },
          {
            url: `/email/readmail`,
            type: "link",
            title: "Read Mail",
          },
          {
            url: `/email-app/compose`,
            type: "link",
            title: "Compose",
          },
        ],
      },
      {
        url: `/file-manager`,
        icon: <GitPullRequest />,
        title: "File Manager",
        type: "link",
        bookmark: true,
      },
      {
        url: `/kanban-board`,
        icon: <Monitor />,
        type: "link",
        title: "Kanban Board",
      },
      {
        url: `/bookmark`,
        icon: <Heart />,
        bookmark: true,
        type: "link",
        title: "Bookmark",
      },
      {
        url: `/contact`,
        icon: <List />,
        type: "link",
        title: "Contacts",
      },
      {
        url: `/task`,
        icon: <CheckSquare />,
        type: "link",
        title: "Task",
      },
      {
        url: `/social-app`,
        icon: <Zap />,
        title: "Social App",
        type: "link",
        bookmark: true,
      },

      {
        url: `/todo-app/todo`,
        icon: <Clock />,
        type: "link",
        title: "To-Do",
      },
    ],
  },
  {
    className: "menu-box",
    menu: [
      {
        title: "Forms",
        icon: <FileText />,
        menu: [
          {
            title: "Form Controls",
            menu: [
              {
                title: "Form Validation",
                type: "link",
                url: `/forms/form-validation`,
              },
              {
                title: "Basic Input",
                type: "link",
                url: `/forms/baseInput`,
              },
              {
                title: "Checkbox & Radio",
                type: "link",
                url: `/forms/radio-checkbox`,
              },
              {
                title: "Input Groups",
                type: "link",
                url: `/forms/inputGroup`,
              },
              {
                title: "Mega Option",
                type: "link",
                url: `/forms/megaOptions`,
              },
            ],
          },
          {
            title: "Form Widgets",
            menu: [
              {
                title: "Datepicker",
                type: "link",
            bookmark:true,
                url: `/form-widget/datepicker`,
              },
              {
                title: "Typeahead",
                type: "link",

                url: `/form-widget/typeahead`,
              },
              {
                title: "Rangepicker",
                type: "link",

                url: `/form-widget/rangepicker`,
              },
              {
                title: "Touchspin",
                type: "link",

                url: `/form-widget/touchspin`,
              },
              {
                title: "Select2",
                type: "link",

                url: `/form-widget/select`,
              },
              {
                title: "Switch",
                type: "link",

                url: `/form-widget/switch`,
              },
              {
                title: "Clipboard",
                type: "link",

                url: `/form-widget/clipboard`,
              },
            ],
          },
          {
            title: "Form Layout",
            menu: [
              {
                url: `/form-layout/formDefault`,
                type: "link",
                title: "Form Default",
              },
              {
                url: `/form-layout/formWizard`,
                type: "link",
                title: "Form Wizard",
              },
            ],
          },
        ],
      },
      {
        title: "Tables",
        icon: <Server />,
        menu: [
          {
            title: "React Strap Table",
            url: `/table/reactstraptable`,
            type: "link",
          },
          {
            title: "Data Tables",
            type: "link",
            url: `/table/datatable`,
          },
        ],
      },
    ],
  },
  {
    className: "menu-box",
    menu: [
      {
        title: "Ui-Kits",
        icon: <Box />,
        menu: [
          {
            url: `/ui-kits/typography`,
            title: "Typography",
            type: "link",
          },
          {
            url: `/ui-kits/avatar`,
            title: "Avatars",
            type: "link",
          },
          {
            url: `/ui-kits/helperclass`,
            title: "Helper-Classes",
            type: "link",
          },
          {
            url: `/ui-kits/grid`,
            title: "Grid",
            type: "link",
          },
          {
            url: `/ui-kits/tagsandpills`,
            title: "Tag & Pills",
            type: "link",
          },
          {
            url: `/ui-kits/progress-bar`,
            title: "Progress",
            type: "link",
          },
          {
            url: `/ui-kits/modal`,
            title: "Modal",
            type: "link",
          },
          {
            url: `/ui-kits/alert`,
            title: "Alert",
            type: "link",
          },
          {
            url: `/ui-kits/popover`,
            title: "Popover",
            type: "link",
          },
          {
            url: `/ui-kits/tooltips`,
            title: "Tooltip",
            type: "link",
          },
          {
            url: `/ui-kits/spinner`,
            title: "Spinners",
            type: "link",
          },
          {
            url: `/ui-kits/dropdown`,
            title: "Dropdown",
            type: "link",
          },
          {
            url: `/ui-kits/accordion`,
            title: "Accordion",
            type: "link",
          },
          {
            title: "Tabs",
            type: "sub",
            menu: [
              {
                title: "Bootstrap Tabs",
                type: "link",
                url: `/ui-kits/tab-bootstrap`,
              },
              {
                title: "Line Tabs",
                type: "link",
                url: `/ui-kits/tab-line`,
              },
            ],
          },
          {
            url: `/ui-kits/shadow`,
            title: "Shadow",
            type: "link",
          },
          {
            url: `/ui-kits/list`,
            title: "List",
            type: "link",
          },
        ],
      },
      {
        title: "Bonus Ui",
        type: "sub",
        icon: <FolderPlus />,
        menu: [
          {
            url: `/bonus-ui/scrollable`,
            type: "link",
            title: "Scrollable",
          },
          {
            url: `/bonus-ui/treeview`,
            type: "link",
            title: "Tree",
          },
          {
            url: `/bonus-ui/rating`,
            type: "link",
            title: "Rating",
          },
          {
            url: `/bonus-ui/dropzone`,
            type: "link",
            title: "Dropzone",
          },
          {
            url: `/bonus-ui/tourComponent`,
            type: "link",
            title: "Tour",
          },
          {
            url: `/bonus-ui/sweetAlert`,
            type: "link",
            title: "SweetAlert",
          },
          {
            url: `/bonus-ui/ribbons`,
            type: "link",
            title: "Ribbons",
          },
          {
            url: `/bonus-ui/pagination`,
            type: "link",
            title: "Pagination",
          },
          {
            url: `/bonus-ui/breadcrumb`,
            type: "link",
            title: "Breadcrumb",
          },
          {
            url: `/bonus-ui/rangeSlider`,
            type: "link",
            title: "Range Slider",
          },
          {
            url: `/bonus-ui/imageCropper`,
            type: "link",
            title: "Image Cropper",
          },
          {
            url: `/bonus-ui/stickyNotes`,
            type: "link",
            title: "Sticky",
          },
          {
            url: `/bonus-ui/dragNDropComp`,
            type: "link",
            title: "Drag and Drop",
          },
          {
            url: `/bonus-ui/image-upload`,
            type: "link",
            title: "Upload",
          },
          {
            url: `/bonus-ui/card/basicCards`,
            type: "link",
            title: "Basic Card",
          },
          {
            url: `/bonus-ui/card/draggingCards`,
            type: "link",
            title: "Draggable Card",
          },
          {
            url: `/bonus-ui/timelines/timeline1`,
            type: "link",
            title: "Timeline",
          },
        ],
      },
      {
        title: "Icons",
        icon: <Command />,
        type: "sub",
        menu: [
          {
            url: `/icons/flagIcons`,
            type: "link",
            title: "Flag Icon",
          },
          {
            url: `/icons/fontAwsomeIcon`,
            type: "link",
            title: "Font awesome Icon",
          },
          {
            url: `/icons/icoIcons`,
            type: "link",
            title: "Ico Icon",
          },
          {
            url: `/icons/themifyIcons`,
            type: "link",
            title: "Themify Icon",
          },
          {
            url: `/icons/featherIcons`,
            type: "link",
            title: "Feather Icon",
          },
          {
            url: `/icons/weatherIcons`,
            type: "link",
            title: "Whether Icon",
          },
        ],
      },
      {
        title: "Buttons",
        icon: <Cloud />,
        type: "sub",
        menu: [
          {
            url: `/buttons/default-btn`,
            type: "link",
            title: "Default Style",
          },
          {
            url: `/buttons/groupBtn`,
            type: "link",
            title: "Button Group",
          },
        ],
      },
      {
        title: "Charts",
        icon: <BarChart />,
        type: "sub",
        menu: [
          {
            url: `/charts/apexCharts`,
            type: "link",
            title: "Apex Chart",
            bookmark:true,

          },
          {
            url: `/charts/googleChart`,
            type: "link",
            title: "Google Chart",
          },
          {
            url: `/charts/chartJs`,
            type: "link",
            title: "Chartjs",
          },
          {
            url: `/charts/chartistComponent`,
            type: "link",
            title: "Chartist",
          },
        ],
      },
    ],
  },
  {
    className: "menu-box",
    menu: [
      {
        url: `/samplepage`,
        icon: <Layers />,
        title: "Sample Page",
        type: "sub",
      },
      {
        title: "Others",
        icon: <Layers />,
        type: "sub",
        menu: [
          {
            title: "Error Page",
            type: "sub",
            menu: [
              {
                title: "Error Page 1",
                type: "link",
                url: `/error/error-page1`,
              },
              {
                title: "Error Page 2",
                type: "link",
                url: `/error/error-page2`,
              },
              {
                title: "Error Page 3",
                type: "link",
                url: `/error/error-page3`,
              },
              {
                title: "Error Page 4",
                type: "link",
                url: `/error/error-page4`,
              },
              {
                title: "Error Page 5",
                type: "link",
                url: `/error/error-page5`,
              },
            ],
          },
          {
            title: "Coming Soon",
            type: "sub",

            menu: [
              {
                title: "Coming Sample",
                type: "link",
                url: `/coming/comingsimple`,
              },
              {
                title: "Coming with Bg-video",
                type: "link",
                url: `/coming/comingbgvideo`,
              },
              {
                title: "Coming with Bg-Image",
                type: "link",
                url: `/coming/comingbgimage`,
              },
            ],
          },
          {
            title: "Authentication",
            type: "sub",

            menu: [
              {
                title: "Login Simple",
                type: "link",

                url: `/authentication/login-simple`,
              },
              {
                title: "Login With bg image",
                type: "link",

                url: `/authentication/login-img`,
              },
              {
                title: "Login with image two",
                type: "link",

                url: `/authentication/login-bg-img`,
              },
              {
                title: "Login with validation",
                type: "link",

                url: `/authentication/login-validation`,
              },
              {
                title: "Login with tooltip",
                type: "link",

                url: `/authentication/login-tooltip`,
              },
              {
                title: "Login with sweetaleart",
                type: "link",

                url: `/authentication/login-sweetalert`,
              },
              {
                type: "link",
                title: "Register Simple",

                url: `/authentication/register-simpleimg`,
              },
              {
                title: "Register with Bg image",
                type: "link",

                url: `/authentication/register-bg-img`,
              },
              {
                title: "Register with Bg Video",
                type: "link",

                url: `/authentication/register-video`,
              },
              {
                title: "Unlock User",
                type: "link",

                url: `/authentication/unlock-user`,
              },
              {
                title: "Forgot Password",
                type: "link",

                url: `/authentication/forget-pwd`,
              },
              {
                title: "Create Passward",
                type: "link",

                url: `/authentication/create-pwd`,
              },
              {
                title: "Maintenance",
                type: "link",

                url: `/authentication/maintenance`,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    className: "menu-box",
    menu: [
      {
        icon: <Image />,
        title: "Gallery",
        type: "sub",
        menu: [
          {
            url: `/gallery/imageGallery`,
            title: "Gallery Grid",
            type: "link",
          },
          {
            url: `/gallery/imageWithDesc`,
            type: "link",
            title: "Gallery Grid  Desc",
          },
          {
            url: `/gallery/mesonryGallery`,
            type: "link",
            title: "Masonry Gallery",
          },
          {
            url: `/gallery/mesonryDesc`,
            type: "link",
            title: "Masonry With Desc",
          },
          {
            url: `/gallery/imageHover`,
            type: "link",
            title: "Hover Effect",
          },
        ],
      },
      {
        icon: <Film />,
        title: "Blog",
        type: "sub",
        menu: [
          {
            url: `/blog/blogDetail`,
            type: "link",
            title: "Blog Details",
          },
          {
            url: `/blog/blogSingle`,
            type: "link",
            title: "Blog Single",
          },
          {
            url: `/blog/blogPost`,
            type: "link",
            title: "Add Post",
          },
        ],
      },
      {
        icon: <Package />,
        title: "Job Search",
        type: "sub",
        menu: [
          {
            url: `/jobSearch/cardView`,
            type: "link",
            title: "Cards View",
          },
          {
            url: `/jobSearch/job-list`,
            type: "link",
            title: "List View",
          },
          {
            url: `/jobSearch/job-detail`,
            type: "link",
            title: "Job Details",
          },
          {
            url: `/jobSearch/job-apply`,
            type: "link",
            title: "Apply",
          },
        ],
      },
      {
        icon: <Radio />,
        title: "Learning",
        type: "sub",
        menu: [
          {
            url: `/learning/learning-list`,
            type: "link",
            title: "Learning List",
          },
          {
            url: `/learning/learning-detail`,
            type: "link",
            title: "Detail Course",
          },
        ],
      },
      {
        title: "Maps",
        icon: <Map />,
        type: "sub",
        menu: [
          {
            url: `/map/googleMap`,
            type: "link",
            title: "Google Maps",
          },
          {
            url: `/map/pigeonMap`,
            type: "link",
            title: "Pigeon Maps",
          },
        ],
      },
      {
        title: "Editor",
        icon: <Edit />,
        type: "sub",
        menu: [
          {
            url: `/editor/ckEditor`,
            type: "link",
            title: "CK  Editor",
          },
          {
            url: `/editor/mdeEditor`,
            type: "link",
            title: "MDE Editor",
          },
          {
            url: `/editor/acecodeeditor`,
            type: "link",
            title: "ACE code editor",
          },
        ],
      },
      {
        url: `/faq`,
        icon: <HelpCircle />,
        title: "FAQ",
        type: "link",
      },
      {
        title: "knowledgebase",
        icon: <Sunrise />,
        type: "sub",
        menu: [
          {
            url: `/knowledgebase/knowledgebase`,
            type: "link",
            title: "knowledgebase",
          },
          {
            url: `/knowledgebase/KnowledgeCategory`,
            type: "link",
            title: "KnowledgeCategory",
          },
          {
            url: `/knowledgebase/KnowledgeDetailContain`,
            type: "link",
            title: "KnowledgeDetail",
          },
        ],
      },
      {
        url: `/support-ticket`,
        icon: <Users />,
        type: "link",
        title: "Support Ticket",
      },
    ],
  },
];

