import { WRITE } from "../Constant";

export const moveKeyToLast = (object, keyToMove) => {
  const entries = Object.entries(object);

  entries.sort(([key1], [key2]) => {
    if (key1 === keyToMove) return 1;
    if (key2 === keyToMove) return -1;
    return 0;
  });

  const sortedObject = entries.reduce((result, [key, value]) => {
    result[key] = value;
    return result;
  }, {});

  return sortedObject;
};

export const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (!value) return;

    const nextKey = prefix ? `${prefix}.${key}` : key;
    if (typeof value === "object") {
      transformObjectToDotNotation(value, nextKey, result);
    } else {
      result.push(nextKey);
    }
  });

  return result;
};

const permission = {
  Read: true,
  Write: true,
  "Not Allow": false,
};

export const checkPermissionAccess = (user) => {
  if (!user.isAdmin) return;
  if (user.isSuperAdmin) return true;
  if (!user.permissions) return;
  if (permission[user.permissions.dashboardAllow]) {
    return true;
  }
};

export const isWriteAccess = (user) => {
  return user?.permissions?.dashboardAllow === WRITE
 };
 