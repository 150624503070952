import React, { Fragment, useContext, useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { Breadcrumbs } from "../../../AbstractElements";
import LearningFilter from "../Common/learning-filter";
import LearningDeatil from "./learning-deatil";
import courseContext from "../../../Helper/Course";
import studentContext from "../../../Helper/Student";
import { getSingleCourseDetails } from "../../../utils/courses";
import { useParams } from "react-router-dom";
import Loader from "../../../Layout/Loader";

const DetailCourseContain = () => {
  const { courses, allStudents } = useContext(courseContext);
  const { studentDetails } = useContext(studentContext);
  const location = useParams();
  const [courseDetails, setCourseDetails] = useState(null);

  useEffect(() => {
    const courseDetail = getSingleCourseDetails(
      studentDetails,
      courses,
      location.id,
      allStudents
    );
    setCourseDetails(courseDetail);
  }, []);

  if (!courseDetails) return <Loader />;
  return (
    <Fragment>
      <Breadcrumbs mainTitle={courseDetails.name} parent="Dashboard" />
      <Container fluid={true}>
        <Row>
          <LearningDeatil courseDetails={courseDetails} />
          <LearningFilter courseDetails={courseDetails} />
        </Row>
      </Container>
    </Fragment>
  );
};

export default DetailCourseContain;
