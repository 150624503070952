// dashbaord
import Default from "../Components/Dashboard/Default/Default";
import Ecommerce from "../Components/Dashboard/Ecommerce/Ecommerce";
import Policy from "../Components/Policy";
import General from "../Components/Widgeta/General";
import Chart from "../Components/Widgeta/Chart";
import Project from "../Components/Project";
import CreateListContain from "../Components/Project/Create List";
import KanbanBoard from "../Components/KanbanBoard";
import Product from "../Components/Eccommerce/Products";
import ProductPage from "../Components/Eccommerce/ProductPage";
import ProductList from "../Components/Eccommerce/ProductList";
import PaymentDetails from "../Components/Eccommerce/PaymentDetails";
import OrderHistory from "../Components/Eccommerce/OrderHistory";
import Invoice from "../Components/Eccommerce/Invoice";
import WhishList from "../Components/Eccommerce/Wishlist";
import MailInbox from "../Components/Email/Mail Inbox";
import ReadMail from "../Components/Email/Read Mail";
import Compose from "../Components/Email/Compose";
import ChatApp from "../Components/Chat/ChatApp";
import VideoApp from "../Components/Chat/VideoChat";
import UsersProfileContain from "../Components/User/UsersProfile";
import UsersCardssContain from "../Components/User/UsersCards";
import UsersEditContain from "../Components/User/UsersEdit";
import BookmarksContain from "../Components/BookMarks";
import ContactApp from "../Components/Contact/ContactApp";
import Task from "../Components/Task";
import SocialApp from "../Components/SocialApp";
import TodoContain from "../Components/Todo/index";
import SearchResult from "../Components/Search Result";
import MegaOptionsContain from "../Components/Forms/Form Control/Mega Option";
import SupportTickit from "../Components/Support Tickit";
import RadioCheckbox from "../Components/Forms/Form Control/Radio Checkbox";
import InputGroup from "../Components/Forms/Form Control/Input Group";
import BaseInput from "../Components/Forms/Form Control/BaseInput";
import FormDefault from "../Components/Forms/Form Layout/Form Default";
import FormWizard from "../Components/Forms/Form Layout/Form Wizard";
import BasicTable from "../Components/Tables/Reactstrap/BasicTable";
import Typography from "../Components/UiKits/Typography";
import Avtars from "../Components/UiKits/Avtars";
import HelperClasses from "../Components/UiKits/HelperClass";
import Grid from "../Components/UiKits/Grid";
import TagAndPills from "../Components/UiKits/TagAndPills";
import Progressbars from "../Components/UiKits/Progress";
import Modals from "../Components/UiKits/Modals";
import Alert from "../Components/UiKits/Alert";
import PopOvers from "../Components/UiKits/Popover";
import Toollip from "../Components/UiKits/Tooltip";
import Spinners from "../Components/UiKits/Spinners";
import Dropdowns from "../Components/UiKits/Dropdown";
import Accordian from "../Components/UiKits/Accordian";
import TabBootstrap from "../Components/UiKits/Tabs/BootstrapTab";
import TabLine from "../Components/UiKits/Tabs/LineTab";
import Shadow from "../Components/UiKits/Shadows";
import Lists from "../Components/UiKits/Lists";
import FeatherIcon from "../Components/Icons/FeatherIcon";
import FlagIcon from "../Components/Icons/FlagIcon";
import Fontawesome from "../Components/Icons/FontawesomeIcon";
import IcoIcons from "../Components/Icons/IcoIcon";
import ThemifyIcons from "../Components/Icons/ThemifyIcon";
import WeatherIcons from "../Components/Icons/WeatherIcon";
import DefaultButton from "../Components/Buttons/Default";
import GroupButton from "../Components/Buttons/GroupBtn";
import LearningList from "../Components/Learning/LearningList";
import FAQContent from "../Components/FAQ";
import BlogDetail from "../Components/Blog/BlogDetail";
import BlogSingle from "../Components/Blog/BlogSingle";
import BlogPost from "../Components/Blog/BlogPost";
import ApplyContain from "../Components/JobSearch/Apply/index";
import JobDetails from "../Components/JobSearch/JobDetail";
import ListView from "../Components/JobSearch/ListView";
import JobCardsContain from "../Components/JobSearch/JobCardView";
import KnowledgeCategoryContain from "../Components/Knowledgebase/Knowledge Category";
import KnowledgeBaseContain from "../Components/Knowledgebase/Knowledgebase";
import KnowledgeDetailContain from "../Components/Knowledgebase/Knowledge Details";
import CkEditorContain from "../Components/Editor/CkEditor";
import ACEEditorContain from "../Components/Editor/Ace code editor/ACECodeEditor";
import MdeEDitorContain from "../Components/Editor/MDE Editor/MDEEditor";
import MesonryGalleryContain from "../Components/Gallery/MesonryGallery";
import MesonryDescContain from "../Components/Gallery/MesonryDesc";
import ImageHoverContain from "../Components/Gallery/ImageHover";
import ImageWithDescContain from "../Components/Gallery/ImageWithDesc";
import ImageGalleryContain from "../Components/Gallery/ImageGallery";
import SampleCard from "../Components/SampleCard";
import FormValidationContain from "../Components/Forms/Form Control/Form Validation";
import DetailCourseContain from "../Components/Learning/DetailCourse";
import ApexchartsContain from "../Components/Charts/apexCharts";
import GoogleChartsContain from "../Components/Charts/googleCharts";
import DraggableContain from "../Components/Calender/Draggable Calender/index";
import ChartistContain from "../Components/Charts/chartistCharts";
import ChartJsContain from "../Components/Charts/chartsjs";
import DataTablesContain from "../Components/Tables/DataTable";
import Scrollable from "../Components/BonusUi/Scrollable";
import TreeView from "../Components/BonusUi/TreeView";
import Rating from "../Components/BonusUi/Rating";
import Dropzone from "../Components/BonusUi/Dropzones";
import Tour from "../Components/BonusUi/Tour";
import SweetAlert from "../Components/BonusUi/SweetAlert";
import Ribbons from "../Components/BonusUi/Ribbons";
import Pagination from "../Components/BonusUi/Pagination";
import Breadcrumb from "../Components/BonusUi/Breadcrumb";
import RangeSlider from "../Components/BonusUi/RangeSlider";
import ImageCropper from "../Components/BonusUi/ImageCropper";
import StickyContain from "../Components/BonusUi/Sticky";
import DragAndDropContain from "../Components/BonusUi/DragAndDrop";
import UploadImageContain from "../Components/BonusUi/ImageUpload";
import BasicCardContain from "../Components/BonusUi/Cards/BasicCards";
import DragCards from "../Components/BonusUi/Cards/DragCards";
import TimeLineContain from "../Components/BonusUi/Timeline/timeline";
import PricingMembershipContain from "../Components/Eccommerce/PricingMemberShip";
import Cart from "../Components/Eccommerce/ProductCart";
import DateTimeContain from "../Components/Forms/Form Widgets/Date and Time";
import FormTypeaheadContain from "../Components/Forms/Form Widgets/Form Typeahead";
import FormTouchspinContain from "../Components/Forms/Form Widgets/Form Touchspin";
import Switch from "../Components/Forms/Form Widgets/Form Switch";
import Select from "../Components/Forms/Form Widgets/Form Select2";
import Clipboard from "../Components/Forms/Form Widgets/Form Clipboard";
import DatePicker from "../Components/Forms/Form Widgets/Form DataPicker";
import RangePicker from "../Components/Forms/Form Widgets/Form Date Rangepicker";
import CheckOutContain from "../Components/Eccommerce/CheckOut/index";
import AddProductName from "../Components/Eccommerce/AddProduct";
import FileManagerContain from "../Components/FileManager/index"
import MapJsContain from "../Components/Maps/pigeon-maps";
import GoolgeMapsContain from "../Components/Maps/GoogleMaps";
import Courses from "../Components/Courses";
import StudentCard from "../Components/StudentCard";
import Attendance from "../Components/Attendance";
import Quizzes from "../Components/Quizzes";
import Profile from "../Components/Profile";
import CourseDetails from "../Components/CourseDetails";
import QuizResult from "../Components/QuizResult";
import Quiz from '../Components/Quizzes/Quiz'
import Result from "../Components/Result";

export const routes = [
  //dashboard
  
  // { path: `/forms/form-validation`, Component: <FormValidationContain /> },
  { path: `/dashboard`, Component: <Default /> },
  // { path: `/dashboard/ecommerce`, Component: <Ecommerce /> },
  { path: `/courses`, Component: <Courses /> },
  { path: `/courses/:id`, Component: <CourseDetails /> },
  { path: `/quizzes`, Component: <Quizzes /> },
  { path: `/quizzes-result`, Component: <QuizResult /> },
  { path: `/student-card`, Component: <StudentCard /> },
  { path: `/profile`, Component: <Profile /> },
  { path: `/attendance`, Component: <Attendance /> },
  { path: `/quizzes/:name`, Component: <Quiz /> },
  { path: `/result`, Component: <Result />},
  // { path: `/policy`, Component: <Policy />},


  // //widgets
  // { path: `/widgets/general`, Component: <General /> },
  // { path: `/widgets/chart`, Component: <Chart /> },

  //Project
  // { path: `/project/project-list`, Component: <Project /> },
  // { path: `/project/new-project`, Component: <CreateListContain /> },
  // { path: `/kanban-board`, Component: <KanbanBoard /> },
  // { path: `/file-manager`, Component: <FileManagerContain /> },
  // { path: `/ecommerce/product`, Component: <Product /> },
  // { path: `/ecommerce/product-page/1`, Component: <ProductPage /> },
  // { path: `/ecommerce/product-list`, Component: <ProductList /> },
  // { path: `/ecommerce/payment-details`, Component: <PaymentDetails /> },
  // { path: `/ecommerce/orderhistory`, Component: <OrderHistory /> },
  // { path: `/ecommerce/invoice`, Component: <Invoice /> },
  // { path: `/ecommerce/wishlist`, Component: <WhishList /> },
  // { path: `/email-app`, Component: <MailInbox /> },
  // { path: `/email/readmail`, Component: <ReadMail /> },
  // { path: `/email-app/compose`, Component: <Compose /> },
  // { path: `/chat-app`, Component: <ChatApp /> },
  // { path: `/video-chat-app`, Component: <VideoApp /> },

  // { path: `/users/userProfile`, Component: <UsersProfileContain /> },
  // { path: `/users/userEdit`, Component: <UsersEditContain /> },
  // { path: `/users/userCards`, Component: <UsersCardssContain /> },

  // { path: `/bookmark`, Component: <BookmarksContain /> },

  // { path: `/contact`, Component: <ContactApp /> },

  // { path: `/task`, Component: <Task /> },
  // { path: `/social-app`, Component: <SocialApp /> },
  // { path: `/todo-app/todo`, Component: <TodoContain /> },

  // { path: `/searchresult`, Component: <SearchResult /> },

  // { path: `/support-ticket`, Component: <SupportTickit /> },

  // { path: `/forms/radio-checkbox`, Component: <RadioCheckbox /> },
  // { path: `/forms/inputGroup`, Component: <InputGroup /> },
  // { path: `/forms/megaOptions`, Component: <MegaOptionsContain /> },
  // { path: `/forms/baseInput`, Component: <BaseInput /> },

  // { path: `/form-layout/formDefault`, Component: <FormDefault /> },
  // { path: `/form-layout/formWizard`, Component: <FormWizard /> },

  // { path: `/table/reactstraptable`, Component: <BasicTable /> },

  // { path: `/ui-kits/typography`, Component: <Typography /> },
  // { path: `/ui-kits/avatar`, Component: <Avtars /> },
  // { path: `/ui-kits/helperclass`, Component: <HelperClasses /> },
  // { path: `/ui-kits/grid`, Component: <Grid /> },
  // { path: `/ui-kits/tagsandpills`, Component: <TagAndPills /> },
  // { path: `/ui-kits/progress-bar`, Component: <Progressbars /> },
  // { path: `/ui-kits/modal`, Component: <Modals /> },
  // { path: `/ui-kits/alert`, Component: <Alert /> },
  // { path: `/ui-kits/popover`, Component: <PopOvers /> },
  // { path: `/ui-kits/tooltips`, Component: <Toollip /> },
  // { path: `/ui-kits/spinner`, Component: <Spinners /> },
  // { path: `/ui-kits/dropdown`, Component: <Dropdowns /> },
  // { path: `/ui-kits/accordion`, Component: <Accordian /> },

  // { path: `/ui-kits/tab-bootstrap`, Component: <TabBootstrap /> },
  // { path: `/ui-kits/tab-line`, Component: <TabLine /> },

  // { path: `/ui-kits/shadow`, Component: <Shadow /> },
  // { path: `/ui-kits/list`, Component: <Lists /> },
  // { path: `/icons/featherIcons`, Component: <FeatherIcon /> },
  // { path: `/icons/flagIcons`, Component: <FlagIcon /> },
  // { path: `/icons/fontAwsomeIcon`, Component: <Fontawesome /> },
  // { path: `/icons/icoIcons`, Component: <IcoIcons /> },
  // { path: `/icons/themifyicons`, Component: <ThemifyIcons /> },
  // { path: `/icons/weathericons`, Component: <WeatherIcons /> },
  // { path: `/buttons/default-btn`, Component: <DefaultButton /> },
  // { path: `/buttons/groupBtn`, Component: <GroupButton /> },
  // { path: `/learning/learning-list`, Component: <LearningList /> },
  // { path: `/faq`, Component: <FAQContent /> },
  // { path: `/blog/blogDetail`, Component: <BlogDetail /> },
  // { path: `/blog/blogSingle`, Component: <BlogSingle /> },
  // { path: `/blog/blogPost`, Component: <BlogPost /> },
  // { path: `/jobSearch/job-apply`, Component: <ApplyContain /> },
  // { path: `/jobSearch/job-detail`, Component: <JobDetails /> },
  // { path: `/jobSearch/job-list`, Component: <ListView /> },
  // { path: `/jobSearch/cardView`, Component: <JobCardsContain /> },
  // { path: `/knowledgebase/knowledgecategory`, Component: <KnowledgeCategoryContain /> },
  // { path: `/knowledgebase/knowledgebase`, Component: <KnowledgeBaseContain /> },
  // { path: `/knowledgebase/KnowledgeDetailContain`, Component: <KnowledgeDetailContain /> },
  // { path: `/editor/ckEditor`, Component: <CkEditorContain /> },
  // { path: `/editor/acecodeeditor`, Component: <ACEEditorContain /> },
  // { path: `/editor/mdeEditor`, Component: <MdeEDitorContain /> },
  // { path: `/gallery/mesonryGallery`, Component: <MesonryGalleryContain /> },
  // { path: `/gallery/mesonryDesc`, Component: <MesonryDescContain /> },
  // { path: `/gallery/imageHover`, Component: <ImageHoverContain /> },
  // { path: `/gallery/imageWithDesc`, Component: <ImageWithDescContain /> },
  // { path: `/gallery/imageGallery`, Component: <ImageGalleryContain /> },
  // { path: `/samplepage`, Component: <SampleCard /> },
  // { path: `/map/pigeonMap`, Component: <MapJsContain /> },
  // { path: `/map/googleMap`, Component: <GoolgeMapsContain /> },
  // { path: `/learning/learning-detail`, Component: <DetailCourseContain /> },
  // { path: `/charts/apexCharts`, Component: <ApexchartsContain /> },
  // { path: `/charts/googleChart`, Component: <GoogleChartsContain /> },
  // { path: `/charts/googleChart`, Component: <ChartJsContain /> },
  // { path: `/calendar/draggable-calendar`, Component: <DraggableContain /> },
  // { path: `/charts/chartistComponent`, Component: <ChartistContain /> },
  // { path: `/charts/chartJs`, Component: <ChartJsContain /> },
  // { path: `/table/datatable`, Component: <DataTablesContain /> },
  // { path: `/bonus-ui/scrollable`, Component: <Scrollable /> },
  // { path: `/bonus-ui/treeview`, Component: <TreeView /> },
  // { path: `/bonus-ui/rating`, Component: <Rating /> },
  // { path: `/bonus-ui/dropzone`, Component: <Dropzone /> },
  // { path: `/bonus-ui/tourComponent`, Component: <Tour /> },
  // { path: `/bonus-ui/sweetAlert`, Component: <SweetAlert /> },
  // { path: `/bonus-ui/ribbons`, Component: <Ribbons /> },
  // { path: `/bonus-ui/pagination`, Component: <Pagination /> },
  // { path: `/bonus-ui/breadcrumb`, Component: <Breadcrumb /> },
  // { path: `/bonus-ui/rangeSlider`, Component: <RangeSlider /> },
  { path: `/bonus-ui/imageCropper`, Component: <ImageCropper /> },
  // { path: `/bonus-ui/stickyNotes`, Component: <StickyContain /> },
  // { path: `/bonus-ui/dragNDropComp`, Component: <DragAndDropContain /> },
  // { path: `/bonus-ui/image-upload`, Component: <UploadImageContain /> },
  // { path: `/bonus-ui/card/basicCards`, Component: <BasicCardContain /> },
  // { path: `/bonus-ui/card/draggingCards`, Component: <DragCards /> },
  // { path: `/bonus-ui/timelines/timeline1`, Component: <TimeLineContain /> },
  // { path: `/ecommerce/pricing`, Component: <PricingMembershipContain /> },
  // { path: `/ecommerce/whishlist`, Component: <WhishList /> },
  // { path: `/ecommerce/cart`, Component: <Cart /> },
  // { path: `/form-widget/datepicker`, Component: <DateTimeContain /> },
  // { path: `/form-widget/typeahead`, Component: <FormTypeaheadContain /> },
  // { path: `/form-widget/touchspin`, Component: <FormTouchspinContain /> },
  // { path: `/form-widget/switch`, Component: <Switch /> },
  // { path: `/form-widget/switch`, Component: <Switch /> },
  // { path: `/form-widget/switch`, Component: <Switch /> },
  // { path: `/form-widget/Select`, Component: <Select /> },
  // { path: `/form-widget/clipboard`, Component: <Clipboard /> },
  // { path: `/form-widget/datepicker`, Component: <DatePicker /> },
  // { path: `/form-widget/rangepicker`, Component: <RangePicker /> },
  // { path: `/ecommerce/checkout`, Component: <CheckOutContain /> },
  // { path: `/ecommerce/Add-product`, Component: <AddProductName /> },

];
