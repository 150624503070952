import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { Close, SaveChanges } from "../../../../Constant";

const CommonModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggler}
      size={props.size}
      centered
      className={props.modalClass}
    >
      <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
      <ModalBody className={props.bodyClass}>{props.children}</ModalBody>
      <ModalFooter className={props.footerClass}>
        <Btn attrBtn={{ color: !props.confirmButtonText ? 'primary' : "secondary", onClick: props.toggler }}>
          {Close}
        </Btn>
        {props.confirmButtonText && <Btn
          attrBtn={{
            color: "primary",
            onClick: () => props.confirmHandler(),
            type: props.confirmBtnType,
          }}
        >
          {props.confirmButtonText}
        </Btn>}
      </ModalFooter>
    </Modal>
  );
};

export default CommonModal;
