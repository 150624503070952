import React, { useEffect } from "react";
import CommonModal from "../../Components/UiKits/Modals/common/modal";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { Btn, P } from "../../AbstractElements";
import { VERIFIED_EMAIL } from "../../Constant";

const PromptModal = (props) => {
  const { modalText, btnFunction, isBtn, btnText, innerHtml, modalAction, modal, type, isSecondary, secondaryText, btnSecondary, footerClass, disabled, showTimer, seconds, } = props;

  useEffect(() => {
    if (type === VERIFIED_EMAIL) {
      btnFunction()
    }
  }, [])

  return (
    <Modal
      isOpen={true}
      centered
      className={modal}
    >
      <ModalBody className="f-w-500">
        {innerHtml ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: modalText }} />
          </>
        ) : (
          modalText
        )}
      </ModalBody>
      {modalAction}
      <ModalFooter className={`border-0 ${footerClass}`}>
        {isSecondary && (<Btn
          attrBtn={{
            color: "secondary",
            onClick: () => btnSecondary(),
          }}
        >
          {secondaryText}
        </Btn>
        )}
        {isBtn && (
          <div>
            {showTimer && seconds !== 0 && <div className="d-flex align-items-center email-verification-timer">Resend In: <span>00:{seconds < 9 ? `0${seconds}` : seconds}</span>
            </div>}

            <Btn
              attrBtn={{
                color: "primary",
                disabled,
                onClick: () => btnFunction(),
              }}
            >
              {btnText}
            </Btn>
          </div>
        )}
      </ModalFooter>
    </Modal>
  )
};

export default PromptModal;
