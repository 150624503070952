import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import RadioButton from "../../CommonElements/Input/RadioButton";
import { FormGroup, Label } from "reactstrap";
import { H4, P } from "../../AbstractElements";
import courseContext from "../../Helper/Course";
import moment from "moment/moment";
import { COURSES_ONBOARD, NEW_COURSE_ENROLLMENT } from "../../Constant";
import Dropzone1 from "../../Components/BonusUi/Dropzones/Dropzone1";
import { toast } from "react-toastify";
import Loader from "../Loader";
import CardsClass from "../../Components/JobSearch/JobCardView/Cards";

const CourseForm = ({ formik, type,setIsSubmitDisabled }) => {
  const { courses, generalPaymentDetail } = useContext(courseContext);
  const [coursesData, setCoursesData] = useState([]);
  const [scrollBottom,setScrollBottom] =useState(false);
  const inNewCourseEnroll = type === NEW_COURSE_ENROLLMENT;
  const isSelectedCourse = courses.find(
    (cour) => cour.id === formik.values.courseId
  );

  const getCoursesTiming = async () => {
    const studentGender = formik.values.gender;
    const filteredCourses = courses.filter((res) => {
      return (
        res.courseGenderType == studentGender ||
        res.courseGenderType === "both" ||
        !res.courseGenderType
      );
    });
    let timingsData = [];
    filteredCourses.map((item) => {
      if (item.admissionOpen) timingsData.push(item);
    });
    // console.log(timingsData);
    setCoursesData(timingsData);
  };

  const feeScreenshotImageHanlder = ({ target }) => {
    if (target.files.length > 2) {
      toast.error("Please select max 2 files");
    } else {
      const imagesPreviews = [];
      const files = target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file && file.size <= 1048576) {
          imagesPreviews.push(file);
        } else {
          toast.error("Please upload a screenshot smaller than 1MB");
        }
      }
      formik.setFieldValue("images", imagesPreviews);
      setIsSubmitDisabled(imagesPreviews.length === 0);
    }
  };
  useEffect(() => {
    getCoursesTiming();
  }, [courses]);
  
  const bottomRef = useRef(null);

  useEffect(()=>{
    if (scrollBottom && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      setScrollBottom(false);
    }
  },[scrollBottom]);

  return (
    <Fragment>
      <H4 attrH4={{ className: "show-color m-b-25 text-center" }}>
        Select your course
      </H4>
      <div
        className={`course-form ${inNewCourseEnroll ? "scrollar-form removeScrollbar" : ""
          }`}
      >
        <FormGroup className="m-b-20 course-radio">
          <Label>In which module you want to enroll?</Label>
          {/* {coursesData?.length ? coursesData.map((course) => (

            <RadioButton
              value={course.id}
              key={course.id}
              checked={formik.values.courseId === course.id}
              onChange={formik.handleChange}
              disabled={course.seatsFull}
              onBlur={formik.handleBlur}
              name="courseId"
              label={
                <CourseCard dadata={course} />
              }
            />
          )) : <P>Fetching courses ...</P>} */}
          {coursesData?.length ?
          <div className="removeScrollbar course-selection-onboard">
            <CardsClass
              data={coursesData}
              formik={formik}
              cardCol={12}
              type={COURSES_ONBOARD}
              setScrollBottom={setScrollBottom}
            />
          </div>
             :
            <P>Fetching courses ...</P>}
          {formik.errors["courseId"] && (
            <span className="error-message">{formik.errors["courseId"]}</span>
          )}
        </FormGroup>
        {inNewCourseEnroll && (
          <>
            <FormGroup>
              <div ref={bottomRef}></div>
              <Label>Upload Fee Screenshot</Label>
              <Dropzone1
                imageChangeHandler={feeScreenshotImageHanlder}
                images={formik.values.images}
                isMultiple={true}
              />
              {formik.errors["images"] && formik.touched["images"] && (
                <span className="error-message">{formik.errors["images"]}</span>
              )}
            </FormGroup>
            {formik.values.courseId && (
              <P
                innerHtml={
                  isSelectedCourse.paymentDetail
                    ? isSelectedCourse.paymentDetail
                    : generalPaymentDetail
                }
              />
              
            )}
           
          </>
        )}
     
      </div>
    </Fragment>
  );
};

export default CourseForm;
