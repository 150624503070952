import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import H3 from '../Headings/H3Element';
import { Home } from 'react-feather';

const Breadcrumbs = (props) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col sm="6">
              <H3 attrH3={{ className: 'custom-breadcrumb'  }}>{props.mainTitle}</H3>
            </Col>
            {/* <Col sm="6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to={`/dashboard`}><Home /></Link></li>
                <li className="breadcrumb-item">{props.parent}</li>
                {props.subParent ? <li className="breadcrumb-item">{props.subParent}</li> : ''}
                {props.title? <li className="breadcrumb-item active">{props.title}</li>:""}
              </ol>
            </Col> */}

          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default Breadcrumbs;