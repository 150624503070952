import React, { useContext, useState } from "react";
import { Btn, H4, P } from "../../AbstractElements";
import { FormGroup, Label } from "reactstrap";
import Dropzone1 from "../../Components/BonusUi/Dropzones/Dropzone1";
import courseContext from "../../Helper/Course";
import studentContext from "../../Helper/Student";
import { toast } from "react-toastify";
import { FEE_SUBMISSION_FAILED } from "../../Constant";

const FeeSubmission = ({
  props: { values, formHeading, touched, errors, setFieldValue, type, index },
}) => {
  const { generalPaymentDetail } = useContext(courseContext);
  const { currentCourse } = useContext(studentContext);
  const [openImage, setOpenImage] = useState(false);

  const failedScreenshot = currentCourse.feeScreenshots[index];

  const feeScreenshotImageHanlder = ({ target }) => {
    if (target.files.length > 2) {
      toast.error("Please select max 2 files");
    } else {
      const imagesPreviews = [];
      const files = target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file && file.size <= 1048576) {
          imagesPreviews.push(file);
        } else {
          toast.error("Please upload a screenshot smaller than 1MB");
        }
      }
      setFieldValue("images", imagesPreviews);
    }
  };
  return (
    <div>
      <H4 attrH4={{ className: "show-color m-b-25 text-center" }}>
        {formHeading}
      </H4>
      <div className={`course-form scrollar-form removeScrollbar `}>
        <FormGroup>
          <Label>Upload Fee Screenshot</Label>
          <Dropzone1
          name='feeScreenshot'
            imageChangeHandler={feeScreenshotImageHanlder}
            images={values.images}
            isMultiple={true}
          />
          {errors["images"] && touched["images"] && (
            <span className="error-message" id='feeScreenshot'>{errors["images"]}</span>
          )}
        </FormGroup>
        {type === FEE_SUBMISSION_FAILED && (
          <>
            <Btn
              attrBtn={{
                color: "primary",
                className: "w-100 m-b-10",
                type: "button",
                onClick: () => setOpenImage(!openImage),
              }}
            >
              {openImage ? "Close" : "Show"} Failed Screeshot
            </Btn>
           {openImage && <div className="failed-screenshots removeScrollbar">
              <img src={failedScreenshot.image} />
              {failedScreenshot.image2 && <img src={failedScreenshot.image2} />}
            </div>}
          </>
        )}
        <P
          innerHtml={
            currentCourse.paymentDetail
              ? currentCourse.paymentDetail
              : generalPaymentDetail
          }
        />
      </div>
    </div>
  );
};

export default FeeSubmission;
