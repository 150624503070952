import { Card, CardBody, Col } from "reactstrap";
import TotalEarningCardHeader from "./TotalEarningCardHeader";
import QuizDark from '../../../../assets/images/quiz-dark.png';
import QuizWhite from '../../../../assets/images/quiz-white.png';

import Chart from "react-apexcharts";
import { apexMixedCharts } from "../ChartsData/ApexChart";
import { useContext } from "react";
import studentContext from "../../../../Helper/Student";
import { H4, H5, P } from "../../../../AbstractElements";
import {
  calculateAveragePercentage,
  calculateGrade,
} from "../../../../utils/result";
import CustomizerContext from "../../../../Helper/Customizer";

const TotalEarning = ({ showOnboard }) => {
  const {
    studentDetails: { result },
  } = useContext(studentContext);
  const { mix_background_layout } = useContext(CustomizerContext);

  const resultLabel = showOnboard ? ['HTML-Quiz', 'CSS-Quiz', 'JAVASCRIPT-Quiz'] : result?.length ? result.map((res) => res.category + "-" + res.name) : [];
  const resultValue = showOnboard ? [30, 40, 20] : result?.length
    ? result.map((res) => res.marks.toFixed(0))
    : [];
  apexMixedCharts.options.xaxis.categories = resultLabel;
  apexMixedCharts.series[0].data = resultValue;

  return (
    <Col xxl="8" xl="8" lg="8">
      <Card className="our-earning grade-chart">
        {(resultLabel?.length || showOnboard) ?
          <>
            <TotalEarningCardHeader />
            <CardBody className="d-flex grade-chart-container">
              <div className="flex-grow-1 grade-chart grade-chart">
                <Chart
                  options={apexMixedCharts.options}
                  series={apexMixedCharts.series}
                  type="bar"
                  height={260}
                />
              </div>
              <div className="grade-container">
                <H4 attrH4={{ className: "show-color" }}>Summary</H4>
                <div className="d-flex gap-2 m-b-10 m-t-30">
                  <div className="grade-icon-container">
                    <i className="fa fa-percent"></i>{" "}
                  </div>
                  <div>
                    <P attrPara={{ className: "m-0 show-color f-w-600" }}>
                      {(result?.length || showOnboard)
                        ? calculateAveragePercentage(resultValue) + "%"
                        : ""}
                    </P>
                    <P attrPara={{ className: "m-0 show-color f-w-400" }}>
                      Percentage
                    </P>
                  </div>
                </div>
                <div className="d-flex gap-2 m-b-10">
                  <div className="grade-icon-container">
                    <i className="fa fa-star-o"></i>
                  </div>
                  <div>
                    <P attrPara={{ className: "m-0 show-color f-w-600" }}>
                      {(result?.length || showOnboard) ? calculateGrade(resultValue) : ""}
                    </P>
                    <P attrPara={{ className: "m-0 show-color f-w-400" }}>Grade</P>
                  </div>
                </div>
                <div className="d-flex gap-2">
                  <div className="grade-icon-container">
                    <i className="fa fa-check"></i>
                  </div>
                  <div>
                    <P attrPara={{ className: "m-0 show-color f-w-600" }}>
                      {showOnboard ? 3: result?.length ? result?.length : ""}
                    </P>
                    <P
                      attrPara={{
                        className: "m-0 show-color f-w-400 text-whitespace",
                      }}
                    >
                      Total Attempted
                    </P>
                  </div>
                </div>
              </div>
            </CardBody>
          </> :
          <div className='un-attempt-quiz'>
            <img src={mix_background_layout === 'dark-only' ? QuizDark : QuizWhite} className="w-100 h-100" />
            <H4 attrH4={{ className: "show-color" }}>Attempt any Quiz to unlock the feature</H4>
          </div>
        }
        {/* <TotalEarningCardFooter /> */}
      </Card>
    </Col>
  );
};

export default TotalEarning;
