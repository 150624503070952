import Tooltip from "../Components/GuidedTour/Tooltip";

export const genders = [
  {
    name: "Male",
    value: "male",
  },
  {
    name: "Female (Hijab is obligatory)",
    value: "female",
  },
  {
    name: "Other",
    value: "other",
  },
];

export const marketing = [
  'Recommended by friend or our student',
  'Social Media (Facebook/Twitter/Youtube)'
]

export const courseData = [
  {
    name: 'EX-MS-20 advanced',
    time: 'SAT & SUN | 9:30 AM - 11:30 AM'
  },
  {
    name: 'EX-MS-21 advanced',
    time: 'SAT & SUN | 9:30 AM - 11:30 AM'
  },
  {
    name: 'EX-MS-22 advanced',
    time: 'SAT & SUN | 9:30 AM - 11:30 AM'
  }
]

export const qualificationData = [
  {
    label: "Matric",
    value: "Matric"
  },
  {
    label: "Intermediate",
    value: "Intermediate"
  },
  {
    label: "Undergraduate",
    value: "Undergraduate",

  },
  {
    label: "Graduate",
    value: "Graduate",
  },
  {
    label: "Masters",
    value: "Masters"
  },
  {
    label: "PHD",
    value: "PHD"
  },
  {
    label: "Other",
    value: "Other"
  }
]

export const guidedTourSteps = [
  {
    // content: "These are our super awesome projects!",
    selector: ".welcome-message",
    content: "Welcome to the enhanced student portal, version 2.0!",
  },
  {
    content: ({ close }) => (
      <Tooltip
        title="Attendance Report"
        text={`Here you can review your attendance report and view the total count. `}
      />
    ),
    selector: ".attendance-report",
  },
  {
    content: ({ close }) => (
      <Tooltip
        title="Quiz Grade Report"
        text={`Here, you can conveniently access and review your quiz results, grades,
and overall percentage.`}
      />
    ),
    selector: ".grade-chart",
  },
  {
    content: ({ close }) => (
      <Tooltip
        title="Upcoming Classes"
        text={`Here you can view the schedule for all the upcoming classes in your
courses.`}
      />
    ),
    selector: ".upcoming-classes",
  },
  {
    content: ({ close }) => (
      <Tooltip
        title='My Courses'
        text={`Here is a list of all the courses you are currently enrolled in.`}
      />
    ),
    selector: ".my-courses",
  },
  {
    content: ({ close }) => (
      <Tooltip
        title="Theme Functionality"
        text={`Here, you can customize the theme by selecting different options.
      Additionally, clicking on your avatar will open a dropdown menu where
      you can edit your profile settings.`}
      />
    ),
    selector: ".theme-fucntionality",
  },
];