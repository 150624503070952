import { useContext, useEffect } from "react";
import Greetingcard from "./GreetingCard";
import TotalUserCard from "./TotalUser/TotalUserCard.jsx";
import TotalEarning from "./TotalEarning/TotalEarning.jsx";
import AppoimentCard from "./Appoiment/AppoimentCard.jsx";
import RecentActivity from "./RecentActivity/RecentActivity.jsx";
import { Alerts, Breadcrumbs } from "../../../AbstractElements";
import { Container, Row } from "reactstrap";
import BoxList from "../../Learning/LearningList/BoxList.jsx";
import studentContext from "../../../Helper/Student/index.jsx";
import { useTour } from "@reactour/tour/dist";

const Default = () => {
  const { loginAdmin, setStudentDetails, studentDetails } = useContext(studentContext);
  const { setIsOpen, currentStep, steps, styles, isOpen } = useTour()

  const adminLogin = () => {
    return (loginAdmin &&
      <Alerts
        attrAlert={{
          color: "primary dark",
          style: { width: "fit-content" },
          className: "mx-auto m-b-15",
        }}
      >
        Login as an student {studentDetails?.email} navigate to{" "}
        <b
          className="f-w-500 show-cursor"
          onClick={() => {
            setStudentDetails(null);
            localStorage.removeItem("loginAsUser");
          }}
        >
          home
        </b>
      </Alerts>
    )
  }



  useEffect(() => {
    if (!localStorage.getItem("tooltipView")) {
      setIsOpen(true)
    }
  }, [])

  return (
    <>
      <Breadcrumbs mainTitle="Dashboard" parent="Dashboard" />
      <Container fluid={true} className="dashboard-default">
        {adminLogin()}
        <Row className='welcome-message'>
          <Greetingcard />
          <AppoimentCard />
          <TotalUserCard showOnboard={isOpen} />
          <TotalEarning showOnboard={isOpen} />
          <RecentActivity />
          <BoxList isMyCourses={true} col="12" />
        </Row>
      </Container>
    </>
  );
};

export default Default;
