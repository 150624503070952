import { Link } from "react-router-dom";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn, H4, P, Image } from "../../../AbstractElements";
import imgg from "../../../assets/images/logo/logo-black.png";
import { useFormik } from "formik/dist";
import { emailSchema, passwordResetSchema } from "../../../Validation";
import CustomInput from "../../../CommonElements/Input";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { changePassword, forgetpswrd } from "../../../Service/Firebase";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreatePwd = () => {
  const [oobCode, setOobCode] = useState(null);
  const navigate = useNavigate();

  const formSubmitHandler = async ({ email, password, cPassword }) => {
    try {
      if (oobCode && password !== cPassword) {
        toast.error("Password and confirm password must be same");
      } else {
        await changePassword(oobCode, password);
        toast.success("Password changed successfully");
        navigate("/login");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const formik = useFormik({
    initialValues: { password: "", cPassword: "" },
    validationSchema: passwordResetSchema,
    onSubmit: formSubmitHandler,
  });

  useEffect(() => {
    const url = window.location.search.split("oobCode=");
    if (url[1]) {
      setOobCode(url[1] ? url[1].split("&apiKey")[0] : null);
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <section>
      <Container fluid={true} className="p-0">
        <Row className="m-0">
          <Col xl="12 p-0">
            <div
              className="login-card"
            >
              <div>
                <div className="m-b-20">
                  <Image
                    attrImage={{
                      src: `${imgg}`,
                      alt: "",
                      width: 300,
                      height: 100,
                    }}
                  />
                </div>
              </div>
              <div className="login-main">
                <Form
                  onSubmit={formik.handleSubmit}
                  className="theme-form login-form"
                >
                  <H4 attrH4={{ className: "mb-3" }}>
                    {"Reset Your Password"}
                  </H4>
                  <>
                    <CustomInput
                      name="password"
                      type="password"
                      value={formik.values.password}
                      formik={formik}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isPasswordField={true}
                      label="Password"
                    />
                    <div className="m-t-20">
                      <CustomInput
                        name="cPassword"
                        type="password"
                        value={formik.values.cPassword}
                        formik={formik}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        label="Confirm Password"
                      />
                    </div>
                  </>
                  {/* )} */}
                  {/* <FormGroup className="position-realative form-group pass-hide">
                    <Label>{NewPassword}</Label>
                    <Input
                      type="password"
                      name="login[password]"
                      required=""
                      placeholder="*********"
                    />
                    <div className="show-hide">
                      <span className="show"></span>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>{RetypePassword}</Label>
                    <Input
                      type="password"
                      name="login[password]"
                      required=""
                      placeholder="*********"
                    />
                  </FormGroup> */}
                  <FormGroup>
                    <Btn
                      attrBtn={{
                        color: "primary",
                        className: "w-100 m-t-20",
                        type: "submit",
                      }}
                    >
                      {"Change Password"}
                    </Btn>
                  </FormGroup>
                  <P attrPara={{ className: "text-center mt-4 mb-0" }}>
                    Want to get enrollment?
                    <Link
                      className="ps-2"
                      to={`/login`}
                    >
                      Create Account
                    </Link>
                  </P>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CreatePwd;
