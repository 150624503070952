import React, { Fragment } from "react";
import { Card, CardHeader } from "reactstrap";
import { Personal } from "../../../Constant";
import { H5, H6, P } from "../../../AbstractElements";
import { useContext } from "react";
import studentContext from "../../../Helper/Student";

const PersonalTab = ({ users }) => {
  const { studentDetails } = useContext(studentContext);

  return (
    <Fragment>
      <Card>
        <CardHeader className="d-flex">
          <H5 attrH5={{ className: "mb-0" }}>{Personal}</H5>
          {/* <span className="f-14 pull-right mt-0">{`${users.length} Contacts`}</span> */}
        </CardHeader>
        {/* <CardBody className="p-t-30 p-l-0"> */}
        <div className="d-flex align-items-center justify-content-between  p-l-30 p-r-30">
          <div>
            <H6 attrH6={{ className: "show-color f-w-600" }}>Email</H6>
            <P attrPara={{ className: "show-color" }}>{studentDetails.email}</P>
          </div>
          <div>
            <H6 attrH6={{ className: "show-color f-w-600" }}>Phone</H6>
            <P attrPara={{ className: "show-color detail-left" }}>
              {!studentDetails.contactNo.toString().startsWith("0") ? `0${studentDetails.contactNo}` : studentDetails.contactNo}
            </P>
          </div>
        </div>
        <div className="bottom-border m-b-30" />
        <div className="d-flex align-items-center justify-content-between p-l-30 p-r-30">
          <div>
            <H6 attrH6={{ className: "show-color f-w-600" }}>CNIC No</H6>
            <P attrPara={{ className: "show-color" }}>{studentDetails.cnicNo}</P>
          </div>
          <div>
            <H6 attrH6={{ className: "show-color f-w-600" }}>Gender</H6>
            <P attrPara={{ className: "show-color detail-left text-capitalize" }}>{studentDetails.gender}</P>
          </div>
        </div>
        <div className="bottom-border m-b-30" />
        <div className="d-flex align-items-center justify-content-between p-l-30 p-r-30">
          <div>
            <H6 attrH6={{ className: "show-color f-w-600" }}>
              Enrolled Courses
            </H6>
            <P attrPara={{ className: "show-color" }}>{studentDetails.courses.length}</P>
          </div>
          <div>
            <H6 attrH6={{ className: "show-color f-w-600" }}>Quiz Attempted</H6>
            <P attrPara={{ className: "show-color detail-left" }}>{studentDetails?.result?.length ?? 0}</P>
          </div>
        </div>
        <div className="bottom-border m-b-30" />
        <div className="d-flex align-items-center justify-content-between p-l-30 p-r-30">
          <div>
            <H6 attrH6={{ className: "show-color f-w-600" }}>Student Id</H6>
            <P attrPara={{ className: "show-color" }}>{studentDetails.studentId}</P>
          </div>
          <div>
            <H6 attrH6={{ className: "show-color f-w-600" }}>Qualifiation</H6>
            <P attrPara={{ className: "show-color detail-left" }}>{studentDetails?.qualification}</P>
          </div>
        </div>
        <div className="p-b-30" />
      </Card>
    </Fragment>
  );
};

export default PersonalTab;
