import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import { NewCourseValidation } from "../../Validation";
import CourseForm from "./CourseForm";
import { Row, Container, Col, Card, Form } from "reactstrap";
import Loader from "../Loader";
import { Btn, Progressbar } from "../../AbstractElements";
import Tesseract from "tesseract.js";
import studentContext from "../../Helper/Student";
import courseContext from "../../Helper/Course";
import { toast } from "react-toastify";
import { addCrashErrorToDB, addNotificationToDb, sendMail, uploadImage, userUpdate } from "../../Service/Firebase";
import { calculateSecondModuleFeesDate } from "../../utils/courses";

const NewCourseForm = (props) => {
  const { setCurrentCourse, studentDetails, getAttendance, setNotifications, notifications } =
    useContext(studentContext);
  const { courses } = useContext(courseContext);
  const [progress, setProgress] = useState(0);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const notificationTitle = {
    pending: "Fees Pending",
    verified: "Fees Verified",
    notVerified: "Fees Not Verified",
  };


  const formSubmitHandler = async (values, action) => {
    try {
      const logger = (m) => {
        if (m.progress) {
          const progress = m.progress * 100;
          if (progress !== 100) {
            setProgress(progress);
          }
        } else {
          console.log(m);
          setProgress(0);
        }
      };

      let text = "";
      const { images } = values;
      try {
        const {
          data: { text: extractedText },
        } = await Tesseract.recognize(images[0], "eng", {
          logger,
        });
        text = extractedText;
      } catch (error) {
        toast.error("Something went wrong with Tesseract");
        const extension = images[0].name.split(".").pop();
        const fileSizeInBytes = +images[0].size;
        const fileSizeInMB = fileSizeInBytes / 1048576;
        addCrashErrorToDB(
          "Tesseract",
          error.message,
          {
            imageExtension: extension,
            imageSize: fileSizeInMB.toFixed(2) + "MB",
          },
          studentDetails.email
        );
      }

      try {
        const screenshotUrl = [];
        // await saveImageText({ text, id: studentDetails.id, date: new Date() });
        for (const img of images) {
          const uploadedFeeURL = await uploadImage(img, "feeScreenShots");
          screenshotUrl.push(uploadedFeeURL);
        }

        const feeScreenshots = [
          {
            image: screenshotUrl[0],
            date: new Date(),
            imageText: text,
            status: "pending",
            ...(screenshotUrl[1] && { image2: screenshotUrl[1] }),
          },
        ];
        const newCourse = courses.find((cour) => cour.id === values.courseId);
        const isSecondModuleStart = calculateSecondModuleFeesDate(newCourse.startDate, newCourse.endDate)
        if (isSecondModuleStart) {
          feeScreenshots.unshift({
            skipModule: true,
          });
        }
        const notificationBody = {
          body: {
            courseName: newCourse.name,
            courseType: newCourse.type,
            module: 1
          },
          type: "pending",
          title: notificationTitle["pending"],
          email: studentDetails.email,
        };
        notifications.unshift({
          ...notificationBody,
          read: false,
          createdAt: new Date().toISOString(),
        });

        const coursesData = { courseId: values.courseId, feeScreenshots };
        studentDetails.courses.push(coursesData);
        studentDetails.courseIds.push(values.courseId)
        const data = studentDetails.courses;
        await getAttendance(studentDetails.email, newCourse);
        await userUpdate(studentDetails.email, { courses: data, courseIds: studentDetails.courseIds });
        setNotifications(notifications);
        await addNotificationToDb(notificationBody);
        sendMail(studentDetails.email, studentDetails.fullName);
        setCurrentCourse({
          ...newCourse,
          ...coursesData,
        });
        props.onClose();
        toast.success("Enrolled successfully");
      } catch (error) {
        toast.error("Failed to upload fee screenshot");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const formik = useFormik({
    initialValues: { courseId: "", images: [] },
    validationSchema: NewCourseValidation,
    onSubmit: formSubmitHandler,
  });

  return (
    <Container fluid={true}>
      <Row className="d-flex align-items-center justify-content-center full-height-form">
        <Col md={6} xl={5} xxl={4} sm={12} className="">
          <Card className="card-padding">
            <Form onSubmit={formik.handleSubmit}>
              <CourseForm formik={formik} type={props.type} setIsSubmitDisabled={setIsSubmitDisabled} />
              {progress ? <Progressbar
                attrProgress={{
                  color: "success",
                  className: "m-t-10",
                  value: progress,
                  animated: true,
                }}
              /> : ""}
              <div className="p-t-10 d-flex align-items-center justify-content-between">
                <Btn
                  attrBtn={{
                    color: "secondary",
                    onClick: () => props.onClose(),
                  }}
                >
                  close
                </Btn>
                <Btn
                  attrBtn={{
                    color: "primary",
                    type: "submit",
                    disabled: formik.isSubmitting || isSubmitDisabled,
                  }}
                >
                  {formik.isSubmitting ? <Loader /> : "Submit"}
                </Btn>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NewCourseForm;
