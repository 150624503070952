import React from 'react'
import { H5, P } from '../../AbstractElements'

const Tooltip = ({ text, title }) => {
  return (
    <div>
      <H5>{title}</H5>
      <P>{text}</P>
    </div>
  )
}

export default Tooltip