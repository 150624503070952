import moment from "moment";
import React, { useEffect, useState } from "react";
import { Clock } from "react-feather";

const ClockIcon = ({ curHr, curMi, meridiem }) => {
  const [time, setTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formattedTime = time.format('h:mm');
  return (
    <div className="badge-group">
      <span className="badge badge-light-primary f-12">
        <Clock style={{ width: "12px", height: "12px" }} className="me-1" />
        <span id="txt">
          {formattedTime} {meridiem}
          {/* {curHr}:{curMi < 10 ? "0" + curMi : curMi} {meridiem} */}
        </span>
      </span>
    </div>
  );
};

export default ClockIcon;
