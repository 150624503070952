import React from "react";
import { Suspense, useEffect, useState, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import StudentContext from "../Helper/Student";
import { auth0 } from "../Config/Config";
import { Auth0Provider } from "@auth0/auth0-react";
import { configureFakeBackend } from "../Services/fack.backend";
import Callback from "../Auth/Callback";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { ToastContainer } from "react-toastify";
// require('dotenv').config()

// setup fake backend
configureFakeBackend();
const Routers = () => {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("login")
  );
  const jwt_token = localStorage.getItem("token");
  const { studentDetails, fetchStudentDetails, isAuthenticated } = useContext(StudentContext);
  
  useEffect(() => {
    let abortController = new AbortController();
    // if (!studentDetails) {
    //   console.log("load data");
    //   fetchStudentDetails();
    // }
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    // <Auth0Provider
    //   domain={auth0.domain}
    //   clientId={auth0.clientId}
    //   redirectUri={auth0.redirectUri}
    // >
    <>
      <BrowserRouter basename={"/"}>
        <>
          {/* <Suspense fallback={<Loader />}> */}
          <Routes>
            <Route path={"/"} element={<PrivateRoute />}>
              {isAuthenticated ? (
                <>
                  <Route
                    exact
                    path={``}
                    element={
                      <Navigate
                        to={`/dashboard`}
                      />
                    }
                  />
                  <Route
                    exact
                    path={`/`}
                    element={
                      <Navigate
                        to={`/dashboard`}
                      />
                    }
                  />
                </>
              ) : (
                ""
              )}
              <Route path={`/*`} element={<LayoutRoutes />} />
            </Route>
            <Route
              path={`/callback`}
              render={() => <Callback />}
            />
            {/* {studentDetails ? (
                <>
                  <Route
                    exact
                    path={``}
                    element={
                     <h1>Hello World</h1>
                    }
                  />
                </>
              ) : (
                <> */}

            <Route
              exact
              path={`/login`}
              element={<Signin />}
            />
            {authRoutes.map(({ path, Components }, i) => (
              <Route path={path} element={Components} key={i} />
            ))}
            {/* </>
              )} */}
          </Routes>
          {/* </Suspense> */}
        </>
      </BrowserRouter>
      <ToastContainer />
    </>

    // </Auth0Provider>
  );
};

export default Routers;
