import React, { Fragment, useContext } from 'react'
import { useFormik } from 'formik'
import { bugValidation } from '../../Validation'
import { Label, FormGroup, Input } from 'reactstrap'
import CustomInput from '../../CommonElements/Input'
import Dropzone1 from '../BonusUi/Dropzones/Dropzone1'
import studentContext from '../../Helper/Student'

const BugModal = ({ formik }) => {
  const { studentDetails } = useContext(studentContext)

  return (
    <Fragment>
      <div className='p-t-5 p-b-5 scrollar-form removeScrollbar'>
        <FormGroup className="m-b-20">
          <CustomInput
            name="email"
            value={formik.values.email}
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            disabled={studentDetails ? formik.values.email : false}
            placeholder="Email"
            label="Email"
          />
        </FormGroup>

        <FormGroup className="m-b-20 d-flex flex-column">
          <Label htmlFor='description' className="show-color">Description</Label>
          <Input
            name='description'
            rows={5}
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type='textarea'
            placeholder='Description'
          />
          {formik.errors["description"] && formik.touched["description"] && (
            <span className="error-message">{formik.errors["description"]}</span>
          )}
        </FormGroup>
        <FormGroup>
          <Label className="show-color">Upload bug image</Label>
          <Dropzone1
            imageChangeHandler={(e) => formik.setFieldValue('screenshot', e.target.files[0])}
            images={formik.values.screenshot && URL.createObjectURL(formik.values.screenshot)}
          />
          {formik.errors["screenshot"] && formik.touched["screenshot"] && (
            <span className="error-message">{formik.errors["screenshot"]}</span>
          )}
        </FormGroup>
      </div>
    </Fragment>
  )
}

export default BugModal