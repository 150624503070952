import { Btn, H5, LI, UL } from "../../../AbstractElements";
import { useContext, useState } from "react";
import { Card, CardHeader, Col, Collapse } from "reactstrap";
import courseContext from "../../../Helper/Course";

const CategoriesClass = ({ courseDetails }) => {
  const { generalPaymentDetail } = useContext(courseContext)
  const [isDesign, setIsDesign] = useState(true);

  return (
    <Col xl="12">
      <Card>
        <CardHeader>
          <H5 attrH5={{ className: "mb-0" }}>
            <Btn
              attrBtn={{
                className: "btn-link ps-0",
                onClick: () => setIsDesign(!isDesign),
                color: "transperant",
              }}
            >
              Payment Details
            </Btn>
          </H5>
        </CardHeader>
        <Collapse isOpen={isDesign}>
          <div className="categories">
            <div
              className="show-color p-r-20 p-l-20"
              dangerouslySetInnerHTML={{
                __html: courseDetails.paymentDetail ? courseDetails.paymentDetail : generalPaymentDetail,
              }}
            />
          </div>
        </Collapse>
      </Card>
    </Col>
  );
};

export default CategoriesClass;
