import React from "react";

const Timeline = ({step}) => {
  return (
    <div className="d-flex align-items-center justify-content-center progress-timeline">
      <div className={`timeline ${step >= 0 ? 'timeline-active' : ''} `}>1</div>
      <div className={`line ${step > 0 ? 'line-active' : ''} `} />
      <div className={`timeline ${step >= 1 ? 'timeline-active' : ''} `}>2</div>
      <div className={`line ${step > 1 ? 'line-active' : ''} `} />
      <div className={`timeline ${step == 2 ? 'timeline-active' : ''} `}>3</div>
    </div>
  );
};

export default Timeline;