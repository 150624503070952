import { FeeVerified, MODULE_ONE, MODULE_TWO } from "../Constant";
import moment from 'moment'
import { getNumberSuffix } from "./text";

export const getCoursesDetails = (studentDetails, allCourses) => {
  const studentCourses = [];
  const featuredCourses = [];
  allCourses.forEach((course) => {
    const matchingCourse = studentDetails.courses.find(
      (c) => c.courseId === course.id
    );
    const courseDetail = { ...course, ...matchingCourse };
    if (matchingCourse) {
      studentCourses.push({ ...courseDetail, isEnrolled: true });
    } else if (!matchingCourse && course.admissionOpen) {
      featuredCourses.push(courseDetail);
    }
  });
  return { studentCourses, featuredCourses };
};

export const getSingleCourseDetails = (
  student,
  allCourses,
  courseId,
  allStudents
) => {
  let courseData;
  // student.courses.forEach((course) => {
  const matchingCourse = allCourses.find((c) => c.id === courseId);
  let totalCourseClasses = 0;
  if (matchingCourse) {
    let currentDate = new Date(matchingCourse.startDate);
    const endDate = new Date(matchingCourse.endDate);
    const days = matchingCourse.days;
    const classDays = days.toUpperCase().split(/[ &]+/);
    while (currentDate <= endDate) {
      if (
        classDays.includes(
          currentDate
            .toLocaleString("en-US", { weekday: "short" })
            .toUpperCase()
        )
      ) {
        totalCourseClasses++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    const totalDays = days.split(" & ");
    const isStudentCourse = student.courses.find((co) => co.courseId === matchingCourse.id)
    courseData = {
      ...isStudentCourse,
      ...matchingCourse,
      isCurrent: matchingCourse.isCurrent,
      totalCourseClasses,
      isEnrolled: isStudentCourse ? true : false,
      totalStudents: countStudentsInCourse(allStudents, matchingCourse.id),
      totalDays: totalDays.length,
    };
    // Add the matching course to the studentCourses array
    totalCourseClasses = 0;
  }
  // });
  return courseData;
};

export const countStudentsInCourse = (students, courseId) => {
  let count = 0;
  students.forEach((student) => {
    if (!student.courses) return;
    student.courses.forEach((course) => {
      if (
        course.courseId === courseId &&
        course?.feeScreenshots[0]?.status === FeeVerified
      ) {
        count++;
      }
    });
  });
  return count;
};

export const getTotalUpcomingClassess = (selectedCourse) => {
  // studentCources.forEach((selectedCourse) => {
  let endDate = new Date(selectedCourse.endDate);
  let currentDate = new Date(selectedCourse.startDate);
  const classDays = selectedCourse.days.toUpperCase().split(/[ &]+/);

  const upComingClasses = [];
  while (currentDate <= endDate) {
    // check if the current date is a Saturday or Sunday
    if (
      classDays.includes(
        currentDate.toLocaleString("en-us", { weekday: "short" }).toUpperCase()
      )
    ) {
      const date = new Date(currentDate);
      const dayOfMonth = date.getDate();
      const month = date.toLocaleString("en-us", { month: "long" });
      const suffix = getNumberSuffix(dayOfMonth);
      const upcomingClassDate = `${dayOfMonth}${suffix} ${month.slice(
        0,
        3
      )} ${currentDate.toLocaleString("en-us", { weekday: "short" })} ${date.getFullYear()}`;
      const todayDate = new Date();

      if (date > todayDate) {
        upComingClasses.push(upcomingClassDate);
      }
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  let hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const amPm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  const time = `${hours}:${minutes.toString().padStart(2, "0")} ${amPm}`;
  const timeDiff = Math.abs(currentDate - endDate);

  // Convert milliseconds to hours
  const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
  selectedCourse.classStartTime = time;
  selectedCourse.totalHoursClass = 24 - hoursDiff;
  return upComingClasses;
};


export const calculateDate = (startDate, endDate) => {
  if (!startDate || !endDate) return false;

  const start = moment(startDate);
  const end = moment(endDate);
  const duration = moment.duration(end.diff(start));
  const midpoint = start.clone().add(duration.asMilliseconds() / 2, 'milliseconds');
  return new Date() > midpoint.toDate();
};

export const handleByMonthScreenShot = (currentCourse) => {
  if (calculateDate(currentCourse?.startDate, currentCourse?.endDate)) {
    return currentCourse?.feeScreenshots.length === 2;
  } else {
    return true;
  }
};

export const getCourseId = (courses, courseName, courseType) => {
  return courses.find((cour) => cour.name === courseName && cour.type == courseType)
}

export const calculateSecondModuleFeesDate = (startDate, endDate) => {

  if (!startDate || !endDate) return false;
  const midpoint = getSecondModuleStartDate(startDate, endDate);
  const midpointsAfter10Days = moment(midpoint.toDate()).subtract(10, "days");
  return new Date() > midpointsAfter10Days.toDate();
};

export const getSecondModuleStartDate = (startDate, endDate) => {
  const start = moment(startDate);
  const end = moment(endDate);
  const duration = moment.duration(end.diff(start));
  const midpoint = start.clone().add(duration.asMilliseconds() / 2, 'milliseconds');
  return midpoint
}

export const getCurrentModule = (startDate, endDate, screenshot) => {
  if (!startDate || !endDate) return
  const start = new Date(startDate);
  const end = new Date(endDate);
  const currentDate = new Date();

  // Calculate the midpoint date
  const midpoint = new Date((start.getTime() + end.getTime()) / 2);
  if (currentDate < midpoint) {
    if (screenshot?.length === 0) {
      return MODULE_ONE;
    }
  } else {
    if (screenshot?.length === 2) {
      return ''
    } else if (calculateSecondModuleFeesDate(startDate, endDate)) {
      return MODULE_TWO
    }
  }
}

export const matchTwoDates = (date1, date2) => {
  if (!date1 || !date2) return
  const dateOne = new Date(date1)
  const dateTwo = new Date(date2)
  if (dateOne > dateTwo) {
    return true
  }
  return false
}

export const extractedCourseName = (name) => {
  const match = name.match(/EX-MS-\d+/);
  return match ? match[0] : null;
}