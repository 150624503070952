import { Card, CardBody, Col, Table } from "reactstrap";
import AppoimentCardHeader from "./AppoimentCardHeader";
import AppoimentCardTableBody from "./AppoimentCardTableBody";
import { useContext } from "react";
import studentContext from "../../../../Helper/Student";

const AppoimentCard = () => {

  const { calenderData } = useContext(studentContext)

  return (
    <Col sm='12' md='3' className="d-flex">
      {/* <Col xl="3" md="6" className="box-col-40 xl-40"> */}
      <Card className="appointment-detail w-100">
        <AppoimentCardHeader />
        <CardBody>
          <div className="table-responsive theme-scrollbar">
            <Table>
              <AppoimentCardTableBody data={calenderData.length ? [...calenderData].splice(0, 5) : []} />
            </Table>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AppoimentCard;
