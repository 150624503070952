import React, { useState, useContext } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import { Col, Container, Row } from "reactstrap";
import Greetingcard from "../Dashboard/Default/GreetingCard";
import PersonalTab from "../Contact/ContactApp/PersonalTab";
import CommonModal from "../UiKits/Modals/common/modal";
import OnBoarding from "../Pages/Auth/OnBoarding";
import { EDIT_PROFILE } from "../../Constant";
import studentContext from "../../Helper/Student";
import { isWriteAccess } from "../../utils/errorValidationNavigate";
import { toast } from "react-toastify";

const Profile = () => {
  const { loginAdmin } = useContext(studentContext);
  const [openEditModal, setOpenEditModal] = useState(false);

  const editProfile = () => {
    const viewAsStudent = localStorage.getItem("loginAsUser");
    if (
      !viewAsStudent ||
      loginAdmin?.isSuperAdmin ||
      (isWriteAccess(loginAdmin) && viewAsStudent)
    ) {
      setOpenEditModal(true);
    } else {
      toast.error(
        "You don’t have access, please ask Super Admin to provide you access"
      );
    }
  };

  return (
    <div>
      <Breadcrumbs mainTitle="Profile" parent="Dashboard" />
      <Container fluid={true} className="dashboard-default">
        <Row>
          <Greetingcard onClickHandler={editProfile} />
          <Col>
            <PersonalTab />
          </Col>
          {openEditModal && (
            <CommonModal
              title="Edit Your Personal Details"
              isOpen={true}
              footerClass="d-none"
              toggler={() => setOpenEditModal(false)}
            >
              <OnBoarding
                toggler={() => setOpenEditModal(false)}
                type={EDIT_PROFILE}
              />
            </CommonModal>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Profile;
