import React from "react";
import { Spinner } from "../../AbstractElements";

const Loader = () => {
  return (
    <div className="loader-box">
      <Spinner attrSpinner={{ className: "loader-1" }} />
    </div>
  );
};

export default Loader;
