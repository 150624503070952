import React from "react";
import DetailCourseContain from "../Learning/DetailCourse";

const CourseDetails = () => {
  return (
    <div>
      <DetailCourseContain />
    </div>
  );
};

export default CourseDetails;
