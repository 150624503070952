import { useState, useContext } from "react";
import { File, LogIn, Settings, User } from "react-feather";
import firebase from "firebase";
import { Link, useNavigate } from "react-router-dom";
import { LI, UL } from "../../../AbstractElements";
import { Account, EDIT_PROFILE, LogOut } from "../../../Constant";
import studentContext from "../../../Helper/Student";
import OnBoarding from "../../../Components/Pages/Auth/OnBoarding";
import CommonModal from "../../../Components/UiKits/Modals/common/modal";
import { isWriteAccess } from "../../../utils/errorValidationNavigate";
import { toast } from "react-toastify";

const Users = () => {
  const [toggle, setToogle] = useState(true);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalSaveClicked, setModalSaveClicked] = useState(false);
  const { setStudentDetails, loginUser, setLoginAdmin } =
    useContext(studentContext);
  const history = useNavigate();

  const Logout = async () => {
    const isViewStudentprofile = localStorage.getItem("loginAsUser");
    await firebase.auth().signOut();
    setStudentDetails(null);
    localStorage.removeItem('mix_background_layout')
    document.body.classList.remove("dark-only");
    history(`/login`);
    if (isViewStudentprofile) {
      localStorage.removeItem("loginAsUser");
      setLoginAdmin(null);
    }
  };

  const Active = () => setToogle(!toggle);

  const editProfile = () => {
    const viewAsStudent = localStorage.getItem("loginAsUser");
    if (
      !viewAsStudent ||
      loginUser?.isSuperAdmin ||
      (isWriteAccess(loginUser) && viewAsStudent)
    ) {
      setOpenEditModal(true);
    } else {
      toast.error(
        "You don’t have access, please ask Super Admin to provide you access"
      );
    }
  };

  return (
    <LI attrLI={{ className: `profile-nav onhover-dropdown` }}>
      <div className="account-user">
        <User onClick={Active} />
      </div>
      <UL attrUL={{ className: "profile-dropdown onhover-show-div" }}>
        <LI>
          <Link to={"/profile"}>
            <User />
            <span>My Profile</span>
          </Link>
        </LI>
        <LI>
          <Link to={"/policy"}>
            <File />
            <span>Policy</span>
          </Link>
        </LI>
        {/* <LI attrLI={{ onClick: editProfile }}>
          <Link to={"#"}>
            <i>
              <Settings />
            </i>
            <span>Edit Profile</span>
          </Link>
        </LI> */}
        <LI attrLI={{ onClick: Logout }}>
          <Link to={`#`}>
            <LogIn />
            <span>{LogOut}</span>
          </Link>
        </LI>
      </UL>
      {openEditModal && (
        <CommonModal
          isOpen={true}
          footerClass="d-none"
          toggler={() => setOpenEditModal(false)}
          // bodyClass="p-t-0 p-b-0"
        >
          <OnBoarding
            toggler={() => setOpenEditModal(false)}
            type={EDIT_PROFILE}
            modalSaveClicked={modalSaveClicked}
          />
        </CommonModal>
      )}
    </LI>
  );
};

export default Users;
