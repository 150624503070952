import React, { useContext, useEffect, useState } from "react";
import { Btn, P } from "../../../AbstractElements";
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "../../../CommonElements/Input";
import { createStudentCollection, forgetpswrd, login } from "../../../Service/Firebase";
import { emailSchema, passwordSchema } from "../../../Validation";
import { useFormik } from "formik";
import { Form } from "reactstrap";
import { toast } from "react-toastify";
import Loader from "../../../Common/Loader";
import LayoutLoader from '../../../Layout/Loader';
import studentContext from "../../../Helper/Student";
import Footer from "../../../Layout/Footer";

const LoginTab = () => {
  const [showPasswordField, setShowPasswordField] = useState(false);
  const history = useNavigate();
  const userEmail = window !== undefined && localStorage.getItem("onBoardingEmail");
  const { setLoading, studentDetails, isLoading } = useContext(studentContext)

  const formSubmitHandler = async (values, { setSubmitting }) => {
    try {
      if (showPasswordField) {
        await login(values.email, values.password);
        if (userEmail) {
          localStorage.removeItem("onBoardingEmail")
          localStorage.removeItem("onBoardFormNo")
          localStorage.removeItem("onBoardData")
        }
        // fetchStudentDetails()
        setLoading(true)
        toast.success("Login sucessfully");
        history('/dashboard')
      } else {
        const data = await createStudentCollection(values.email.toLowerCase());
        if (data?.studentId || data?.isAdmin) {
          setShowPasswordField(true);
        } else if (data) {
          if (userEmail) {
            localStorage.removeItem("onBoardingEmail")
            localStorage.removeItem("onBoardFormNo")
            localStorage.removeItem("onBoardData")

          }
          localStorage.setItem("onBoardingEmail", values.email.toLowerCase());
          history("/onboarding");
        }
      }
    } catch (error) {
      setSubmitting(false);
      toast.error("Please enter valid credentials");
    }
  };

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema: showPasswordField ? passwordSchema : emailSchema,
    onSubmit: formSubmitHandler,
  });
  useEffect(() => {
    if (studentDetails) history("/dashboard")
    // if (userEmail) history("/onboarding")
  }, [studentDetails])

  const sendForgotPasswordEmail = async () => {
    try {
      const paramsEmail = window.location.search.split("email=")[1];
      await forgetpswrd(formik.values.email.trim());
      toast.success("Email send successfully");
    } catch (error) {
      console.log("error", error)
      toast.error(error.message);
    }
  };

  if (isLoading) {
    return <LayoutLoader />
  }

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        {showPasswordField ? (
          <CustomInput
            name="password"
            type="password"
            value={formik.values.password}
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            isPasswordField={true}
            label="Password"
          />
        ) : (
          <CustomInput
            name="email"
            value={formik.values.email}
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label="Email Address"
          />
        )}
        <Btn
          attrBtn={{
            color: "primary",
            className: "d-block w-100 m-t-30",
            type: "submit",
          }}
        >
          {formik.isSubmitting ? <Loader /> : showPasswordField ? "Login" : "Continue"}
        </Btn>
        {showPasswordField && <Link to="#">
          <P attrPara={{ className: "font-primary text-end m-t-10", onClick: sendForgotPasswordEmail }}>
            Forgot Password?
          </P>
        </Link>}
      </Form>
      <div className="bug-modal-wrapper">
        <Footer />
      </div>
    </>
  );
};

export default LoginTab;
