import { LI, P, UL } from "../../../../AbstractElements";
import { Presnt, Absent, Leave } from "../../../../Constant";
const status = {
  0: Presnt,
  1: Absent,
  2: Leave,
};
const DeviceList = ({ attendaceData }) => {
  return (
    <UL attrUL={{ className: "simple-list d-flex" }}>
      {attendaceData.map((attendance, index) => (
        <LI key={index}>
          <div className={`${status[index].toLowerCase()}-color attendance-notation`} />
          <P attrPara={{ className: "f-w-600 font-primary f-12" }}>
            {status[index]}
          </P>
          <span className="f-w-700">{attendance}</span>
        </LI>
      ))}
    </UL>
  );
};

export default DeviceList;
