import React, { Fragment } from "react";
import { FormGroup } from "reactstrap";
import { H4, P } from "../../AbstractElements";
import CustomInput from "../../CommonElements/Input";
import PasswordStrengthBar from 'react-password-strength-bar';
import { useLocation } from "react-router-dom";

const PasswordForm = ({ formik, showHeading, formClasses, quiz }) => {
  const { pathname } = useLocation();
  const isOnboarding = pathname === '/onboarding'
  const isQuizzes = pathname === '/quizzes'
  return (
    <Fragment>
      {showHeading && <H4 attrH4={{ className: "show-color m-b-25 text-center" }}>
        Enter your password
      </H4>}
      <div className={`course-form ${formClasses}`}>
        {isQuizzes &&
          <div className="m-b-20">
            <P attrPara={{className: 'f-w-600 quiz-title show-color'}}><span>Quiz Name:</span> <span className="quiz-value">{quiz.quiz.name}</span></P>
            <P attrPara={{className: 'f-w-600 quiz-title show-color'}}><span>Category Name:</span> <span className="quiz-value">{quiz.quiz.category}</span></P>
            <P attrPara={{className: 'f-w-600 quiz-title show-color'}}><span>Total Questions:</span> <span className="quiz-value">{quiz.quiz.questions.length}</span></P>
          </div>
        }
        <FormGroup className="m-b-20">
          <CustomInput
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            formik={formik}
            onBlur={formik.handleBlur}
            isPasswordField={true}
            placeholder="Password"
            label="Password"
          />
        </FormGroup>
        {isOnboarding &&
          <>
            <div className="">
              <PasswordStrengthBar password={formik.values.password} />
            </div>
            <FormGroup className="m-b-20">
              <CustomInput
                name="cPassword"
                value={formik.values.cPassword}
                onChange={formik.handleChange}
                formik={formik}
                onBlur={formik.handleBlur}
                isPasswordField={true}
                placeholder="Confirm Password"
                label="Confirm Password"
              />
            </FormGroup>
          </>
        }
      </div>
    </Fragment>
  );
};

export default PasswordForm;
