import { useContext } from "react";
import { Row, Col } from "reactstrap";
import FindCourseClass from "./FindCourse";
import CategoriesClass from "./Categories";
import UpcomingClass from "./UpcomingClass";
import LearningContext from "../../../Helper/Leatning";

const LearningFilter = ({ courseDetails }) => {
  const { isOpen, setIsOpen } = useContext(LearningContext);

  return (
    <Col xl="5" className="xl-40 box-col-40">
      <div className="job-sidebar md-sidebar">
        <div
          className={`md-sidebar-aside job-left-aside custom-scrollbar ${
            isOpen ? "open" : ""
          }`}
        >
          <div
            className="default-according style-1 job-accordion"
            id="accordionoc"
          >
            <Row>
              {courseDetails.isEnrolled ? (
                <UpcomingClass courseDetails={courseDetails} />
              ) : (
                <CategoriesClass courseDetails={courseDetails} />
              )}
            </Row>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default LearningFilter;
