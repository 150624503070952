import React, { useState } from "react";
import { Label } from "reactstrap";
import { Eye, EyeOff } from 'react-feather'

const CustomInput = (props) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const { label, name, formik, isPasswordField, type, ...inputProps } = props;
  return (
    <>
      <>
        <Label className="show-color">{label}</Label>
        <div className={isPasswordField ? "password-field form-control" : "" + "position-relative"}>
          <input
            className="form-control"
            {...inputProps}
            name={name}
            type={
              isPasswordField ? (!togglePassword ? "password" : "text") : type
            }
          />
          {isPasswordField && (
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              {togglePassword ? <EyeOff /> : <Eye />}
              {/* <span className={togglePassword ? "" : "show"}></span> */}
            </div>
          )}
        </div>
        {formik?.touched[name] && formik?.errors[name] && <span className="error-message">{formik?.errors[name]}</span>}
      </>
    </>
  );
};

export default CustomInput;
