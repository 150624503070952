import React from 'react'
import { useNavigate } from 'react-router-dom';
import CreatePwd from '../Pages/Auth/CreatePwd';
import VerifyEmail from '../VerifyEmail';

const EmailHandler = () => {

  const router = useNavigate()
  let mode;
  let code;
  // Extract the action mode from the URL
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    mode = urlParams.get("mode");
    code = urlParams.get("oobCode");
    if (!mode && !code) {
      router("/");
    }
  }
  const renderComponentByType = () => {
    switch (mode) {
      case "resetPassword":
        return <CreatePwd />;
      case "verifyEmail":
        return <VerifyEmail />
      default:
        return null;
    }
  };

  return renderComponentByType();
}

export default EmailHandler