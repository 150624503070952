import React, { useEffect, useState } from "react";
import P from "../../CommonElements/Paragraph";
import Btn from "../../CommonElements/Button";
import Confetti from "react-confetti";
import H3 from "../../CommonElements/Headings/H3Element";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import { dynamicImage } from "../../Services";

const Result = () => {
  const [dimension, setDimension] = useState({
    height: "",
    width: "",
  });
  const location = useLocation();
  const quizData = location.state;
  const navigate = useNavigate();

  const updateDimensions = (e) => {
    setDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    if (!quizData) {
      navigate("/quizzes");
    }
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);
  if(!quizData) return null
  return (
    <Container fluid={true}>
      {quizData.marks > 60 && (
        <Confetti
          width={dimension.width ? dimension.width : window.innerWidth}
          height={dimension.height ? dimension.height : window.innerHeight}
          colors={["#A864FD", "#29CDFF", "#78FF44", "#FF718D", "#FDFF6A"]}
        />
      )}
      <Row className="justify-content-center">
        <Col lg={4}>
          <Card className="p-4">
            <div className="result-image-wrapper">
              <img
                src={dynamicImage(
                  quizData.marks > 60 ? "pass.webp" : "failed.gif"
                )}
              />
              {/* <img src={dynamicImage("pass.webp")} /> */}
            </div>
            <div className="result-text">
              <H3
                attrH3={{
                  className: "show-color  m-t-10",
                }}
              >
                {quizData.name}
                {/* {"Quiz for Interns"} */}
              </H3>
              <div className="percentage">
                <P
                  attrPara={{
                    className: "show-color f-w-500 m-t-10",
                  }}
                >
                  Your result is : {quizData.marks.toFixed(0)}%
                  {/* Your result is : {Math.round(this.state.percentage) + "%"} */}
                </P>
              </div>
              {quizData.marks < 60 ? (
                <P attrPara={{ className: "f-w-600 danger-color" }}>
                  Sorry, You Are Failed
                </P>
              ) : (
                <P attrPara={{ className: "f-w-600 success-color" }}>
                  Congrats, You Are Pass
                </P>
              )}
            </div>

            <Btn
              attrBtn={{
                color: "primary",
                onClick: () => navigate("/quizzes"),
              }}
            >
              Back to Home
            </Btn>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Result;
