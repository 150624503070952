import React, { Fragment, useState, useRef } from 'react';
import { Container, Card, CardBody, CardHeader } from 'reactstrap';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { ImageCropper } from '../../../Constant';
import { H4, Breadcrumbs, P } from '../../../AbstractElements';
import { useDebounceEffect } from './useDebounceEffect';
import { canvasPreview } from './canvasPreview';
import { getImageDimensions } from '../../../utils/image';

function centerAspectCrop(
  mediaWidth, mediaHeight, aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 30,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}
const ImagecropperContain = ({ url, imageCanvasRef }) => {
  const previewCanvasRef = imageCanvasRef
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [random, setRandom] = useState(0)
  const scale = 1;
  const rotate = 0;
  const aspect = 1 / 1;

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  const imageDimenshion = (canvas) => {
    if (!canvas) return
    const dataURL = canvas.toDataURL(); // This will give you a Base64 encoded string
    const data = getImageDimensions(dataURL)
    return <>
      <P><b>Height:</b> {data.height}</P>
      <P><b>Width:</b> {data.width}</P>
    </>
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )
  return (
    <>
      {Boolean(url) && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
          className='m-t-0'
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={url}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      <div>
        {Boolean(completedCrop) && (
          <>
            <canvas
              ref={previewCanvasRef}
              style={{
                objectFit: 'contain',
                width: '100%',
                height: completedCrop.height,
              }}
            />
            {/* <P><b>Height:</b> {completedCrop.height.toFixed(0)}px</P>
            <P><b>Width:</b> {completedCrop.width.toFixed(0)}</P> */}
          </>
        )}
      </div>
    </>
  );
};
export default ImagecropperContain;