import React from "react";
import Routers from "./Route";
import CustomizerProvider from "./Helper/Customizer/CustomizerProvider";
import ProjectProvider from "./Helper/Project/ProjectProvider";
import FilterProvider from "./Helper/filter/FilterProvider";
import CartProvider from "./Helper/cart/CartProvider";
import ProductProvider from "./Helper/product/ProductProvider";
import WishListProvider from "./Helper/wishlist/WishProvider";
import EmailProvider from "./Helper/email/EmailProvider";
import ChatProvider from "./Helper/chat-app/ChatProvider";
import BookmarkProvider from "./Helper/bookmark/BookmarkProvider";
import ContactProvider from "./Helper/Contact/ContactProvider";
import TaskProvider from "./Helper/task-app/TaskProvider";
import TodoProvider from "./Helper/Todo/TodoProvider";
import TableProvider from "./Helper/Table/TableProvider";
import Leatning from "./Helper/Leatning/LearningProvider";
import FAQProvider from "./Helper/Faq/FaqProvider";
import JobSearchProvider from "./Helper/JobSearch/JobSearchProvider";
import GalleryProvider from "./Helper/Gallery/GalleryProvider";
import GoogleChartProvider from "./Helper/GoogleChart/GoogleChartProvider";
import ChartistProvider from "./Helper/Chartist/ChartistProvider";
import AnimationThemeProvider from "./Helper/AnimationTheme/AnimationThemeProvider";
import CourseProvider from "./Helper/Course/CourseProvider";
import StudentProvider from "./Helper/Student/StudentProvider";
import { TourProvider, useTour } from "@reactour/tour/dist";
import { guidedTourSteps } from "./Data";
import { NextBtn, PreviousBtn } from "./Components/GuidedTour/TooltipButton";

const App = () => {
  // const { setIsOpen } = useTour()

  return (
    <div className="App">
      <CustomizerProvider>
        <ProductProvider>
          <WishListProvider>
            <FilterProvider>
              <CartProvider>
                <ProjectProvider>
                  <ContactProvider>
                    <EmailProvider>
                      <ChatProvider>
                        <TaskProvider>
                          <TodoProvider>
                            <TableProvider>
                              <Leatning>
                                <FAQProvider>
                                  <JobSearchProvider>
                                    <GalleryProvider>
                                      <GoogleChartProvider>
                                        <ChartistProvider>
                                          <BookmarkProvider>
                                            <CourseProvider>
                                              <AnimationThemeProvider>
                                                <StudentProvider>
                                                  <TourProvider
                                                    scrollSmooth
                                                    onClickClose={({ setIsOpen }) => {
                                                      setIsOpen(false)
                                                      localStorage.setItem('tooltipView', true)
                                                    }}
                                                    steps={guidedTourSteps}
                                                    styles={{
                                                      popover: (base) => ({
                                                        ...base,
                                                        '--reactour-accent': '#5c61f2',
                                                      }),
                                                    }}
                                                    prevButton={({ currentStep, setCurrentStep }) =>
                                                      <PreviousBtn  {...{
                                                        currentStep,
                                                        setCurrentStep
                                                      }}
                                                      />
                                                    }
                                                    nextButton={({ currentStep,
                                                      stepsLength,
                                                      setIsOpen,
                                                      setCurrentStep,
                                                      steps }) => <NextBtn
                                                        {...{
                                                          currentStep,
                                                          stepsLength,
                                                          setIsOpen,
                                                          setCurrentStep,
                                                          steps
                                                        }}
                                                      />}
                                                  >
                                                    <Routers />
                                                  </TourProvider>
                                                </StudentProvider>
                                              </AnimationThemeProvider>
                                            </CourseProvider>
                                          </BookmarkProvider>
                                        </ChartistProvider>
                                      </GoogleChartProvider>
                                    </GalleryProvider>
                                  </JobSearchProvider>
                                </FAQProvider>
                              </Leatning>
                            </TableProvider>
                          </TodoProvider>
                        </TaskProvider>
                      </ChatProvider>
                    </EmailProvider>
                  </ContactProvider>
                </ProjectProvider>
              </CartProvider>
            </FilterProvider>
          </WishListProvider>
        </ProductProvider>
      </CustomizerProvider>
    </div>
  );
};

export default App;
