
const getFirebaseConfig = () => {

  let config;
  if (process.env.REACT_APP_ENVIROMENT === 'DEV') {
    return config = {
      apiKey: "AIzaSyAaa5xlkL34IIYPJSnRAA8FcDAOYS-BUrc",
      authDomain: "eu-portal---dev.firebaseapp.com",
      projectId: "eu-portal---dev",
      storageBucket: "eu-portal---dev.appspot.com",
      messagingSenderId: "383459465430",
      appId: "1:383459465430:web:da858bfc646c1e304e9177",
      measurementId: "G-S7ZNL9J5NK"
    }
  } else {
    return config = {
      apiKey: "AIzaSyAH__w-0fB2Upn4Gbc70L9IUJ2g9wUwVtQ",
      authDomain: "expertizoquiz.firebaseapp.com",
      databaseURL: "https://expertizoquiz.firebaseio.com",
      projectId: "expertizoquiz",
      storageBucket: "expertizoquiz.appspot.com",
      messagingSenderId: "378786426812",
      appId: "1:378786426812:web:4511b711f8960358f605b9",
    }
  }
}

export default getFirebaseConfig