import React, { useContext, useState } from "react";
import { Btn, P, Progressbar, ToolTip } from "../../AbstractElements";
import studentContext from "../../Helper/Student";
import { shuffleArray } from "../../utils/quiz";
import CommonModal from "../UiKits/Modals/common/modal";
import { Form } from "reactstrap";
import { useFormik } from "formik";
import { passwordSchema } from "../../Validation";
import PasswordForm from "../../Common/Forms/PasswordForm";
import useSweetAlert from "../../Hooks/useSweetAlert";
import { isWriteAccess } from '../../utils/errorValidationNavigate'
import { FeeVerified, QUIZ_MODAL } from "../../Constant";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const QuizByCategory = ({ quiz, categoryName }) => {
  const { showSweetAlert } = useSweetAlert();
  const [tooltip, setTooltip] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState(null)

  const { studentDetails, currentCourse, loginUser } = useContext(studentContext);
  const isQuizAttmpted = studentDetails.result ? studentDetails.result.find((res) =>
    res.name == quiz.name && res.category == categoryName ? quiz : ""
  ) : null
  const isReviewOpen = quiz.name === isQuizAttmpted?.name && quiz.showReview;
  const navigate = useNavigate();

  const toggle = () => setTooltip(!tooltip);

  const modalTogle = () => setModal(!modal);

  const quizStartHandler = (selectedQuiz) => {
    const viewAsStudent = localStorage.getItem("loginAsUser");

    if (
      !viewAsStudent ||
      loginUser?.isSuperAdmin ||
      (isWriteAccess(loginUser) && viewAsStudent)
    ) {
      selectedQuiz.quiz.category = selectedQuiz.categoryName;
      const questions = selectedQuiz.quiz.questions;
      shuffleArray(questions);
      setModal(true);
      setSelectedQuiz(selectedQuiz)
    } else {
      toast.error(
        "You don’t have access, please ask Super Admin to provide you access"
      );
    }
  };

  const quizReviewHanlder = (selectedQuiz) => {
    localStorage.setItem("quiz", JSON.stringify(selectedQuiz.progress));
    localStorage.setItem("isQuizUnderway", true);
    localStorage.setItem("isReview", true);
    navigate(`/quizzes/${selectedQuiz.name.replace(/\s/g, "")}`);
  };

  const QuizButton = (quiz, categoryName) => {
    return isQuizAttmpted ? (
      isReviewOpen ? (
        <Btn
          attrBtn={{
            color: "primary",
            className: "rounded-1",
            onClick: () => quizReviewHanlder(isQuizAttmpted),
          }}
        >
          Review
        </Btn>
      ) : (
        <Progressbar
          attrProgress={{
            color: "primary",
            value: isQuizAttmpted?.marks?.toFixed(0),
            className: "sm-progress-bar",
          }}
        />
      )
    ) : (
      <Btn
        attrBtn={{
          color: "primary",
          className: "rounded-1",
          onClick: () => quizStartHandler(quiz, categoryName),
        }}
      >
        Start
      </Btn>
    );
  };

  const formSubmitHandler = (val) => {
    let unVerifiedFee;
    let CourseIndex;
    currentCourse.feeScreenshots.forEach((screen, index) => {
      if(screen.skipModule) return
      if (!unVerifiedFee) {
        unVerifiedFee = screen.status !== FeeVerified;
        CourseIndex = index;
      }
    });
    if (unVerifiedFee) {
      const message = `Your fee verification for ${currentCourse.name
        } course on this date ${moment(
          currentCourse.feeScreenshots[CourseIndex].date.seconds * 1000
        ).format("LL")} has not been verified yet`;
      showSweetAlert("Sorry", message, "", "error", "", false, modalTogle);
    } else {
      if (quiz.password !== val.password) {
        toast.error("Invalid Password");
      } else {
        quiz.category = localStorage.setItem("quiz", JSON.stringify(quiz));
        localStorage.setItem("isQuizUnderway", true);
        navigate(`/quizzes/${quiz.name.replace(/\s/g, "")}`);
      }
    }
  };
  const formik = useFormik({
    initialValues: { password: "" },
    validationSchema: passwordSchema,
    onSubmit: formSubmitHandler,
  });

  return (
    <div className="b-r-10 quiz-option" id={`tooltip-${quiz.id}`}>
      <div className="d-flex align-items-center cursor-pointer justify-content-between">
        <P attrPara={{ className: "show-color m-b-0 " }}>{quiz.name}</P>
        {<QuizButton quiz={quiz} categoryName={categoryName} />}
      </div>
      <ToolTip
        attrToolTip={{
          placement: "bottom",
          isOpen: tooltip,
          target: `tooltip-${quiz.id}`,
          toggle: toggle,
        }}
      >
        {isQuizAttmpted ? "" : `Duration: ${quiz.Duration} Mins || `}
        Total Questions: {quiz.questions ? quiz.questions.length : 0} || Passing
        Marks: 60%
        {isQuizAttmpted
          ? " Marks Taken " + Math.round(isQuizAttmpted.marks) + "%"
          : ""}
      </ToolTip>
      {modal && (
        <CommonModal
          isOpen={modal}
          confirmHandler={formik.handleSubmit}
          confirmButtonText={"Submit"}
          confirmBtnType="submit"
          title={"Please enter quiz password"}
          toggler={modalTogle}
          modalClass='password-modal'
          footerClass='justify-content-center'
        >
          <Form onSubmit={formik.handleSubmit}>
            <PasswordForm
              formik={formik}
              quiz={selectedQuiz}
              formClasses="form-auto-height"
            />
          </Form>
        </CommonModal>
      )}
    </div>
  );
};

export default QuizByCategory;
