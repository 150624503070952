import React, { Component } from "react";
import firebase from "firebase";
import { Card, Col, Container, Row } from "reactstrap";
import ProgressBar from "../../Common/Component/ProgressBar";
import QuestionProgress from "./QuestionProgress";
import { Btn, P } from "../../AbstractElements";
import StudentContext from "../../Helper/Student";
import { Navigate, useNavigate } from "react-router-dom";
import { userUpdate } from "../../Service/Firebase";
import QuizTimer from "./QuizTimer";
import Loader from "../../Common/Loader";

class StartQuiz extends Component {
  static navigationOptions = {
    header: null,
  };

  static contextType = StudentContext;
  constructor(props) {
    super(props);
    this.state = {
      time: "",
      quiz: "",
      name: "",
      quizData: [],
      currentIndex: [],
      correctAns: 0,
      dateNow: Date.now(),
      btnLoading: false,
      pageNavigate: false,
      pageState: "",
      pageRoute: "",
      index: 0,
      disabledNext: false,
      seletedAnswers: "",
      seletedAnswersList: [],
      isDisabled: true,
      disabledPrev: false,
      // percent1: ''
      quizCompleted: false
    };
    this.submitQuestion = this.submitQuestion.bind(this);
  }

  componentDidMount = async () => {
    let quiz = await JSON.parse(localStorage.getItem("quiz"));
    let seletedAnswersList = JSON.parse(
      localStorage.getItem("seletedAnswersList")
    );
    this.setState({ quiz });
    await this.checkForAuth();
    document.addEventListener("contextmenu", this.disableContextMenu);
    document.addEventListener("keydown", this.disableF12);
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  };

  componentWillUnmount() {
    document.removeEventListener("contextmenu", this.disableContextMenu);
    document.removeEventListener("keydown", this.disableF12);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  checkForAuth = () => {
    if (!localStorage.getItem("quiz")) {
      this.setState({
        pageNavigate: true,
        // pageState: result,
        pageRoute: "/quizzes",
      });
    } else {
      const localTime = localStorage.getItem(
        "localTime" + this.state.quiz.name
      );
      this.setState({
        quiz: JSON.parse(localStorage.getItem("quiz")),
        time:
          Date.now() +
          parseInt(
            parseInt(JSON.parse(localStorage.getItem("quiz")).Duration, 10) *
            60000,
            10
          ),
        localTime: localTime,
        isLog: true,
      });
    }
  };

  disableContextMenu = (event) => {
    event.preventDefault();
  };

  disableF12 = (event) => {
    if (event.keyCode === 123) {
      event.preventDefault();
    }
  };

  handleChange(i, op) {
    const correctAnswer =
      op === this.state.quiz.questions[this.state.index].correct_answer.name;
    this.setState({ seletedAnswers: { i, op, correctAnswer } });
    this.setState({ currentIndex: i, isDisabled: false });
    let addKey = this.state.quiz;
    addKey.questions[i].index = i;
    addKey.questions[i].option = op;
    localStorage.setItem("questions", JSON.stringify(addKey.questions));
    this.setState({ quiz: addKey });
  }

  submitQuestion() {
    this.setState({ btnLoading: true })
    var count = 0;
    const { quiz } = this.state;
    // eslint-disable-next-line
    quiz.questions.map((ques) => {
      if (ques.correct_answer.name === ques.option) {
        count = count + 1;
      }
    });

    localStorage.removeItem("isQuizUnderway");
    localStorage.removeItem("quiz");
    localStorage.removeItem("questions");
    this.setState(
      {
        correctAns: count,
        total: quiz.questions.length,
        quizName: { category: quiz.category, name: quiz.name },
        quizCompleted: true
      },
      () => {
        this.goResult();
      }
    );
  }

  async goResult() {
    if (JSON.parse(localStorage.getItem("isReview"))) {
      localStorage.removeItem("isReview");
      this.setState({
        pageNavigate: true,
        pageRoute: "/quizzes",
      });
    } else {
      var allResult = this.context.studentDetails?.result || [];
      let result = {
        category: this.state.quizName.category,
        name: this.state.quizName.name,
        marks: (this.state.correctAns * 100) / this.state.total,
        date: new Date(),
        batch: this.context.currentCourse.name,
        progress: this.state.quiz,
      };
      allResult.push(result);
      const updatedStudentData = (this.context.studentDetails.result =
        allResult);
      await userUpdate(this.context.studentDetails.email, {
        result: updatedStudentData,
      });
      this.setState({
        pageNavigate: true,
        pageState: result,
        pageRoute: "/result",
      });
    }
  }

  logOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.props.history.replace("/");
      });
  };

  togglePrev(e) {
    let index = this.state.index - 1;
    let disabledPrev = index === 0;

    this.setState({
      index: index,
      disabledPrev: disabledPrev,
      disabledNext: false,
    });
    this.setState({ isDisabled: false });
  }

  toggleNext(e) {
    const { seletedAnswers, seletedAnswersList, index } = this.state;
    const demo = seletedAnswersList.find((value) => value.i === index);
    if (!demo) {
      this.setState(
        { seletedAnswersList: [...seletedAnswersList, seletedAnswers] },
        () => {
          let index = this.state.index + 1;
          this.setState({ index: index, isDisabled: true });
        }
      );
    } else {
      let index = this.state.index + 1;
      this.setState({ index: index, isDisabled: true });
    }
  }

  render() {
    let localTime = this.state.localTime;
    const { isLog, index, pageNavigate, pageRoute, time, pageState, quizCompleted, btnLoading } =
      this.state;
    const questions = this.state.quiz.questions
      ? this.state.quiz.questions[index]
      : null;
    const lastIndex =
      this.state.quiz.questions && this.state.quiz.questions.length - 1;
    let isReview = JSON.parse(localStorage.getItem("isReview"));
    const totalQuiz =
      this.state.quiz.questions && this.state.quiz.questions.length + 1;
    const percent = Math.floor((index / totalQuiz) * 100);
    let remainQuestion = totalQuiz - this.state.seletedAnswersList.length;

    let percent1 = this.state.seletedAnswersList.filter(
      (value) => value.correctAnswer == true
    );
    percent1 = Math.floor((percent1.length / totalQuiz) * 100);

    let percent2 = this.state.seletedAnswersList.filter(
      (value) => value.correctAnswer == true
    );
    percent2 =
      Math.floor(
        (percent2.length / this.state.seletedAnswersList.length) * 100
      ) || 0;

    let percent3 = this.state.seletedAnswersList.filter(
      (value) => value.correctAnswer == true
    );
    percent3 = Math.floor(
      ((percent3.length + remainQuestion) / totalQuiz) * 100
    );

    return isLog ? (
      <Container fluid={true} style={{ marginTop: "-92px" }}>
        <QuestionProgress
          index={index}
          totalQuiz={totalQuiz}
          QuestionProgress={percent}
          quiz={this.state.quiz}
          isReview={isReview}
        />
        <Row className="justify-content-center">
          <Col lg={6}>
            <div
              className="dashboard"
              style={{
                minHeight: "100vh",
                paddingBottom: "35px",
                marginTop: "100px",
              }}
            >
              {!isReview && <QuizTimer
                time={time}
                submitQuestion={!quizCompleted ? this.submitQuestion : () => { }}
              />}
              <Card className="p-4">
                <div>
                  <P attrPara={{ className: "show-color" }}>
                    <b>{index + 1}{")"}</b>     {questions && questions.question}
                  </P>
                  {questions && questions.imageUrl && (
                    <div className="question-image-wrapper">
                      <img
                        className="question-image"
                        src={questions.imageUrl}
                        alt="img"
                      />
                    </div>
                  )}
                  <div style={{ position: "relative", marginBottom: 12 }}>
                    <input
                      type="checkbox"
                      className="check-box checkedColor"
                      disabled={isReview}
                      onChange={() =>
                        this.handleChange(index, questions.questions.A.name)
                      }
                      checked={
                        questions &&
                          questions.index === index &&
                          questions &&
                          questions.option === questions.questions.A.name
                          ? true
                          : false
                      }
                      value="checkedA"
                    />
                    <label
                      className={`option-label 
                                        ${isReview &&
                        questions &&
                        (questions.questions.A.name ===
                          questions.correct_answer.name
                          ? "correct-answer"
                          : "inncorrect-answer")
                        }`}
                    >
                      {questions.questions.A.value}
                    </label>
                  </div>
                  <div style={{ position: "relative", marginBottom: 12 }}>
                    <input
                      type="checkbox"
                      className="check-box checkedColor"
                      disabled={isReview}
                      onChange={() =>
                        this.handleChange(index, questions.questions.B.name)
                      }
                      checked={
                        questions &&
                          questions.index == index &&
                          questions &&
                          questions.option == questions.questions.B.name
                          ? true
                          : false
                      }
                      value="checkedB"
                    />
                    <label
                      className={`option-label 
                                        ${isReview &&
                        questions &&
                        (questions.questions.B.name ===
                          questions.correct_answer.name
                          ? "correct-answer"
                          : "inncorrect-answer")
                        }`}
                    >
                      {questions.questions.B.value}
                    </label>
                  </div>
                  <div style={{ position: "relative", marginBottom: 12 }}>
                    <input
                      type="checkbox"
                      className="check-box checkedColor"
                      disabled={isReview}
                      onChange={() =>
                        this.handleChange(index, questions.questions.C.name)
                      }
                      checked={
                        questions &&
                          questions.index == index &&
                          questions &&
                          questions.option == questions.questions.C.name
                          ? true
                          : false
                      }
                      value="checkedC"
                    />
                    <label
                      className={`option-label 
                                        ${isReview &&
                        questions &&
                        (questions.questions.C.name ===
                          questions.correct_answer.name
                          ? "correct-answer"
                          : "inncorrect-answer")
                        }`}
                    >
                      {questions.questions.C.value}
                    </label>
                  </div>
                  <div style={{ position: "relative", marginBottom: 12 }}>
                    <input
                      type="checkbox"
                      className="check-box checkedColor"
                      disabled={isReview}
                      onChange={() =>
                        this.handleChange(index, questions.questions.D.name)
                      }
                      checked={
                        questions &&
                          questions.index == index &&
                          questions &&
                          questions.option == questions.questions.D.name
                          ? true
                          : false
                      }
                      value="checkedD"
                    />
                    <label
                      className={`option-label 
                                ${isReview &&
                        questions &&
                        (questions.questions.D.name ===
                          questions.correct_answer.name
                          ? "correct-answer"
                          : "inncorrect-answer")
                        }`}
                    >
                      {questions.questions.D.value}
                    </label>
                  </div>
                </div>
              </Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 20,
                }}
              >
                {isReview && index !== 0 && (
                  <Btn
                    attrBtn={{
                      onClick: (e) => this.togglePrev(e),
                      color: "secondary",
                    }}
                  >
                    Previous
                  </Btn>
                )}
                {index !== lastIndex ? (
                  <Btn
                    attrBtn={{
                      color: "primary",
                      disabled: !isReview && this.state.isDisabled,
                      className: `quiz-btn ${!isReview && this.state.isDisabled && "disabled"}`,
                      onClick: (e) => this.toggleNext(e),
                    }}
                  >
                    {isReview ? "Next" : "Submit"}
                  </Btn>
                ) : (
                  <Btn
                    attrBtn={{
                      color: "primary",
                      disabled: btnLoading,
                      className: "quiz-btn",
                      onClick: () => this.submitQuestion(),
                    }}
                  >
                    {btnLoading ? <Loader /> : isReview ? "Home" : "Submit"}
                  </Btn>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {pageNavigate && <Navigate to={pageRoute} state={pageState} />}
      </Container >
    ) : (
      ""
    );
  }
}

export default StartQuiz;
// export default withRouter(StartQuiz)
