import React, { useContext } from "react";
import { Breadcrumbs, P } from "../../AbstractElements";
import { Card, CardBody, Col, Container, Table } from "reactstrap";
import studentContext from "../../Helper/Student";
import moment from "moment";
import TotalSellingProductCardHeader from "../Dashboard/Ecommerce/Total/TotalSellingProductCardHeader";
import TotalSellingProductTbody from "../Dashboard/Ecommerce/Total/TotalSellingProductTbody";
import OrderListCardHeader from "../Dashboard/Ecommerce/OrderList/OrderListCardHeader";
import OrderListCardTableBody from "../Dashboard/Ecommerce/OrderList/OrderListCardTableBody";

const QuizResult = () => {
  const { studentDetails } = useContext(studentContext);

  return (
    <div>
      <Breadcrumbs mainTitle="Quizzes Result" parent="Dashboard" />
      <Container fluid={true} className="dashboard-2">
        <Col sm="12" lg="10" className="mx-auto">
          <Card className="order-card py-5">
            {/* <OrderListCardHeader /> */}
            <CardBody className="pt-0">
              <div className="table-responsive theme-scrollbar">
                <Table className="table-bordernone">
                  <thead>
                    <tr>
                      <th className="f-26">Subject</th>
                      <th>Quiz</th>
                      <th>Date</th>
                      <th>Marks</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <OrderListCardTableBody data={studentDetails.result} />
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </div>
  );
};

export default QuizResult;
