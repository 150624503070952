import React, { useContext } from "react";
import { Card, Col, Row, CardBody } from "reactstrap";
import { H6, Image, P, Ribbon } from "../../../../AbstractElements";
import { Link } from "react-router-dom";
import { truncateString } from "../../../../utils/text";
import studentContext from "../../../../Helper/Student";
import { toast } from "react-toastify";
import { COURSES_ONBOARD } from "../../../../Constant";
import moment from "moment";

const CardsClass = ({ data, cardCol, type, formik, setScrollBottom}) => {
  const { studentDetails } = useContext(studentContext);
  const currentCourseId = studentDetails?.courses?.length
    ? studentDetails.courses[studentDetails.courses.length - 1].courseId
    : "";

  const isOnboardCourses = type === COURSES_ONBOARD;

  const selectCourse = (course) => {
    if (!isOnboardCourses) return;
    if (course.seatsFull) return
    formik.setFieldValue("courseId", course.id);
  };

  const renderRibbon = (item) => {
    const isCurrentCourse = currentCourseId === item.id;
    const isAdmissionOpen = item.admissionOpen;
    const isSeatsFull = item.seatsFull;
    const isEnrolledAndSeatsFull = studentDetails?.courses?.length ? studentDetails?.courses?.find((cour) => cour.courseId === item.id) : false

    if (formik?.values?.courseId === item.id) {
      return "Selected Course";
    } else if (isCurrentCourse) {
      return "Current Course";
    } else if (isSeatsFull && !isEnrolledAndSeatsFull) {
      return "Seats Full";
    } else if (isAdmissionOpen && !isOnboardCourses) {
      return "Admission Open";
    } else {
      return "";
    }
  };
  const handleClick = (item) => {
    selectCourse(item)
    setScrollBottom(true);
  };

  const renderCourseDetails = (item) => {
    return (
      <div>
        <span style={{ textTransform: "uppercase" }}>{item.days}</span>  | {moment(new Date(item.startDate)).format("LT")} -{" "}
        {moment(new Date(item.endDate)).format("LT")}
      </div>
    );
  };

  const renderCardBody = (item) => {
    return (
      <CardBody className={isOnboardCourses ? "course-card-body" : ""}>
        {renderRibbon(item) && (
          <Ribbon
            attrRibbons={{
              className: `ribbon ribbon-clip-right ribbon-right ${renderRibbon(item) === "Current Course" ||
                (item.admissionOpen && !item.seatsFull)
                ? "success-ribbon"
                : "danger-ribbon"
                }`,
            }}
          >
            {renderRibbon(item)}
          </Ribbon>
        )}
        <div className="d-sm-flex d-block align-items-center sm-m-t-10 m-t-20">
          <Image
            attrImage={{
              className: "img-fluid m-r-20",
              src: item.icon,
              alt: "",
            }}
          />
          <div className="flex-grow-1">
            <H6 attrH6={{ className: "f-w-600" }}>
              <Link to={isOnboardCourses ? "#" : `/courses/${item.id}`}>
                {item.name}
              </Link>
            </H6>
            <P attrPara={{ className: "text-capitalize" }}>{item.type}</P>
            {isOnboardCourses && renderCourseDetails(item)}
          </div>
        </div>
        {!isOnboardCourses && (
          <P attrPara={{ className: "mb-0" }}>
            {truncateString(item.description, 220)}
          </P>
        )}
        {!isOnboardCourses && (
          <div
            className={`my-3 d-flex align-items-center ${item.isEnrolled || item.seatsFull
              ? "justify-content-center"
              : "justify-content-between"
              }`}
          >
            <Link to={`/courses/${item.id}`}>{"View Course"}</Link>
            {!item.isEnrolled && !item.seatsFull && (
              <Link
                to="#"
                onClick={() =>
                  toast("You are already enrolled in a course")
                }
              >
                Enroll Now
              </Link>
            )}
          </div>
        )}
      </CardBody>
    );
  };

  return (
    <Col className="box-col-8 course-card">
      <Row>
        {data &&
          data.map((item, i) => (
            <Col xl={cardCol || "6"} className="box-col-6" key={i}>
              <Card
                onClick={() =>handleClick(item)}
                
                className={
                  isOnboardCourses
                    ? "course-main-card" :
                    currentCourseId === item.id || item.admissionOpen
                      ? "ribbon-wrapper-right"
                      : ""
                }
              >
                <div className="job-search">{renderCardBody(item)}</div>
              </Card>
            </Col>
          ))}
      </Row>
    </Col>
  );
};

export default CardsClass;