import { Btn, H5, P } from "../../../AbstractElements";
import { useState } from "react";
import { Col, Card, CardHeader, Collapse, CardBody } from "reactstrap";
import PromptModal from "../../../Common/Component/PromptModal";
import { calculateSecondModuleFeesDate } from "../../../utils/courses";
import CommonModal from "../../UiKits/Modals/common/modal";
import FeeSubmissionForm from "../../../Common/Forms/FeeSubmissionForm";
import { getNumberSuffix } from "../../../utils/text";

const icons = {
  pending: "fa-clock-o",
  verified: "fa-check",
  notVerfied: "fa-times",
};
const UpcomingClass = ({ courseDetails }) => {
  const [isDevelopment, setIsDevelopment] = useState(true);
  const [showFeeUpload, setShowFeeUpload] = useState(false)
  const [viewScreenshot, setViewScreenshot] = useState({
    show: false,
    image: '',
    image2: ''
  })

  const feeScreenshotViewer = (image, image2) => {
    setViewScreenshot({
      show: true,
      image,
      image2
    })
  }

  const renderScreenshotDate = (screenshot) => {
    const date = new Date(
      screenshot.date.seconds
        ? screenshot.date.seconds * 1000
        : screenshot.date
    );
    const monthOptions = { month: "short" };
    const dayOptions = { day: "numeric" };
    const month = date.toLocaleString("en-US", monthOptions);
    const day = date.toLocaleString("en-US", dayOptions);
    const suffix = getNumberSuffix(day);

    return <>
      <h5 className="mb-0 font-primary">{day}<sup>{suffix}</sup> {month}</h5>
      {/* <span className="d-block m-l-10">{month}</span> */}
    </>
  }

  const renderFeeScreenshot = () => {
    if (viewScreenshot?.image2) {
      return `<div 
      class='scrollar-form removeScrollbar'>
      <img class='w-100 h-100' style='object-fit: contain;'  src=${viewScreenshot.image} /> 
      <img class='w-100 h-100' style='object-fit: contain;' src=${viewScreenshot?.image2} />
      </div>`
    } else {
      return `<div 
      class='scrollar-form removeScrollbar'>
      <img class='w-100 h-100' style='object-fit: contain;' src=${viewScreenshot.image} /> 
      </div>`
    }
  }

  const isSecondModuleStart = calculateSecondModuleFeesDate(courseDetails.startDate, courseDetails.endDate)

  return (
    <Col xl="12">
      <Card>
        <CardHeader>
          <H5 attrH5={{ className: "mb-0" }}>
            <Btn
              attrBtn={{
                className: "btn-link ps-0",
                onClick: () => setIsDevelopment(!isDevelopment),
                color: "transperant",
              }}
            >
              {"Fees Status"}
            </Btn>
          </H5>
        </CardHeader>
        <Collapse isOpen={isDevelopment}>
          <CardBody className="upcoming-course">
            {courseDetails.feeScreenshots.map((screenshot, index) => {

              return (
                <>
                  <div className="d-flex" key={index}>
                    <div className="flex-grow-1">
                      <span className="f-w-600">
                        {index === 0 ? "Module One" : "Module Two"}
                      </span>
                      <span className="d-flex align-items-center">
                        <i className={`fa ${icons[screenshot.status]}`}></i>{" "}
                        <P
                          attrPara={{
                            className: "m-b-0 m-l-5 text-capitalize",
                          }}
                        >
                          {screenshot.skipModule ? 'Not Enrolled' : screenshot.status}
                        </P>
                      </span>
                    </div>
                    {screenshot.skipModule ? '' : <div className="">
                      {/* <P attrPara={{ title: 'View screenshot', className: 'screenshot-view m-b-0', onClick: () => feeScreenshotViewer(screenshot.image, screenshot.image2) }}>
                        {receptIcon()}
                      </P> */}
                      <div className="d-flex align-items-center">
                        {renderScreenshotDate(screenshot)}
                      </div>
                      <P attrPara={{ onClick: () => feeScreenshotViewer(screenshot.image, screenshot.image2), className: 'receipt-text font-primary' }}>View Receipt</P>
                    </div>}
                  </div>
                  {courseDetails.feeScreenshots.length === 1 && (
                    <div className="d-flex m-t-0">
                      <div className="flex-grow-1">
                        <span className="f-w-600">{"Module Two"}</span>
                        <span className="d-flex align-items-center justify-content-center">
                          {isSecondModuleStart ?
                            <Btn attrBtn={{ color: "primary", className: 'm-t-10 w-auto', onClick: () => setShowFeeUpload(true) }}> Upload Fee Screenshot</Btn> :
                            <P
                              attrPara={{
                                className: "m-b-0 m-l-5 text-capitalize",
                              }}
                            >
                              Coming Soon..
                            </P>}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </CardBody>
        </Collapse>
      </Card>
      <CommonModal
        title='Fee Receipt'
        bodyClass='p-t-0'
        isOpen={viewScreenshot.show}
        toggler={() => {
          setViewScreenshot({
            show: false,
            image: '',
            image2: ''
          })
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: renderFeeScreenshot() }} />
      </CommonModal>
      {/* <PromptModal
        modalText={renderFeeScreenshot()}
        isBtn={true}
        innerHtml={true}
        btnText={"Close"}
        btnFunction={() => {
          setViewScreenshot({
            show: false,
            image: '',
            image2: ''
          })
        }}

      />} */}
      <CommonModal
        title='Fee Submission'
        bodyClass='p-t-0'
        footerClass='d-none'
        isOpen={showFeeUpload}
        toggler={() => setShowFeeUpload(false)}
      >
        <FeeSubmissionForm
          className='w-100 p-0 h-auto'
          cardClass='m-b-0 p-0'
          containerClass='h-auto p-0'
          onClose={() => setShowFeeUpload(false)}
        />
      </CommonModal>

    </Col>
  );
};

export default UpcomingClass;
