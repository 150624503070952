import { useEffect, useState } from "react";

export default function useFile() {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (!file) {
      if (url) setUrl("");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setUrl(reader.result);
    };
    reader.readAsDataURL(file);
  }, [file]);
  return { file, setFile, url, setUrl };
}
