import { useContext, useEffect, useState } from "react";
import { CardBody } from "reactstrap";
import Chart from "react-apexcharts";
import { apexDonutCharts } from "../ChartsData/ApexChart";
import { ArrowUpCircle } from "react-feather";
import DeviceList from "./DeviceList";
import studentContext from "../../../../Helper/Student";
import { useTour } from "@reactour/tour";

const UserCardBody = ({ showOnboard }) => {
  const { setIsOpen, currentStep, steps, styles, isOpen } = useTour()
  const [chartData, setchartData] = useState(apexDonutCharts)


  const {
    attendanceData: { absent, present, leave },
    calenderData,
  } = useContext(studentContext);
  const attendaceData = calenderData.length ? [present, absent, leave] : [1];
  useEffect(() => {
    if (isOpen) {
      setchartData({
        ...chartData,
        series: [4, 3, 1],
        options: {
          colors: ["#5A64DC", "#FD092E", "#FF9A57"],
          tooltip: {
            enabled: true
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          }
        }
      })

    } else {
      setchartData({
        ...chartData,
        series: attendaceData,
        options: {
          colors: calenderData.length
            ? ["#5A64DC", "#FD092E", "#FF9A57"]
            : ["#323347"],
          tooltip: {
            enabled: calenderData.length ? true : false
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          }
        }
      })
    }

  }, [isOpen])

  if (present === undefined) return <></>
  return (
    <CardBody>
      <div className="user-chart">
        {chartData.series.length ?
          <>
            <Chart
              id="user-chart"
              options={chartData.options}
              series={chartData.series}
              type="donut"
              height={300}
            />
            <div className="icon-donut">
              <ArrowUpCircle />
            </div>
          </>
          : ""}
      </div>
      <DeviceList attendaceData={isOpen ? [4, 3, 1] : calenderData.length ? attendaceData : []} />
    </CardBody>
  );
};

export default UserCardBody;
