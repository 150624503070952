export const getBrowserInfo = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    
    if (macPlatforms.indexOf(platform) !== -1) {
      return 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      return 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      if (userAgent.indexOf('Windows NT 10.0') !== -1) {
        return 'Windows 10';
      } else if (userAgent.indexOf('Windows NT 6.3') !== -1) {
        return 'Windows 8.1';
      } else if (userAgent.indexOf('Windows NT 6.2') !== -1) {
        return 'Windows 8';
      } else if (userAgent.indexOf('Windows NT 6.1') !== -1) {
        return 'Windows 7';
      } else if (userAgent.indexOf('Windows NT 6.0') !== -1) {
        return 'Windows Vista';
      } else if (userAgent.indexOf('Windows NT 5.1') !== -1) {
        return 'Windows XP';
      } else {
        return 'Unknown Windows';
      }
    } else {
      return platform;
    }
  }
