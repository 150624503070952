import React from "react";
import { Breadcrumbs } from "../../AbstractElements";
import LearningList from '../Learning/LearningList'

const Courses = () => {
  return (
    <div>
      <Breadcrumbs mainTitle="Courses" parent="Dashboard" />
      <LearningList />
    </div>
  );
};

export default Courses;
