import { CardBody } from "reactstrap";
import { newsupdate } from "../../../../Data/DashBord/News&Update/news";
import { P } from "../../../../AbstractElements";
import { useContext, useState } from "react";
import studentContext from "../../../../Helper/Student";
import { getTotalUpcomingClassess } from "../../../../utils/courses";
import { useEffect } from "react";
import moment from "moment";

const RecentActivityCardBody = () => {
  const { currentCourse } = useContext(studentContext);
  const [upcommingClasses, setUpcommingClasses] = useState([]);

  useEffect(() => {
    const upcommingClass = getTotalUpcomingClassess(currentCourse);
    setUpcommingClasses(upcommingClass);
  }, []);

  return (
    <CardBody>
      <div className="activity-timeline removeScrollbar">
        {upcommingClasses.map((item, i) => {
          const date = item.split(" ")
          const formattedDate = date[0] +  " " + date[1] + " " + date[2]
          return (<div className="d-flex align-items-start" key={i}>
            <div className="activity-line"></div>
            <div
              className={`${i % 2 ? "activity-dot-primary" : "activity-dot-secondary"
                } `}
            ></div>
            <div className="flex-grow-1">
              <span className="f-w-600 d-block show-color">
                {currentCourse.name}
              </span>
              <P attrPara={{ className: "mb-0" }}> {formattedDate} | {currentCourse.classStartTime} - {moment(currentCourse.endDate).format('LT')}</P>
            </div>
          </div>)
        })}
      </div>
    </CardBody>
  );
};

export default RecentActivityCardBody;
