import { Card, Col } from "reactstrap";
import RecentActivityCardHeader from "./RecentActivityCardHeader";
import RecentActivityCardBody from "./RecentActivityCardBody";

const RecentActivity = () => {
  return (
    <Col xl="4" className="d-flex">
      <Card className='upcoming-classes w-100'>
        <RecentActivityCardHeader />
        <RecentActivityCardBody />
      </Card>
    </Col>
  );
};

export default RecentActivity;