import React, { useContext, useEffect, useState } from "react";
import studentContext from "../Helper/Student";
import PromptModal from "./Component/PromptModal";
import firebase from 'firebase'
import {
  BANNED_USER,
  CurrentCourse,
  EMAIL_VERIFIED,
  FEE_SUBMISSION,
  FEE_SUBMISSION_FAILED,
  FEE_VERIFICATION_FAILED,
  FIRST_MODULE_FEE_SUBMISSION,
  MODULE_ONE,
  MODULE_TWO,
  NEW_COURSE_ENROLLMENT,
  RESUBMISSION_TEXT,
  SECOND_MODULE_FEE,
  UPDATED_UI_DATE,
  VERIFIED_EMAIL,
} from "../Constant";
import NewCourseForm from "./Forms/NewCourseForm";
import FeeSubmission from "./Forms/FeeSubmissionForm";
import { getCurrentModule, matchTwoDates } from "../utils/courses";
import CommonModal from "../Components/UiKits/Modals/common/modal";
import { Btn, P } from "../AbstractElements";
import { downloadFeeScreenshot, getNotVerifiedFees, getRefundScreenshot } from "../utils/fees";
import { ModalFooter } from "reactstrap";
import useAdminTrackActivity from "../Hooks/useAdminTrackActivity";
import { toast } from "react-toastify";
import { sendVerificationEmail } from "../Service/Firebase";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";

const AuthLayout = ({ children }) => {
  const { studentDetails, currentCourse, loginAdmin, setStudentDetails, setLoginAdmin } =
    useContext(studentContext);
  const { userDenied, coordination } = useAdminTrackActivity()
  const [showResend, setShowResend] = useState(true)
  const [courseForm, setCourseForm] = useState(false);
  const [secondCourseEnroll, setSecondCourseEnroll] = useState(false)
  const [ShowFullScreenshot, setShowFullScreenshot] = useState(false)
  const notVerifiedFees = getNotVerifiedFees(currentCourse)
  const [seconds, setSeconds] = useState(60);
  const isRefundScreeshot = getRefundScreenshot(currentCourse)
  const history = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds])

  const feeRefundAction = () => {
    const downloadRefundFee = () => {
      downloadFeeScreenshot(currentCourse.feeScreenshots[isRefundScreeshot].refundReceiptUrl)
    }
    return (
      <>
        <ModalFooter className="justify-content-start">
          <b><P attrPara={{ className: 'm-t-10 f-w-600' }}>Refund Details:</P></b>
          <div className="d-flex align-items-center justify-content-between">
            <Btn attrBtn={{ color: "primary", outline: true, onClick: () => setShowFullScreenshot(true) }}>View Refund Screenshot</Btn>
            <Btn attrBtn={{
              color: "primary", className: 'm-l-10',
              onClick: downloadRefundFee
            }}>Download Screenshot</Btn>
          </div>
        </ModalFooter>
        <b><P
          attrPara={{
            className: 'm-t-10 f-w-600 p-l-10 m-b-0',
          }}>
          Enrollment Details:</P></b>
      </>
    )
  }

  const sendEmail = async () => {
    setSeconds(60)
    if (showResend) setShowResend(false)
    try {

      await sendVerificationEmail()
      setTimeout(() => {
        setShowResend(true)
      }, 60000);
      toast.success('Email Send')
    } catch (error) {
      toast(error.message)
    }
  }


  const Logout = async () => {
    const isViewStudentprofile = localStorage.getItem("loginAsUser");
    await firebase.auth().signOut();
    setStudentDetails(null);
    localStorage.removeItem('mix_background_layout')
    document.body.classList.remove("dark-only");
    history(`/login`);
    if (isViewStudentprofile) {
      localStorage.removeItem("loginAsUser");
      setLoginAdmin(null);
    }
  };

  const renderLayout = () => {
    const currModule = getCurrentModule(currentCourse?.startDate, currentCourse?.endDate, currentCourse?.feeScreenshots)

    const modalConfigurations = studentDetails
      ? [
        {
          component: PromptModal,
          condition: studentDetails?.banned,
          props: { modalText: BANNED_USER },
        },
        {
          component: PromptModal,
          condition: !currentCourse.name && !courseForm,
          props: {
            modalText: CurrentCourse,
            modal: 'modal-bg',
            isBtn: true,
            btnText: "Continue",
            btnFunction: () => {
              setCourseForm(true);
            },
          },
        },
        {
          component: NewCourseForm,
          condition: !currentCourse.name && courseForm || secondCourseEnroll,
          props: {
            type: NEW_COURSE_ENROLLMENT,
            onClose: () => {
              setCourseForm(false)
              setSecondCourseEnroll(false)
            },
          },
        },
        {
          component: PromptModal,
          condition: matchTwoDates(studentDetails?.createdAt?.seconds * 1000, UPDATED_UI_DATE) && !studentDetails?.emailVerified,
          props: {
            modalText: `<div>${EMAIL_VERIFIED} <div class='m-t-10'> ${RESUBMISSION_TEXT}</div></div> </div>`,
            type: VERIFIED_EMAIL,
            showTimer: true,
            seconds,
            isSecondary: true,
            footerClass: 'justify-content-between',
            disabled: !showResend,
            secondaryText: 'Logout',
            btnSecondary: () => Logout(),
            innerHtml: true,
            isBtn: true,
            modal: 'modal-bg',
            btnText: "Resend Email",
            btnFunction: sendEmail,
          },
        },
        {
          component: PromptModal,
          condition: currModule === MODULE_ONE && !courseForm,
          props: {
            modalText: FEE_SUBMISSION,
            modal: 'modal-bg',
            isBtn: true,
            btnText: "Continue",
            btnFunction: () => {
              setCourseForm(true);
            },
          },
        },
        {
          component: FeeSubmission,
          condition: currModule === MODULE_ONE && courseForm,
          props: {
            formHeading: "Submit Your fees",
            type: FIRST_MODULE_FEE_SUBMISSION,
            onClose: () => setCourseForm(false),
          },
        },
        {
          component: PromptModal,
          condition: currentCourse?.feeScreenshots[notVerifiedFees] && !courseForm,
          props: {
            modalText: `${FEE_VERIFICATION_FAILED} <p class='m-t-15 m-b-0'><b>Reason: </b> ${currentCourse.feeScreenshots[notVerifiedFees]?.reason}</p>`,
            isBtn: true,
            innerHtml: true,
            modal: 'modal-bg',
            btnText: "Continue",
            btnFunction: () => {
              setCourseForm(true);
            },
          },
        },
        {
          component: FeeSubmission,
          condition: currentCourse?.feeScreenshots[notVerifiedFees] && courseForm,
          props: {
            formHeading: "Reupload Your fee screenshot",
            type: FEE_SUBMISSION_FAILED,

            index: notVerifiedFees,
            onClose: () => setCourseForm(false),
          },
        },
        {
          component: PromptModal,
          condition: currModule === MODULE_TWO && !courseForm,
          props: {
            modalText: SECOND_MODULE_FEE,
            isBtn: true,
            modal: 'modal-bg',
            btnText: "Continue",
            btnFunction: () => {
              setCourseForm(true);
            },
          },
        },
        {
          component: FeeSubmission,
          condition: currModule === MODULE_TWO && courseForm,
          props: {
            formHeading: "Submit Your fees",
            onClose: () => setCourseForm(false),
          },
        },
        {
          component: PromptModal,
          condition: isRefundScreeshot && ShowFullScreenshot,
          props: {
            modalText: `<img class='h-100 w-100' src=${currentCourse.feeScreenshots[isRefundScreeshot]?.refundReceiptUrl} />`,
            isBtn: true,
            innerHtml: true,
            btnText: "Close",
            btnFunction: () => {
              setShowFullScreenshot(false)
            },
          },
        },
        {
          component: PromptModal,
          condition: isRefundScreeshot && !secondCourseEnroll && !ShowFullScreenshot,
          props: {
            modalText: `Your fees has been refunded for ${currentCourse.name} ${currentCourse.type} module ${isRefundScreeshot === 0 ? 1 : 2}`,
            isBtn: true,
            modalAction: feeRefundAction(),
            btnText: "Enroll New Course",
            btnFunction: () => {
              setSecondCourseEnroll(true)
            },
          },
        }
      ]
      : [];
    const activeModalConfig = modalConfigurations.find(
      (config) => config.condition
    );

    if (activeModalConfig) {
      const ActiveModalComponent = activeModalConfig.component;
      return <ActiveModalComponent {...activeModalConfig.props} />;
    }

    return <>{children}</>;
  };

  if (loginAdmin?.isAdmin) {
    return (
      <>
        {coordination.lat ? (
          studentDetails?.email ? (
            <>
              <div>{renderLayout()}</div>;
            </>
          ) : (
            <CommonModal
              isOpen={true}
              title={`Admin Login email: ${loginAdmin.email} `}
              footerClass="d-none"
            >
              <P>
                Enter ?email=studentemail@gmail.com to view student profile as
                an admin
              </P>
            </CommonModal>
          )
        ) : !userDenied ? (
          <></>
        ) : (
          <CommonModal isOpen={true} footerClass="d-none">
            <P>Location access denied by user.</P>
          </CommonModal>
        )}
      </>
    );
  }

  return <div>{renderLayout()}</div>;
};

export default AuthLayout;
