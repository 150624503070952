import { LI, UL, Image } from "../../../../AbstractElements";
import { profileVector } from "../../../../Data/Profiledata/profiledata";
import { dynamicImage } from "../../../../Services/index";
import ProfileImageCard from "./ProfileImageCard";

const ProfileVector = ({ profileImage }) => {
  return (
    <div className="profile-vector">
      <UL attrUL={{ className: "dots-images simple-list" }}>
        {profileVector.map((item, i) => (
          <LI attrLI={{ className: `${item.classname} ` }} key={i}></LI>
        ))}
      </UL>
      <div className="user-profile-image-container">

      <Image
        attrImage={{
          className: "img-fluid welcome-image user-profile-image",
          src: profileImage,
        }}
        alt="image"
        />
        </div>
      <ProfileImageCard />
    </div>
  );
};

export default ProfileVector;
