import { Fragment, useContext, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Footer from "./Footer/index";
import TapTop from "./TapTop/index";
import Header from "./Header";
import SideBar from "./Sidebar/Sidebar";
import Themecustomizer from "./ThemeCustomizer";
import CheckContext from "../Helper/Customizer";
import ProductContext from "../Helper/product";
import AnimationThemeContext from "../Helper/AnimationTheme";
import ConfigDB from "../Config/ThemeConfig";
import Loader from "./Loader";

const Layout = ({ children, classNames, ...rest }) => {
  const navigate = useNavigate();
  const { setDefaultClass, setTogglSidebar, sidebar_types } =
    useContext(CheckContext);
  const { setIsVertical } = useContext(ProductContext);
  const location = useLocation();
  const queryData = location?.search?.split("=")[1]?.toString();
  const query = window.location.pathname;
  const { animation } = useContext(AnimationThemeContext);
  const isQuiz = localStorage.getItem("isQuizUnderway");
  const isResult = localStorage.getItem("isResult");
  const quizData = JSON.parse(localStorage.getItem("quiz"));
  const nameQuery = query?.split('/')
  const name = nameQuery.length > 1 && nameQuery[2] && nameQuery[1] === 'quizzes'

  const isQuizInProgress = isQuiz && !name;
  const quizRoute = `/quizzes/${quizData?.name?.replace(/\s/g, "")}`;
  const animationTheme =
    localStorage.getItem("animation") ||
    animation ||
    ConfigDB.data.router_animation;
  const sidebarType = localStorage.getItem("sidebar_types");
  let sidebar_types1 =
    queryData === "compact-wrapper" || queryData === "horizontal-wrapper"
      ? queryData
      : localStorage.getItem("sidebar_types") ||
      ConfigDB.data.settings.sidebar.type ||
      sidebar_types;

  const sideBarReload = () => {
    if (sidebarType === "horizontal-wrapper") {
      if (window.innerWidth <= 1200) {
        navigate({ search: `?sidebar=compact-wrapper` });
        setDefaultClass(true);
        sidebar_types1 = "compact-wrapper";
      } else {
        navigate({ search: `?sidebar=horizontal-wrapper` });
        setDefaultClass(false);
      }
    }
  };

  useEffect(() => {
    sideBarReload();
    setDefaultClass(true);
    window.addEventListener("resize", () => {
      sideBarReload();
      if (window.innerWidth - 440 <= 759) {
        setTogglSidebar(true);
      } else {
        setTogglSidebar(false);
      }
      if (window.innerWidth <= 1200) {
        setIsVertical(true);
      } else {
        setIsVertical(false);
      }
    });
  }, []);
  const isNotQuizPage = !location.pathname.includes(quizRoute)

  useEffect(() => {
    if (isQuizInProgress) {
      navigate(quizRoute);
    } else if (!quizData && !isQuizInProgress && name) {
      navigate('/quizzes');
    }
  }, [location.pathname]);

  return (
    <Fragment>
      {/* <Loader /> */}
      <TapTop />
      <div className={`page-wrapper ${sidebar_types1} `} id="pageWrapper">
        {isNotQuizPage && <Header />}
        <div className={"page-body-wrapper"}>
          {isNotQuizPage && <SideBar />}
          <div className={`page-body ${name ? 'm-l-0' : ''}`}>
            <TransitionGroup {...rest}>
              <CSSTransition
                key={location.key}
                timeout={100}
                classNames={animationTheme}
                unmountOnExit
              >
                <div>
                  {children}
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
          {isNotQuizPage && <Footer />}
        </div>
      </div>
      {/* <Themecustomizer /> */}
      <ToastContainer />
    </Fragment>
  );
};
export default Layout;
