import { attendanceColors } from "./colors";

export const updateAttendance = (data, currentCourse) => {
  if (!currentCourse) return;
  const modifiedData = {};

  data.forEach((doc) => {
    const attendanceData = doc;
    const { attendanceDate, status } = attendanceData;
    // updatedAttendance.push({
    //   title: status,
    //   start: attendanceDate,
    //   color:
    //     status == "present"
    //       ? "#5C61F2"
    //       : status == "absent"
    //       ? "#F81F58"
    //       : "#4FAAD5",
    // });
    modifiedData[attendanceDate] = status;
  });
  const { updatedAttendance, attendanceInfo } = checkTotalDays(
    modifiedData,
    currentCourse
  );
  return { updatedAttendance, data:  attendanceInfo};
};

export const checkTotalDays = (attendance, currentCourse) => {
  const startDate = new Date(currentCourse.startDate);
  const endDate = new Date(currentCourse.endDate);
  const days = currentCourse.days;
  const today = new Date();
  const classDays = days.toUpperCase().split(/[ &]+/);
  let current = new Date(startDate);
  let totalDaysFromStart = 0;
  let totalCourseClasses = 0;
  let currentDate = new Date(startDate);
  let currentDateCount = new Date(startDate);
  let present = 0;
  let absent = 0;
  let leave = 0;
  let totalClassesTillDate = 0;

  // Calculate total number of class days
  while (currentDate <= endDate) {
    if (
      classDays.includes(
        currentDate.toLocaleString("en-US", { weekday: "short" }).toUpperCase()
      )
    ) {
      totalCourseClasses++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Calculate number of class days from start date until today
  current = new Date(startDate);
  while (current <= today) {
    if (
      classDays.includes(
        current.toLocaleString("en-US", { weekday: "short" }).toUpperCase()
      )
    ) {
      totalDaysFromStart++;
    }
    current.setDate(current.getDate() + 1);
  }
  const updatedAttendance = [];

  currentDateCount = new Date(startDate);
  while (currentDateCount <= endDate) {
    const dateString = currentDateCount.toISOString().split("T")[0];
    const status = attendance[dateString];
    if (status) {
      updatedAttendance.push({
        title: status,
        start: dateString,
        color:
          status == "present"
            ? "#5C61F2"
            : status == "absent"
            ? "#F81F58"
            : "#4FAAD5",
      });
      if (status === "present") {
        present++;
      } else if (status === "absent") {
        absent++;
      } else if (status === "leave") {
        leave++;
      }
    }
    currentDateCount.setDate(currentDateCount.getDate() + 1);
  }
  totalClassesTillDate = present + absent + leave;

  // Adjust absent count if necessary
  // if (totalDaysFromStart > totalClassesTillDate) {
  //   absent += totalDaysFromStart - totalClassesTillDate;
  const attendanceInfo = {
    present,
    absent,
    leave,
    totalClassesTillDate: totalDaysFromStart,
    totalCourseClasses,
  }
  // }
  return {
    updatedAttendance,
    attendanceInfo
  };
};
