import React from "react";
import { Container, Row, Col, Form, Card } from "reactstrap";

const FormWrapper = (props) => {
  return (
    <Container fluid={true}>
      <Row className={`d-flex align-items-center justify-content-center full-height-form ${props.containerClass}`}>
        <Col md={6} xl={5} xxl={4} sm={12} className={props.className}>
          <Card className={`card-padding ${props.cardClass}`}>
            <Form onSubmit={props.formik.handleSubmit}>{props.children}</Form>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default FormWrapper;
