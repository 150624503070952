import React, { useContext, useEffect, useState } from "react";
import { Breadcrumbs, H6, Progressbar } from "../../AbstractElements";
import { category } from "../../Service/Firebase";
import { Card, Col, Row } from "reactstrap";
import QuizCard from "./QuizCard";
import studentContext from '../../Helper/Student'
import Loader from "../../Layout/Loader";
import CommonModal from "../UiKits/Modals/common/modal";

const Quizzes = () => {
  const [open, setOpen] = useState(false);
  const [beginnerQuiz, setBeginnerQuiz] = useState([]);
  const [advanceQuiz, setAdvanceQuiz] = useState([]);
  const [quizLoad, setQuizLoad] = useState(false);
  const { studentDetails } = useContext(studentContext);
// console.log('studentDetails', studentDetails)


  useEffect(() => {
    const getQuizData = async () => {
      const beginnerQuizData = [];
      const advanceQuizData = [];
      setQuizLoad(true);
      const res = await category();
      res.forEach((quiz) => {
        if (quiz.data.module == "beginner") beginnerQuizData.push(quiz);
        else if (quiz.data.module == "advance") advanceQuizData.push(quiz);
      });
      setBeginnerQuiz(beginnerQuizData.reverse());
      setAdvanceQuiz(advanceQuizData.reverse());
      setQuizLoad(false);
    };
    getQuizData();
  }, []);

  return (
    <div>
      <Breadcrumbs mainTitle="Quizzes" parent="Dashboard" />
      <div>
        {quizLoad ? (
          <Loader pageLoader={true} />
        ) : (
          <Row className="justify-content-around">
            <Col lg={4}>
              <Card className="p-20">
                <H6
                  attrH6={{
                    className: "m-b-20 show-color  text-center f-w-600",
                  }}
                >
                  Beginner
                </H6>
                {beginnerQuiz.map((beginner) => (
                  <QuizCard
                    beginner={beginner}
                    key={beginner.id}
                    open={open}
                    setOpen={setOpen}
                  />
                ))}
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="p-20">
                <H6
                  attrH6={{
                    className: "m-b-20 show-color  text-center f-w-600",
                  }}
                >
                  Advance
                </H6>
                {advanceQuiz.map((beginner) => (
                  <QuizCard
                    beginner={beginner}
                    key={beginner.id}
                    open={open}
                    setOpen={setOpen}
                  />
                ))}
              </Card>
            </Col>
          </Row>
        )}
      </div>
      {/* <CommonModal isOpen={true} title={'Hello world'} toggler={()=>{}} >....</CommonModal> */}
    </div>
  );
};

export default Quizzes;
