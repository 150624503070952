import React, { useContext, useEffect, useState } from "react";
import FormWrapper from "./FormWrapper";
import { Btn, Progressbar } from "../../AbstractElements";
import Loader from "../Loader";
import { useFormik } from "formik";
import FeeSubmission from "./FeeSubmission";
import Tesseract from "tesseract.js";
import {
  addCrashErrorToDB,
  addNotificationToDb,
  sendMail,
  firebase,
  uploadImage,
  userUpdate,
} from "../../Service/Firebase";
import { toast } from "react-toastify";
import studentContext from "../../Helper/Student";
import { FeeSubmissionValidation } from "../../Validation";
import { FEE_SUBMISSION_FAILED, FEE_VERIFICATION_FAILED } from "../../Constant";
import { calculateSecondModuleFeesDate } from "../../utils/courses";

const FeeSubmissionForm = (props) => {
  const [progress, setProgress] = useState(0);
  const { studentDetails, currentCourse, setNotifications, notifications } =
    useContext(studentContext);
  const isSecondModuleStart = calculateSecondModuleFeesDate(currentCourse.startDate, currentCourse.endDate)
  const notificationTitle = {
    pending: "Fees Pending",
    verified: "Fees Verified",
    notVerified: "Fees Not Verified",
  };
  


  const updateStudentFeeScreenshot = async (values, action) => {
    try {
      const logger = (m) => {
        if (m.progress) {
          const progress = m.progress * 100;
          if (progress !== 100) {
            setProgress(progress);
          }
        } else {
          setProgress(0);
        }
      };
      const tesseractPromise = new Promise(async (resolve, reject) => {
        const image = values.images[0]
        try {
          const {
            data: { text: extractedText },
          } = await Tesseract.recognize(image, "eng", {
            logger,
          });
          resolve(extractedText);
        } catch (error) {
          reject(error);
          toast.error("Something went wrong with Tesseract");
          const extension = image.name.split(".").pop();
          const fileSizeInBytes = +image.size;
          const fileSizeInMB = fileSizeInBytes / 1048576;
          addCrashErrorToDB(
            "Tesseract",
            error.message,
            {
              imageExtension: extension,
              imageSize: fileSizeInMB.toFixed(2) + "MB",
            },
            studentDetails.email
          );
        }
      });

      const timeoutPromise = new Promise((resolve) => {
        setTimeout(() => {
          resolve("");
        }, 15000); // 15 seconds timeout
      });

      const extractedText = await Promise.race([tesseractPromise, timeoutPromise]);
      const screenshotUrl = [];
      for (const img of values.images) {
        const uploadedFeeURL = await uploadImage(img, "feeScreenShots");
        screenshotUrl.push(uploadedFeeURL);
      }
      const currCourse = studentDetails.courses.find(
        (cour) => cour.courseId == currentCourse.id
      );
      const otherCourses = studentDetails.courses.filter(
        (cour) => cour.courseId != currentCourse.id
      );

      const feesData = {
        image: screenshotUrl[0],
        date: new Date(),
        imageText: extractedText,
        status: "pending",
        ...(screenshotUrl[1] && { image2: screenshotUrl[1] }),
      };
      if (props.type === FEE_SUBMISSION_FAILED) {
        currCourse.feeScreenshots[props.index].image = screenshotUrl[0];
        currCourse.feeScreenshots[props.index].status = "pending";
        currCourse.feeScreenshots[props.index].imageText = extractedText;
        currCourse.feeScreenshots[props.index].date = new Date();
        if (screenshotUrl[1]) {
          currCourse.feeScreenshots[props.index].image2 = screenshotUrl[1];
        }
      } else {
        if (!currentCourse.feeScreenshots.length && isSecondModuleStart) {
          currCourse.feeScreenshots.unshift({
            skipModule: true,
          });
        }
        currCourse.feeScreenshots.push(feesData);
      }
      const data = {
        courses: [...otherCourses, { ...currCourse }],
      };
      await userUpdate(studentDetails.email, data);
      const notificationBody = {
        body: {
          courseName: currentCourse.name,
          courseType: currentCourse.type,
          module: 1
        },
        type: "pending",
        title: notificationTitle["pending"],
        email: studentDetails.email,
      };
      notifications.unshift({
        ...notificationBody,
        read: false,
        createdAt: new Date().toISOString(),
      });
      setNotifications(notifications);
      await addNotificationToDb(notificationBody);
      sendMail(studentDetails.email, studentDetails.fullName);
      toast.success("Fee submit successfully");
      props.onClose();
    } catch (error) {
      toast.error(error);
    }
  };

  const formik = useFormik({
    initialValues: { images: [] },
    validationSchema: FeeSubmissionValidation,
    onSubmit: updateStudentFeeScreenshot,
  });

  useEffect(() => {
    if (formik.isValid) return;

    const element = document.querySelector(
      `span[id='feeScreenshot']`
    );
    if (!element) return;

    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [formik.submitCount, formik.isValid]);

  return (
    <FormWrapper formik={formik} className={props.className} cardClass={props.cardClass} containerClass={props.containerClass}>
      <FeeSubmission props={{ ...props, ...formik }} />
      {progress ? <Progressbar
        attrProgress={{
          color: "success",
          className: "m-t-10",
          value: progress,
          animated: true,
        }}
      /> : ""}
      <div className="p-t-10 d-flex align-items-center justify-content-between">
        <Btn
          attrBtn={{
            color: "secondary",
            disabled: formik.isSubmitting,
            onClick: () => props.onClose(),
          }}
        >
          Close
        </Btn>
        <Btn
          attrBtn={{
            color: "primary",
            type: "submit",
            disabled: formik.isSubmitting,
          }}
        >
          {formik.isSubmitting ? <Loader /> : "Submit"}
        </Btn>
      </div>
    </FormWrapper>
  );
};

export default FeeSubmissionForm;
