import React from "react";
import { Breadcrumbs } from "../../AbstractElements";
import DraggableContain from "../Calender/Draggable Calender";

const Attendance = () => {
  return (
    <div>
      <Breadcrumbs mainTitle="Attendance" parent="Dashboard" />
      <DraggableContain />
    </div>
  );
};

export default Attendance;
