import { Card, Col } from "reactstrap";
import UserCardBody from "./UserCardBody";
import UserCardHeader from "./UserCardHeader";

const TotalUserCard = ({ showOnboard }) => {
  return (
    <Col md='4' className="d-flex">
      <Card className="our-user w-100 attendance-report">
        <UserCardHeader />
        <UserCardBody showOnboard={showOnboard} />
      </Card>
    </Col>
  );
};

export default TotalUserCard;
