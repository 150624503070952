import { Btn, P } from "../../../../AbstractElements";
import moment from "moment/moment";
import { attendanceColors } from "../../../../utils/colors";
import { useNavigate } from "react-router-dom";

const AppoimentCardTableBody = ({ data }) => {
  const navigate = useNavigate()

  return (
    <tbody>
      {data.length ? data.map((item, index) => (
        <tr key={index}>
          <td>{moment(item.start).format("D MMMM")} </td>
          <td>
            <Btn
              attrBtn={{
                className: `show-cursor text-capitalize attendance-btn ${attendanceColors[item.title]}`,
                onClick: () => navigate('/attendance'),
              }}>
              {item.title}
            </Btn>
          </td>
        </tr>
      )) : <P attrPara={{ className: 'show-color text-center' }}>No Attendance Found</P>}
    </tbody>
  );
};

export default AppoimentCardTableBody;

