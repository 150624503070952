import React from "react";
import { H5, Progressbar } from "../../AbstractElements";

const QuestionProgress = ({ totalQuiz, QuestionProgress, quiz }) => {
  return (
    <>
      <div className="question-progress">
        <div>
          <H5 attrH5={{ className: 'f-w-600' }}>{quiz.name}</H5>
        </div>
        <div className="question-count">
          <H5 attrH5={{ className: "show-color" }}>
            <>Total Questions: {" "}</>
            {totalQuiz - 1}
          </H5>
        </div>
      </div>
      <div className="quiz-progress-bar">
        <Progressbar
          attrProgress={{
            color: "primary",
            animated: true,
            value: QuestionProgress,
            className: "md-progress-bar small-progress",
          }}
        />
      </div>
    </>
  );
};

export default QuestionProgress;
