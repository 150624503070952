import React, { useContext, useEffect, useState } from 'react'
import { getUniversityPolicies } from '../../Service/Firebase'
import { Breadcrumbs, Footer } from '../../AbstractElements'
import Layout from '../../Layout/Layout';
import studentContext from '../../Helper/Student';

const Policy = () => {
  const [policyUrl, setPolicyUrl] = useState('')
  const { studentDetails } = useContext(studentContext);

  const renderPolicies = () => {
    if (studentDetails?.fullName) {
      return (<>
        <Layout >
          <div>
            <Breadcrumbs mainTitle="Policies" parent="Dashboard" />
            <iframe
              style={{ width: '100%', height: "100vh" }}
              src={policyUrl}
            ></iframe>
          </div>
        </Layout>
      </>)
    } else {
      return (<iframe
        style={{ width: '100%', height: "100vh" }}
        src={policyUrl}
      ></iframe>)
    }
  }

  useEffect(() => {
    const getUniPolicies = async () => {
      const data = await getUniversityPolicies()
      setPolicyUrl(data.policies)
    }
    getUniPolicies()
  }, [])

  return (
    <>
      {renderPolicies()}
    </>
  )
}

export default Policy