import moment from "moment";

export const truncateString = (string = "", maxLength = 50) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

export const getNumberSuffix = (number) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const suffixIndex = number % 100;
  return (
    suffixes[(suffixIndex - 20) % 10] || suffixes[suffixIndex] || suffixes[0]
  );
};

export const timeShorter = (createdAt) => {
  // Convert the Firebase timestamp to a Moment object
  const createdMoment = moment(createdAt);
  // Calculate the time difference between the current moment and the createdAt moment
  const currentMoment = moment();
  const duration = moment.duration(currentMoment.diff(createdMoment));
  // Decide the most appropriate unit and construct the result string
  if (duration.asYears() >= 1) {
    return `${Math.floor(duration.asYears())}y`;
  } else if (duration.asMonths() >= 1) {
    return `${Math.floor(duration.asMonths())}m`;
  } else if (duration.asWeeks() >= 1) {
    return `${Math.floor(duration.asWeeks())}w`;
  } else if (duration.asDays() >= 1) {
    return `${Math.floor(duration.asDays())}d`;
  } else if (duration.asHours() >= 1) {
    return `${Math.floor(duration.asHours())}h`;
  } else if (duration.asMinutes() >= 1) {
    return `${Math.floor(duration.asMinutes())}m`;
  } else {
    return `${Math.floor(duration.asSeconds())}s`;
  }
}

export const capitalizeFirstLetter = (word) => {
  if(!word) return
  return word.charAt(0).toUpperCase() + word.slice(1);
}