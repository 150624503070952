import React, { useEffect, useState } from "react";
import { getCourseCollection, getPaymentDetails, getTotalStudents } from "../../Service/Firebase";
import Context from "./index";

const CourseProvider = (props) => {
  const [courses, setCourses] = useState([]);
  const [courseDetails, setCourseDetails] = useState(null)
  const [allStudents, setAllStudents] = useState([])
  const [paymentDetail, setPaymentDetail] = useState(null)

  const getCourses = async () => {
    try {
      const coursesData = await getCourseCollection();
      const students = await getTotalStudents();
      const generalPayemntInfo = await getPaymentDetails()
      setPaymentDetail(generalPayemntInfo.paymentDetails)
      setAllStudents(students)
      setCourses(coursesData)
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getCourses();
  }, []);

  return (
    <Context.Provider
      value={{
        ...props,
        courses,
        generalPaymentDetail: paymentDetail,
        setCourseDetails,
        allStudents,
        courseDetails,
        getCourses
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default CourseProvider;
