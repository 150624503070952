import React, { useContext, useRef } from "react";
import QrCode from 'react-qr-code'
import { Breadcrumbs, Btn, P } from "../../AbstractElements";
import { dynamicImage } from "../../Services";
import studentContext from "../../Helper/Student";
import { toast } from "react-toastify";
import { extractedCourseName } from "../../utils/courses";

const StudentCard = () => {
  const { studentDetails, currentCourse } = useContext(studentContext);
  const cardRef = useRef(null)

  const downloadClickHandler = () => {
    const isFacebookBrowser =
      navigator.userAgent.includes("FBAN") ||
      navigator.userAgent.includes("FBAV");
    if (isFacebookBrowser) {
      toast.error('Please download your ID card from google chrome')
    } else {
      cardRef.current.classList.remove('main-card')
      window.print();
      cardRef.current.classList.add('main-card')
    }
  }

  return (
    <div>
      <Breadcrumbs mainTitle="Student Card" parent="Dashboard" />
      <div ref={cardRef} className="removeScrollbar main-card">
        <div className="d-flex align-items-center justify-content-center">
          <Btn attrBtn={{ color: "primary", onClick: downloadClickHandler, className: 'student-card-btn' }}>Download</Btn>
        </div>
        <div class="student-card">
          <div className="card-front">
            <img class="img-full student-card-bg" src={dynamicImage("studentCardBackgroundLeft.png")} draggable="false" />
            <div class="id-card-front">
              <div class="card-detail-container">

                <img class="img-full" src={dynamicImage("studentCardFront.png")} draggable="false"
                />
                <P attrPara={{ className: "id-card-id" }} >{extractedCourseName(currentCourse.name)}-{currentCourse.type.charAt(0).toUpperCase()}-{studentDetails.studentId}</P>
                <P attrPara={{ className: "id-card-name" }} >{studentDetails.fullName}</P>
                <P attrPara={{ className: "id-card-contact" }} > {!studentDetails.contactNo.toString().startsWith("0") ? `0${studentDetails.contactNo}` : studentDetails.contactNo}</P>
                <img class="id-card-image" draggable="false"
                  src={studentDetails?.userProfileImage} />
              </div>
            </div>
          </div>
          <div className="card-back">
            <img class="img-full student-card-bg" src={dynamicImage("studentCardBackgroundRight.png")} draggable="false" />
            <div class="id-card-back">
              {studentDetails.studentId && <div className="qr-code-container">
                <div className="qr-code-main">
                  <QrCode
                    size={65}
                    value={`studentId=${String(studentDetails.studentId)}/email=${studentDetails.email}`}
                    viewBox={`0 0 65 65`}
                  />
                </div>
              </div>}
              <img class="img-full" src={dynamicImage("studentCardBack.png")} draggable="false" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCard;
