import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import CustomInput from "../../CommonElements/Input";
import { H4 } from "../../AbstractElements";
import RadioButton from "../../CommonElements/Input/RadioButton";
import { genders, marketing, qualificationData } from "../../Data";
import {
  moveKeyToLast,
  transformObjectToDotNotation,
} from "../../utils/errorValidationNavigate";
import Dropzone1 from "../../Components/BonusUi/Dropzones/Dropzone1";
import useFile from "../../Hooks/useFIle";
import { EDIT_PROFILE } from "../../Constant";
import studentContext from "../../Helper/Student";
import Select from "react-select";
import ImageCropper from "../../Components/Cropper";
import ImagecropperContain from "../../Components/BonusUi/ImageCropper";
import CommonModal from "../../Components/UiKits/Modals/common/modal";
import { getImageDimensions } from "../../utils/image";
import { toast } from "react-toastify";

const PersonalInfo = ({ formik, type }) => {
  const { setFile, url, setUrl } = useFile();
  const { studentDetails } = useContext(studentContext)
  const [cropper, setCropper] = useState({
    show: false,
    url: ''
  })

  const imageCanvasRef = useRef(null)

  const imageChangeHandler = ({ target }) => {
    setFile(target.files[0]);
  };

  const showError = (errorName) => {
    return formik.errors[errorName] && formik.touched[errorName] && (
      <span className="error-message">{formik.errors[errorName]}</span>
    )
  }

  const closeCropper = () => setCropper({
    show: false,
    urll: ''
  })

  const cropComplete = () => {
    const canvas = imageCanvasRef.current;
    const dataURL = canvas?.toDataURL(); // This will give you a Base64 encoded string
    const data = getImageDimensions(dataURL)
    // if (data.height < 200 || data.width < 200) {
    //   toast.error("Your image should be greater than 200x200 pixels. Please crop or upload a larger image.")
    //   closeCropper()
    //   return
    // }
    formik.setFieldValue("userImage", dataURL)
    closeCropper()
  }

  const closeImageCropper = () => {
    const data = getImageDimensions(url)
    // if (data.height < 200 || data.width < 200) {
    //   toast.error("Your image should be greater than 200x200 pixels. Please crop or upload a larger image.")
    //   closeCropper()
    //   return
    // }
    closeCropper()
    formik.setFieldValue('userImage', url)
  }


  useEffect(() => {
    if (formik.isValid) return;
    const fieldErrorNames = transformObjectToDotNotation(
      moveKeyToLast(formik.errors, "userImage")
    );
    const selectRef = document.getElementById("react-select-2-input")
    if (selectRef) {
      selectRef.name = 'qualification'
    }

    if (fieldErrorNames.length <= 0) return;

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    );
    if (!element) return;

    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [formik.submitCount, formik.isValid]);

  useEffect(() => {
    if (url) {
      setCropper({
        show: true,
        url,
      })
      setUrl(url)
    };
  }, [url]);

  return (
    <Fragment>
      {type !== EDIT_PROFILE &&
        <H4 attrH4={{ className: "show-color m-b-25 text-center" }}>
          Enter Your Personal Details
        </H4>}
      <div className="scrollar-form removeScrollbar">
        {type === EDIT_PROFILE && <FormGroup className="m-b-20">
          <CustomInput
            name="email"
            value={formik.values.email}
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Email"
            label="Email"
          />
        </FormGroup>}
        <FormGroup className="m-b-20">
          <CustomInput
            name="fullName"
            value={formik.values.fullName}
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Full Name"
            label="Full Name"
          />
        </FormGroup>
        <FormGroup className="m-b-20">
          <Label className="show-color">Gender</Label>
          {genders.map((gender, index) => (
            <RadioButton
              key={gender.value}
              name="gender"
              checked={gender.value == formik.values.gender}
              value={gender.value}
              defaultValue={"male"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={gender.name}
            />
          ))}
          {showError("gender")}
        </FormGroup>
        <FormGroup className="m-b-20">
          <CustomInput
            name="age"
            type="number"
            value={formik.values.age}
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Age"
            label="Age"
          />
        </FormGroup>
        <FormGroup className="m-b-20">
          <div className="mb-2">
            <Label className="col-form-label show-color">Qualification</Label>
            <Select
              options={qualificationData}
              value={qualificationData.find((qua) => qua.value === formik.values.qualification)}
              onChange={(e) => formik.setFieldValue('qualification', e.value)}
              placeholder='Qualification'
              onBlur={formik.handleBlur}
              id="qualification"
              className="js-example-basic-single col-sm-12" />
          </div>
          {showError("qualification")}
        </FormGroup>
        <FormGroup className="m-b-20">
          <CustomInput
            name="cnicNo"
            type="number"
            value={formik.values.cnicNo}
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="CNIC no"
            label="CNIC number"
          />
        </FormGroup>
        <FormGroup className="m-b-20">
          <CustomInput
            name="contactNo"
            type="text"
            value={formik.values.contactNo}
            formik={formik}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Contact No"
            label="Contact Number (Whatsapp No. Preferred)"
          />
        </FormGroup>
        <FormGroup className="m-b-20">
          <Label className="show-color">
            Do you've a laptop and can you bring it for your use within the
            course labs? (It's mandatory)
          </Label>
          <RadioButton
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={"Yes"}
            name="ownLaptop"
            checked={
              "Yes" == formik.values.ownLaptop ||
              "ownLaptop" === formik.values.ownLaptop
            }
            label={"Yes"}
          />
          {showError('ownLaptop')}
        </FormGroup>
        <FormGroup className="m-b-20">
          <Label className="show-color">How did you know about us?</Label>
          {marketing.map((market) => (
            <RadioButton
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className='marketing-field'
              value={market}
              key={market}
              name="howYouKnow"
              checked={market == formik.values.howYouKnow}
              label={market}
            />
          ))}
          {showError("howYouKnow")}
        </FormGroup>
        <FormGroup>
          <Label className="show-color">Upload user image</Label>
          <Dropzone1
            imageChangeHandler={imageChangeHandler}
            images={formik.values.userImage ? formik.values.userImage : studentDetails?.userProfileImage}
          />
          {showError("userImage")}
        </FormGroup>
        {!studentDetails?.policyAgreed &&
          <FormGroup>
            <Label className="d-block m-l-5 show-cursor" for="policyAgreed">
              <Input
                className="checkbox_animated"
                id="policyAgreed"
                type="checkbox"
                value={true}
                checked={formik.values.policyAgreed}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              Kindly Acknowledge Our <a href='/policy' target='_blank'><span >Policies</span> </a>
            </Label>
            {showError("policyAgreed")}
          </FormGroup>}
        {cropper.show && <CommonModal
          isOpen={cropper.show}
          bodyClass='screen-modal-form'
          toggler={() => {
            closeImageCropper()
          }}
          title='Crop Profile Image'
          confirmButtonText='Done'
          confirmHandler={cropComplete}
        >
          <ImagecropperContain imageCanvasRef={imageCanvasRef} url={cropper.url} />
        </CommonModal>}
      </div>
    </Fragment>
  );
};

export default PersonalInfo;
