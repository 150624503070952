export const calculateAveragePercentage = (data) => {
  if(!data) return
  let total = 0;
  for (let i = 0; i < data.length; i++) {
    const element = +data[i];
    total += +element;
  }
  return Math.round(total / data.length);
};

export const calculateGrade = (data) => {
  if(!data) return
  const totalPercentage = calculateAveragePercentage(data);

  if (totalPercentage >= 80) {
    return "A+";
  } else if (totalPercentage >= 70) {
    return "A";
  } else if (totalPercentage >= 60) {
    return "B";
  } else {
    return "C";
  }
};
