import React from "react";
import Countdown from "react-countdown";
import { Clock } from "react-feather";



const QuizTimer = ({ time, submitQuestion }) => {

  const renderer = (
    { minutes, seconds, completed },
  ) => {
    if (completed) {
      localStorage.removeItem('quizIsInUnderWay')
      localStorage.removeItem('quiz')
      submitQuestion()
      return <div className="quiz-count-down">
        <Clock />
        <span className={minutes >= 10 ? 'font-danger' : "show-color"}>
          00:00:00
        </span>
      </div>
    } else {
      return (
        <div className="quiz-count-down">
          <Clock />
          <span className={minutes <= 1 ? 'font-danger' : "show-color"}>
            {minutes}:{seconds < 10 ? `0${seconds}`: seconds } Remaining
          </span>
        </div>
      );
    }
  };

  return (
    <div className="fixed right-0 flex items-center w-52 h-14 rounded-l-xl bg-primary-light dark:bg-dropdown-background top-24">
      <span className="ml-1 font-medium text-primary dark:text-primary-light">
        <Countdown
          date={parseInt(time)}
          controlled={false}
          onTick={() => { }}
          renderer={(props) => renderer(props)}
        />{" "}
      </span>
    </div>
  );
};

QuizTimer.displayName = 'QuizTimer'

export default QuizTimer;