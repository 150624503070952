import { ArrowLeft, ArrowRight, X } from "react-feather"

export const PreviousBtn = ({ currentStep, setCurrentStep }) => {
  const first = currentStep === 0

  return (
    <button
      className={`${first ? 'tooltip-btn-none' : ''} tooltip-btn`}
      onClick={() => {
        if (first) {
          return
        } else {
          setCurrentStep((s) => s - 1)
        }
      }}
    >
      <ArrowLeft />
    </button>
  )
}

export const NextBtn = ({
  currentStep,
  stepsLength,
  setIsOpen,
  setCurrentStep,
  steps
}) => {
  const last = currentStep === stepsLength - 1

  return (
    <button
      className="tooltip-btn"
      onClick={() => {
        if (last) {
          localStorage.setItem('tooltipView', true)
          setIsOpen(false)
        } else {
          setCurrentStep((s) => (s === steps?.length - 1 ? 0 : s + 1))
        }
      }}
    >
      {last ? <X /> : <ArrowRight />}
    </button>)
}